import React from 'react'
import {View, Text,TextInput} from 'react-native'
import { useTheme } from '../../utils/state-context'

const SubFormInput = ({label, placeholder,title,lineNum, ...rest}) =>{
    const {darkTheme} = useTheme()
    return(
        <View style={{
            width: '90%',
            marginHorizontal: 10,
            marginTop: 10,
            marginBottom: 20,
        }}>
            <Text style={{
                fontSize: 18,
                fontFamily:'roboto-regular',
                fontWeight:'700',
                marginBottom: 10,
                color:darkTheme?'#f3f3f3':'121212'
            }}>
                {title}
            </Text>
            <TextInput
            style= {{
                fontSize: 16, backgroundColor:darkTheme?'#545454':'#F3F3F3',
                paddingHorizontal: 5,
                paddingVertical: 10,
                borderRadius: 5
            }}
                value={label}
                numberOfLines={lineNum}
                placeholderTextColor={darkTheme?'#f3f3f3':'#666'}
                placeholder={placeholder}
                multiline={false}
                {...rest}
            />
        </View>
    )
}

export default SubFormInput