import * as React from 'react'
import {View, useWindowDimensions, StyleSheet,ScrollView, FlatList,TextInput} from 'react-native'
import { useTheme } from '../utils/state-context'
import { NavigationButton } from '../components/nav-button'
import axios from 'axios'
import { baseUrl } from '../utils/urls'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useAdminAuthentication } from './admin-utils/admin-auth-context'
import { accountsSetter } from './admin-utils/accounts-interface'
import { useAdminBlogs } from './admin-utils/admin-blogs-context'
import { AdminAllInfo } from './admin-components/admin-global-info'
import { Request, requestsSetter } from './admin-utils/request-interface'
import { RequestCard } from './admin-components/admin-request'
import { AdminProfile } from './admin-components/admin-profile'
import { Contacts } from '../components/contacts'


export function AdminRequestsListContent({navigation,setNot}){
    const{auth} = useAdminAuthentication()
    const {setAccounts} = useAdminBlogs()
    const {darkTheme} = useTheme()
    const {width: windowWidth} = useWindowDimensions()
    const [isFiltered,setIsFiltered] = React.useState(false)
    const [data,setData] = React.useState<Request[]>([])
    const isNarrowScreen = windowWidth < 800
    const [searchQuery, setSearchQuery] = React.useState('')
    const[filteredRequests,setFilteredRequests] = React.useState([])

    const handleSearch = (searchText: string) => {
        setSearchQuery(searchText)
        if (searchText.trim() === '') {
            // If the search query is empty, hide the search results
            setFilteredRequests(data)
        } else {
            // Filter blogs based on title or topic
            const filteredRequests = data.filter(
                (request) =>
                request.name.toLowerCase().includes(searchText.toLowerCase())
            )
            setFilteredRequests(filteredRequests)
        }
    }


    React.useEffect(()=>{
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${baseUrl}admin-requests/`,{
                    headers:{
                        Authorization: `Token ${auth.token}`,
                    }
                },)
                setData(requestsSetter(response.data))
                setAccounts(accountsSetter(response.data))
                setIsFiltered(true)
            } catch (error) {
                setNot({message:'Error while handling request',level:'warning'})
            }
        }
        fetchBlogs()
    },[auth,isFiltered])

    return(<ScrollView
        showsVerticalScrollIndicator={false}
    >
        <View style={{
            flexDirection: 'row', flexWrap: 'wrap',
            width:'100%',
        }}>
            {!isNarrowScreen && <View
                // @ts-ignore
                style={{
                    width: isNarrowScreen ? '100%' : '15%',alignSelf: 'flex-start',
                    marginHorizontal:!isNarrowScreen && 10,
                    position: !isNarrowScreen && 'sticky',
                    top: !isNarrowScreen &&10,
                    zIndex: !isNarrowScreen &&100
            }}>
                <AdminAllInfo/>
            </View>}
            <View style={{
                width: isNarrowScreen ? '100%' : '50%',
            }}>
                {
                    <View style={{
                        width: '100%', flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                        justifyContent:isNarrowScreen?'center':
                        'flex-start',alignSelf:isNarrowScreen?'center':'flex-start'
                    }} >
                        <NavigationButton navItem={'Home'} onPress={()=> navigation.reset({
                                index: 0,
                                routes: [{ name: 'Admin' }],
                            })}
                            index={0}
                        />
                        <NavigationButton navItem={'Accounts'} onPress={()=> navigation.navigate('AdminAccountsList')}
                            index={1}
                        />
                        <NavigationButton navItem={'Requests'} onPress={()=> navigation.navigate('AdminRequestScreen')}
                            index={2}
                        />
                    </View>
                }
                <View style={{
                    marginTop: 10,
                    borderRadius: 10,
                    borderWidth: 1.5,
                    width: '90%',
                    justifyContent:'space-evenly',
                    backgroundColor:darkTheme?'#12222F':'#ffffff',
                    borderColor:darkTheme?'#454545':'#EDEAEA',
                    minWidth: windowWidth *.3,
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginHorizontal:5,
                    marginBottom:10,
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                }}>
                    <TextInput
                        inlineImageLeft='search_icon'
                        style={styles.inputField}
                        underlineColorAndroid={'transparent'}
                        placeholder='Search Name or Email'
                        placeholderTextColor={darkTheme?'#f3f3f3':'#666'}
                        value={searchQuery}
                        onChangeText = {(search)=> handleSearch(search)}
                    />
                    <MaterialCommunityIcons name='magnify' size={24} color={darkTheme?'#ffffff':'#141414'}/>
                </View>
                <View style={{
                    width:'100%',
                    borderWidth:1.5,
                    borderColor:darkTheme?'#454545':'#EDEAEA',
                    backgroundColor:darkTheme?'#12222F':'#ffffff',
                    paddingHorizontal: 10,marginTop: 10,paddingVertical:5,
                    borderRadius: 10,
                }}>
                    <FlatList
                    data={searchQuery===''?data: filteredRequests}
                    numColumns={1}
                    keyExtractor={(homeData, index) => index.toString()}
                    renderItem={({item})=>(
                        <View
                        //@ts-ignore
                        style={{
                            alignContent:'center',
                            justifyContent:'center',alignItems:'center',
                            alignSelf:'center',
                            width: '100%',
                        }}
                        >
                            <RequestCard data={item} key={item.id}
                                setNot={setNot} setIsFiltered={setIsFiltered}
                            />
                        </View>)}
                    />
                </View>
            </View>
            {!isNarrowScreen && <View style={{
                marginBottom: 10,marginTop: 10,
                marginLeft:20,borderRadius:10,
                width:'30%', flexDirection:'column',
                backgroundColor:darkTheme?'#12222F':'#ffffff',
                paddingHorizontal: 10, alignSelf:'flex-start'
            }}>
                <AdminProfile/>
                <Contacts navigation={navigation}/>
            </View>}
        </View>
    </ScrollView>
    )
}


const styles = StyleSheet.create({
    inputField:{
        backgroundColor: '#f3f3f3',
            fontSize: 14,
            minWidth: '70%',
            height: 40,
            marginHorizontal:5,
            borderRadius: 10,
            paddingVertical: 5,
            color: '666',
            paddingHorizontal: 10,
            // @ts-ignore
            outlineColor: 'transparent',
    }
})
