import * as React from 'react'
import {View,Text,ScrollView,
    useWindowDimensions} from 'react-native'
import BottomSide from '../components/bottom-side'
import { BlogsStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import { SubscriptionSection } from '../components/subscription-component'
import { GuideComponent } from '../components/guide-component'
import { guideList } from '../utils/guide-list'


const Blogs = ({navigation}) => {
    const blogsStyle = BlogsStyle()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })

    return(
        <View style={{
            flexGrow:1,flexShrink:1,backgroundColor:darkTheme?'#121212':'#ffffff',
            width:'100%',
        }}>
            <ScrollView
            showsVerticalScrollIndicator={false}
            >
                <View style={blogsStyle.starter}>
                    <View>
                        <Text style={blogsStyle.titleText}>Home of Stories</Text>
                        <Text style={blogsStyle.text}>Storytelling at its peak</Text>
                    </View>
                </View>
                <View style={blogsStyle.container}>
                        <View style={{ flexDirection: 'row', flexWrap:'wrap'}} >
                            <View style={[blogsStyle.trendingWrapper,{
                            }]}>
                                {
                                guideList.map((item,index)=><GuideComponent key={index}
                                title={item.title} img={item.image} iconSize={30}
                                description={item.description} navigation={navigation}
                                />
                                )}
                            </View>
                            {windowWidth>800 && <View style={blogsStyle.topicsWrapper}>
                                <View>
                                    <SubscriptionSection setNot={setNotification}/>
                                </View>
                            </View>}
                        </View>
                        {
                        windowWidth<=900 &&
                        <View>
                            <SubscriptionSection setNot={setNotification}/>
                        </View>}
                </View>
            </ScrollView>
        </View>
    )
}

export default Blogs
