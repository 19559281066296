import * as React from 'react'
import {View,FlatList,Text,TouchableOpacity,useWindowDimensions,
    ScrollView} from 'react-native'
import Blog from '../components/blog-content'
import { Feather } from '@expo/vector-icons'
import { BlogReadStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import ListCard from '../components/cards/list-card'
import { BlogStory,Comment } from '../utils/story-data'
import axios from 'axios'
import FormData from 'form-data'
import { baseUrl } from '../utils/urls'
import { commentsSetter, mainBlogSetter, suggestedBlogsSetter } from '../utils/story-interface-setter'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { ContentActions } from '../components/content-actions'
import CircularProgressIndicator from '../components/custom-components/progress-indicator'
import { CommentCard } from '../components/cards/comment-card'
import { CommentTextInput } from '../components/data-entry/comment-input-form'


const BlogRead = ({route,navigation}) => {
    const {id} = route.params
    const {viewedBlog,setViewedBlog} = useTheme()
    const [likes, setLikes] = React.useState(0)
    const[views,setViews] = React.useState(0)
    const scrollViewRef = React.useRef(null)
    const [scrollViewHeight, setScrollViewHeight] = React.useState(0)
    const [data,setData] = React.useState<BlogStory>()
    const [comments,setComments]= React.useState<Comment[]>([])
    const[ifSent,setIfSent] = React.useState(false)
    //progress setter
    const [progress, setProgress] = React.useState(0)
    const [suggestedData,setSuggestedData] = React.useState([])
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 950
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })
    const {
        darkTheme,
    } = useTheme()
    const blogReadStyle = BlogReadStyle()

    // passing responses on clicking
    const viewForm = new FormData()
    viewForm.append('id',id)
    const sendView = async()=>{
        await axios.post(`${baseUrl}viewed/`,viewForm)
        .catch((error)=>{
            setNotification({message:`Experienced error: ${error.message}`,level:'warning'})
        })
    }
    // create localStorage view item
    const storeViewSent = (id: number, value: boolean) => {
        try {
        const viewData = { id: id, viewed: value }
        setViewedBlog(viewData)
        localStorage.setItem('view',JSON.stringify(viewData))
        } catch (error) {
            setNotification({message:`Experienced error: ${error.message}`,level:'warning'})
        }
    }

    const getComments =async()=>{
        await axios.get(`${baseUrl}comments/${id}/`)
        .then((response)=>{
            setComments(commentsSetter(response.data))
        }).catch((error)=>{
            setNotification({message:`Experienced error: ${error.message}`, level:'warning'})
        })
    }

    const getSuggestions=async(topic:string)=>{
        await axios.get(`${baseUrl}suggestions/${topic}/`)
        .then((response)=>{
            setSuggestedData(suggestedBlogsSetter(response.data))
        })
        .catch(()=>{
            setNotification({message:'Error while handling request',level:'warning'})
        })
    }

    React.useEffect(() => {
        // Scroll the ScrollView to the top when the route parameter changes
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ y: 0, animated: false })
        }
        // Variable to track if fast forward is needed
        let shouldFastForward = false
        // Set up the interval for progress update
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
            // Check if fast forward is needed
            if (shouldFastForward) {
                clearInterval(interval) // Stop the regular progress update interval
                return 100 // Set progress to 100%
            }
            // Continue regular progress update
            return (prevProgress + 5) % 101
            })
        }, 30)
        // Set up the homeData after 2 seconds (adjust as needed)
        const timeout = setTimeout(() => {
            axios.get(`${baseUrl}get_blog/${id}/`)
            .then(async(response)=>{
                setData(mainBlogSetter(response.data))
                setLikes(response.data.likes)
                setViews(response.data.views)
                await getSuggestions(response.data.topic)
            }).catch((err)=>{
                setNotification({message:`Experienced error: ${err.message}`,level:'warning'})
            })
            if(!ifSent){
                getComments()
            }
            shouldFastForward = true // Set the flag to trigger fast forward
        }, 2000)
        // Clear intervals and timeouts on component unmount or dependencies change
        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
    }, [id])

    React.useEffect(()=>{
        getComments()
    },[ifSent])

    const onScroll = (event: any) => {
        const { y } = event.nativeEvent.contentOffset
        const halfwayPoint = scrollViewHeight / 2 // Adjust as needed
        // Check if the user has scrolled to at least halfway through the content
        if (y >= halfwayPoint &&
            data && Object.keys(data).length !== 0 &&
            data.constructor === Object) {
                if(viewedBlog===null || String(viewedBlog.id) !== String(id) || (String(viewedBlog.id) === String(id) && viewedBlog.viewed !==true)){
                    sendView()
                    storeViewSent(id, true)
                }
        }
    }

    const onLayout = (event:any) => {
        const { height } = event.nativeEvent.layout
        setScrollViewHeight(height)
    }

    return(
        <View style={{
            flexGrow: 1,flex:1,
            flexShrink:1,
            backgroundColor:darkTheme?'#121212':'f3f3f3',
            width:'100%',
        }}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
                maxHeight: isNarrowScreen ? '100%' : undefined,
            }}
            ref={scrollViewRef}
            onScroll={onScroll}
            onLayout={onLayout}
            >
                <View style={{
                    justifyContent:'space-evenly',
                    width: '100%',flexDirection:'row',flexWrap:'wrap',
                    paddingHorizontal: isNarrowScreen?5: 10,marginTop: 10,
                }}>
                    {!isNarrowScreen && data && <View
                    style={[
                        {
                        width: '7%',
                        marginBottom: 10,alignItems: 'center',alignSelf: 'flex-start',
                    },!isNarrowScreen&& blogReadStyle.sidePositioning]}
                    >
                        <ContentActions comments={comments} isNarrowScreen={isNarrowScreen}
                        navigation={navigation}
                        darkTheme={darkTheme} setLikes={setLikes} likes={likes}
                        views={views} setNot={setNotification} id={id} />
                    </View>}
                    {data?<View style={{ marginBottom:10,width: isNarrowScreen?'100%':'55%',
                    alignItems: 'flex-start',
                    alignSelf:'flex-start' }}>
                        <View
                        style={{
                            borderRadius:10,
                            width: '100%',
                            borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                            backgroundColor: darkTheme?'#12222F':'#ffffff',
                            // alignSelf: 'flex-start',
                            paddingHorizontal: 10,marginBottom: 10,
                            alignItems: 'flex-start',
                        }}
                        >
                            <Blog blog={data}/>
                        </View>
                        {isNarrowScreen && data && <View
                        style={[
                            {
                            width: isNarrowScreen?'40%':'7%',
                            marginBottom: 10,alignItems: 'center',alignSelf: 'center',
                        },!isNarrowScreen&& blogReadStyle.sidePositioning]}
                        >
                            <ContentActions comments={comments} isNarrowScreen={isNarrowScreen}
                            navigation={navigation}
                            darkTheme={darkTheme} setLikes={setLikes} likes={likes}
                            views={views} setNot={setNotification} id={id} />
                        </View>}
                        <View
                        style={{
                            borderRadius:10,
                            width: '100%',
                            borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                            backgroundColor: darkTheme?'#12222F':'#ffffff',
                            // alignSelf: 'flex-start',
                            paddingHorizontal: 10,marginBottom: 10,
                            paddingVertical: 5,
                            alignItems: 'flex-start',
                        }}
                        >
                            <CommentTextInput darkTheme={darkTheme} isNarrowScreen={isNarrowScreen}
                                blog_id={id} setNot={setNotification} setIfSent={setIfSent}
                            />
                            <View style={{ width: '100%' }}>
                                {
                                    comments.length>0 && comments.map((item:any,index:number)=>{
                                        return <CommentCard comment={item} darkTheme={darkTheme}
                                        key={index}
                                        isNarrowScreen={isNarrowScreen}/>
                                    })
                                }
                            </View>
                        </View>
                    </View>:<View style={{
                            marginTop:'20%',
                            width: isNarrowScreen?'100%':'55%',alignSelf:'center',alignContent:'center',
                            alignItems:'center', justifyContent:'center',marginBottom: 20
                        }}>
                            <CircularProgressIndicator
                            radius={25}
                            strokeWidth={1}
                            progress={progress}
                        />
                        </View>}
                    {data && <View style={[{
                        width: isNarrowScreen?'100%':'35%',marginBottom:10,
                        backgroundColor: darkTheme?'#12222F':'#ffffff',
                        borderRadius: 10, marginLeft:!isNarrowScreen && 10,
                        borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                        paddingHorizontal: isNarrowScreen?10:5,alignItems: 'flex-start',alignSelf: 'flex-start',
                    },
                    ]}>
                        <FlatList
                            data={suggestedData}
                            keyExtractor={(item) => item.id.toString()}
                            renderItem={({ item }) => (
                                <View
                                // style={{ width: '100%' }}
                                >
                                    <ListCard
                                        data={item}
                                        onPress={() => {
                                            navigation.navigate('BlogRead', { id: item.id });
                                        }}
                                    />
                                </View>
                            )}
                            ListHeaderComponent={() => (
                                <View style={{ width: '100%' }}>
                                    <Text style={blogReadStyle.trendingText}>
                                        Suggested Posts for Reading
                                    </Text>
                                </View>
                            )}
                            stickyHeaderIndices={[1]} // Index 1 corresponds to the header
                        />
                        <TouchableOpacity onPress={()=>navigation.navigate('Home')} style={blogReadStyle.extendedBtn}>
                            <Text style={blogReadStyle.extendText}>Get more stories</Text>
                            <Feather name="arrow-right" size={24} color={darkTheme?'#ffffff': '#121212'} />
                        </TouchableOpacity>
                    </View>}
                </View>
            </ScrollView>
        </View>
    )
}

export default BlogRead
