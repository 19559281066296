import * as React from 'react'
import {View} from 'react-native'
import { useTheme } from '../utils/state-context'
import { useAdminAuthentication } from './admin-utils/admin-auth-context'
import { useRoute } from '@react-navigation/native'
import { AdminAccountsListContent } from './admin-account-list-content'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { AdminRequestsListContent } from './admin-requests-list'


export function AdminRequestScreen({navigation}){
    const route = useRoute()
    const{auth} = useAdminAuthentication()
    const {darkTheme} = useTheme()
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })


    // console.log(data)

    return(
        <View style={{
            backgroundColor:darkTheme?'#121212':'#f3f3f3',
            flexShrink:1,
            justifyContent:'center',
            // flexGrow: 1,
            width:'100%',
            height: '100%',
            flex: 1
        }}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            {auth && <AdminRequestsListContent navigation={navigation} setNot={setNotification}/>}
        </View>

    )
}

