import * as React from 'react'
import { View, useWindowDimensions} from 'react-native'
import { useTheme } from '../utils/state-context'
import { AdminBlogsList } from './admin-blogs-list'
import { useRoute } from '@react-navigation/native'
import { useAdminAuthentication } from './admin-utils/admin-auth-context'


export function Admin({navigation}){
    const route = useRoute()
    const {auth} = useAdminAuthentication()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    return(
        <View style={{
            width:'100%', backgroundColor:darkTheme?'#121212':'#f3f3f3',
            flex:1,flexShrink:1,flexGrow:1
        }}>
            {auth && <AdminBlogsList navigation={navigation}/>}
        </View>
    )
}