export const STORAGE_KEY = 'encryptedAdminData'

export const adminSaveToFile = (data) => {
    try {
        const serializedData = JSON.stringify(data)
        localStorage.setItem(STORAGE_KEY, serializedData)
    } catch (error) {
        alert(`Error saving data to localStorage: ${error.message}`)
    }
}

export const adminReadFromFile = () => {
    try {
        const serializedData = localStorage.getItem(STORAGE_KEY)
        const data = JSON.parse(serializedData)
        return data
    } catch (error) {
        alert(`Error retrieving data from localStorage:${error.message}`)
    }
}

export const adminClearData = () => {
    try {
        localStorage.removeItem(STORAGE_KEY)
        console.log(`Data cleared from localStorage with key: ${STORAGE_KEY}`)
    } catch (error) {
        alert(`Error clearing data from localStorage: ${error.message}`)
    }
}