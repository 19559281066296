import React, { useState } from 'react'
import {View,TextInput,Text} from 'react-native'
import { CredentialFormStyle } from '../../utils/app-styles'

const CredentialFormInput = ({label, placeholder,darkTheme,title,lineNum,secureTextEntry, ...rest}) =>{
    //initializing styles
    const credentialFormStyle = CredentialFormStyle()
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }
    return(
        <View style={{ marginBottom:10,alignItems:'flex-end' }}>
            <TextInput
                style= {credentialFormStyle.inputField}
                value={label}
                numberOfLines={lineNum}
                placeholderTextColor= {darkTheme?'#ffffff':'#666'}
                placeholder={placeholder}
                secureTextEntry={!isPasswordVisible && secureTextEntry}
                multiline={false}
                selectionColor={'#1A8CF1'}
                {...rest}
            />
            {secureTextEntry && (
                <Text onPress={togglePasswordVisibility} style={{ color:darkTheme?'#ffffff':'#121212' }}>
                    {isPasswordVisible ? 'Hide Password' : 'Show Password'}
                </Text>
            )}
        </View>
    )
}

export default CredentialFormInput