import * as React from 'react'
import {View,StyleSheet, ScrollView, useWindowDimensions,Text} from 'react-native'
import ListCard from '../components/cards/list-card'
import { NavigationButton } from '../components/nav-button'
import { BlogsContext } from '../utils/blog-context'
import { BlogReadStyle, HomeStyle } from '../utils/app-styles'
import { TrendBar } from '../components/trend-bar'
import { Contacts } from '../components/contacts'
import { HomeBanner } from '../components/home-banner'
import { useAuthentication } from '../utils/auth-context'

export function Latest({navigation,darkTheme}){
    const {auth} = useAuthentication()
    const homeStyle = HomeStyle()
    const blogReadStyle = BlogReadStyle()
    const {trending} = React.useContext(BlogsContext)
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const{latestBlogs} = React.useContext(BlogsContext)
    return(
        <View style={{
            backgroundColor: darkTheme ? '#121212' : '#f3f3f3',
            flexShrink: 1,
            justifyContent: 'center',
            width:'100%',
            flexGrow: 1,
            flex: 1 }}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ flexDirection: 'row',
                width:'100%',marginBottom: 10,
                paddingHorizontal: isNarrowScreen?5:10,
                justifyContent: 'space-around' }}>
                    {!isNarrowScreen && <View
                    // @ts-ignore
                    style={{
                        width: isNarrowScreen ? '100%' : '15%',alignSelf: 'flex-start',
                        marginHorizontal:!isNarrowScreen && 10,
                        position: !isNarrowScreen && 'sticky',
                        top: !isNarrowScreen &&10,
                        zIndex: !isNarrowScreen &&100
                    }}>
                        <View style={{paddingVertical: 10,paddingLeft: 10}}>
                            <Text style={homeStyle.listedText}>Top Trending</Text>
                        </View>
                        <TrendBar navigation={navigation} isNarrowScreen={isNarrowScreen}
                        isFilter={false} trending={trending} darkTheme={darkTheme}
                        />
                    </View>}
                    <View style={{ width: isNarrowScreen ? '100%' : '50%',alignSelf: 'flex-start' }}>
                        {
                            <View style={{
                                width: '100%', flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                                justifyContent:isNarrowScreen?'center':
                                'flex-start',alignSelf:isNarrowScreen?'center':'flex-start'
                            }} >
                                <NavigationButton navItem={'Home'} onPress={()=> navigation.reset({
                                        index: 0,
                                        routes: [{ name: 'Home' }],
                                    })}
                                    index={0}
                                />
                                <NavigationButton navItem={'Latest'} onPress={()=> navigation.navigate('Latest')}
                                    index={1}
                                />
                            </View>
                        }
                        <View style={{
                            marginTop:1,width:'100%',
                            backgroundColor:darkTheme?'#12222F':'#ffffff',
                            paddingHorizontal: 5,paddingVertical:5,
                            borderRadius: 10,borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                        }}>
                            {
                                latestBlogs && latestBlogs.map((item,index)=>{
                                    return <ListCard data={item} onPress={() =>
                                        navigation.navigate('BlogRead', {
                                        id: item.id,
                                        })
                                    }key={index}/>
                                })
                            }
                        </View>
                    </View>
                    {!isNarrowScreen && <View
                    style={[{
                        width: '30%',
                        alignSelf: 'flex-start',
                    },blogReadStyle.sidePositioning]}>
                        {!auth?<View>
                            <View style={{paddingVertical: 10,paddingLeft: 10}}>
                                <Text style={homeStyle.listedText}>Other Content</Text>
                            </View>
                            <HomeBanner navigation={navigation}/>
                            <View style={{
                                height:1, backgroundColor:darkTheme?'#454545':'#EDEAEA',
                                marginBottom: 10
                            }}/>
                        </View>:null}
                        <View>
                            <Contacts navigation={navigation}/>
                        </View>
                    </View>}
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    inputField:{
        backgroundColor: '#f3f3f3',
            fontSize: 14,
            minWidth: '70%',
            height: 40,
            marginHorizontal:5,
            borderRadius: 10,
            paddingVertical: 5,
            color: '666',
            paddingHorizontal: 10,
            // @ts-ignore
            outlineColor: 'transparent',
    }
})
