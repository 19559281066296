import * as React from 'react'
import { TouchableOpacity, PanResponder,View,useWindowDimensions } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '../../utils/state-context'

const DragHandle = ({ onMove }) => {
    const {width: windowWidth} = useWindowDimensions()
    const{darkTheme} = useTheme()
    const isNarrowScreen = windowWidth < 800
    const panResponder = React.useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onMoveShouldSetPanResponderCapture: () => true,
            onPanResponderMove: (_, gestureState) => onMove(gestureState.dy),
        })
    ).current
    return(
    <View {...panResponder.panHandlers}><TouchableOpacity>
            <MaterialCommunityIcons name="drag" size={isNarrowScreen?30:24} color={darkTheme?'#f3f3f3':'#2B2A2A'}/>
        </TouchableOpacity>
    </View>
    )
}

export default DragHandle