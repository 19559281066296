import * as React from 'react'
import {View,Text,StyleSheet} from 'react-native'
import { useAdminBlogs } from '../admin-utils/admin-blogs-context'
import { useTheme } from '../../utils/state-context'

export function AdminAllInfo(){
    const {darkTheme} = useTheme()
    const {blogs,accounts,subscribers} = useAdminBlogs()
    return(
        <View style={{ width:'100%',marginTop: 10,
            borderRadius: 10,
            borderWidth: 1.5,
            backgroundColor:darkTheme?'#12222F':'#ffffff',
            borderColor:darkTheme?'#454545':'#EDEAEA',
            alignItems: 'center',
            marginHorizontal:5,
            marginBottom:10,
            paddingVertical: 10,
            paddingHorizontal: 10,
        }}>
            <View style={styles.rowLayout}>
                <Text style={styles.textTitle}>Total Blogs </Text>
                <Text style={styles.textContent}>{` ${ blogs.length }`}</Text>
            </View>
            <View style={styles.rowLayout}>
                <Text style={styles.textTitle}>Total Accounts </Text>
                <Text style={styles.textContent}>{` ${ accounts.length }`}</Text>
            </View>
            <View style={styles.rowLayout}>
                <Text style={styles.textTitle}>Total Subscribers </Text>
                <Text style={styles.textContent}>{` ${ subscribers.length }`}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    rowLayout:{
        flexDirection:'row',
        flexWrap:'wrap',width:'100%',
        marginVertical: 10
    },
    textTitle:{
        fontFamily:'roboto-bold',
        fontWeight:'bold',
        fontSize: 16
    },
    textContent:{
        fontWeight:'bold',
        color:'#1A8CF1'
    }
})
