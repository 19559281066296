// this component is a card that describes the layout of the blogs card
// its style is defined inside this file with imported dimensions

import * as React from 'react'
import {View, Text,Pressable, useWindowDimensions, TouchableOpacity} from 'react-native'
import { ListCardStyle } from '../../utils/app-styles'
import { baseUrlImage } from '../../utils/urls'
import {Feather, MaterialCommunityIcons} from '@expo/vector-icons'
import { timeAgo } from '../../utils/time-ago'
import { useTheme } from '../../utils/state-context'
import {Image} from 'expo-image'
import { blurHash } from '../../utils/extra-utils'
import DateTimeFormatter from '../../utils/date-time-util-formatter'
import * as Clipboard from 'expo-clipboard'


const ListCard=({onPress,data})=>{

    const styles = ListCardStyle()
    const {darkTheme} = useTheme()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    //date management
    const dateCreated = new Date(data.created_at)
    const {day,month,year,hours, minutes} = DateTimeFormatter(dateCreated)
    const dateObject = timeAgo(data.created_at)
    const[isCopied,setIsCopied] = React.useState(false)
    // converts story object to string
    const content = data.content
    const [isHovered, setIsHovered] = React.useState(false)

    const pressHandler = () => {
      // Handle press
        onPress && onPress()
    }
    const copyToClipboard = async () => {
        await Clipboard.setStringAsync(`https://yourstory.co.ke/blog/${data.id}`)
        // setNot({message:'Blog url copied to clipboard!',level:'success'})
        setIsCopied(true)
    }

    return(
        <View style={{ width: '100%' }}>
            <Pressable
            onPress={pressHandler}
            //@ts-ignore
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={({ pressed }) => [
                {
                    backgroundColor: pressed ? darkTheme?'#22415a':'#EDF2F6' : (isHovered && (darkTheme?'#22415a':'#EDF2F6')),
                    // borderRadius: 10,
                    padding: 7
                },styles.container
            ]} key={data.id}>
                <View style={{ width: data.contentImages.length>0?'60%':'100%'}}>
                    <View style={{
                        marginTop: 10,marginBottom: 5,width:'100%',flexDirection:'row',
                        alignContent:'center',alignItems:'flex-start'
                    }}>
                        <View style={{
                            width: 28,height: 28, backgroundColor: isHovered?'#1A8CF1':'#D6D1D1', marginRight:10,
                            borderRadius: 14,alignItems:'center',justifyContent:'center',
                        }}>
                            <Image source={{uri:`${baseUrlImage}${data.author?.profile}`}}
                            style={{ width: 25,height: 25,borderRadius: 12.5,alignSelf:'center'
                            }}/>
                        </View>
                        <View>
                            <Text style = {styles.authorText}>
                                {`By ${data?.author?.name}`}
                            </Text>
                            <Text style = {[styles.contentText,{
                                marginTop: 2, fontFamily:'roboto-regular'
                            }]}>
                                {`${month} ${day}, ${year} at ${hours}:${minutes}`}
                            </Text>
                        </View>
                    </View>
                    <View style={{  marginBottom: 2,width:'90%',marginLeft: 20}}>
                        <Text style={[styles.txt,{fontSize:isHovered?(isNarrowScreen?17:19):
                        (isNarrowScreen?16:18)
                        }]}>{data.title}</Text>
                    </View>
                    {data.content.length>0 &&<View style={{  marginBottom: 10,width:'90%',marginLeft: 25}}>
                        <Text numberOfLines={data.content.length>0?3:2} ellipsizeMode="tail" style = {styles.contentText}>
                            {data.content[0].story}
                        </Text>
                    </View>
                    }
                    <View style={{
                        flexDirection: 'row',flexWrap:'wrap' ,width: '90%',marginLeft: 20,
                        alignItems:'center'
                    }}>
                        {data.content.length>0 && <View style={styles.viewsSection}>
                            <Text style={{
                                ...styles.dateText,fontWeight: 'bold',color:'#FFFFFF',
                                fontFamily:'roboto-regular'
                            }}>
                            {`${Math.ceil(content[0].story.length/1500*content.length)} min Read `}
                            </Text>
                        </View>}
                        {/* {<View style={{ ...styles.viewsSection,backgroundColor:'#76B947' }}>
                            <Feather name="eye" size={16} color={darkTheme?'#ffffff': '#121212'} />
                            <Text style={{ ...styles.dateText,marginLeft: 2 }}>{` ${data.views}`}</Text>
                        </View>} */}
                        <View style={{ ...styles.viewsSection,backgroundColor:'#FFA500'}}>
                            <Text style={{
                                ...styles.dateText,fontFamily:'roboto-regular',color:'#ffffff',
                                fontSize: isNarrowScreen? 13:14
                            }}>{` #${data.topic}`}</Text>
                        </View>
                        <TouchableOpacity onPress={()=>copyToClipboard()}>
                            <MaterialCommunityIcons name="content-copy" size={24}
                            color={isCopied?'#1A8CF1':(darkTheme?'#ffffff': '#121212')} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ width: '40%' }}>
                    {data.contentImages.length>0&&
                    <Image source={{ uri:`${baseUrlImage}${data.contentImages[0].media}` }}
                    style={{ width: '100%',height: 150,borderRadius: 5 }} contentFit='cover' placeholder={blurHash}/>}
                    {data.contentImages[0] && <View style={{
                        position: 'absolute', top: 10, right: 3,
                        backgroundColor:isHovered&&( darkTheme?'rgba(18, 34, 47, 0.7)' : 'rgba(255, 255, 255, 0.7)'),
                        borderRadius: 10,paddingHorizontal:5,paddingVertical:2,
                    }}>
                        <View style={{
                            flexDirection:'row',flexWrap:'wrap', justifyContent:'space-evenly',
                            alignItems:'center',width: '100%'
                        }}>
                            <Text style={{
                                fontWeight: 'bold',color: isHovered?'#1A8CF1':'transparent',
                                marginRight:5,maxWidth:100,fontSize: 12
                            }} ellipsizeMode="tail" numberOfLines={1}>
                                {data.contentImages[0].imageCopyright}
                            </Text>
                            <MaterialCommunityIcons name='information-outline'
                            size={18} color={isHovered?'#1A8CF1':'transparent'}/>
                        </View>
                    </View>}
                </View>
            </Pressable>
            <View style={{
                height:1, backgroundColor:darkTheme?'#454545':'#EDEAEA',
                marginBottom: 10
            }}/>
        </View>
    )
}
export default ListCard
