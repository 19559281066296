import * as React from "react"
import { BlogStory } from "../../utils/story-data"
import { Account, Subscriber, accountsSetter, subscribesSetter } from "./accounts-interface"
import { baseUrl } from "../../utils/urls"
import axios from "axios"
import { useAdminAuthentication } from "./admin-auth-context"
import { allBlogsSetter } from "../../utils/story-interface-setter"



export const AdminBlogsContext = React.createContext<AdminBlogsContextProps | undefined>(undefined)


export const useAdminBlogs = () => {
    return React.useContext(AdminBlogsContext)
}

export const AdminBlogsProvider = ({children}:React.PropsWithChildren):JSX.Element=>{
    const{auth} = useAdminAuthentication()
    const [blogs, setBlogs] = React.useState<BlogStory[]>([])
    const [subscribers,setSubscribers] = React.useState<Subscriber[]>([])
    const [accounts,setAccounts] = React.useState<Account[]>([])

    React.useEffect(()=>{
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${baseUrl}admin-blogs/`,{
                    headers:{
                        Authorization: `Token ${auth.token}`,
                    }
                },)
                setBlogs(allBlogsSetter(response.data))
            } catch (error) {}
        }
        const fetchAccounts = async () => {
            try {
                const response = await axios.get(`${baseUrl}accounts/`,{
                    headers:{
                        Authorization: `Token ${auth.token}`,
                    }
                },)
                setAccounts(accountsSetter(response.data))
            } catch (error) {}
        }
        const fetchSubscriber = async () => {
            try {
                const response = await axios.get(`${baseUrl}admin-subscribers/`,{
                    headers:{
                        Authorization: `Token ${auth.token}`,
                    }
                },)
                setSubscribers(subscribesSetter(response.data))
            } catch (error) {}
        }
        fetchBlogs()
        fetchAccounts()
        fetchSubscriber()
    },[auth])

    return <AdminBlogsContext.Provider value={{
        blogs,setBlogs,accounts,setAccounts,subscribers,setSubscribers
    }}>
        {children}
    </AdminBlogsContext.Provider>
}


export interface AdminBlogsContextProps{
    // accounts: Adm
    blogs: BlogStory[],
    setBlogs: React.Dispatch<React.SetStateAction<BlogStory[]>>
    accounts: Account[],
    setAccounts: React.Dispatch<React.SetStateAction<Account[]>>
    subscribers: Subscriber[],
    setSubscribers: React.Dispatch<React.SetStateAction<Subscriber[]>>
}
