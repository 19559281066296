import * as React from 'react'
import { StyleSheet,View,Image,Text, useWindowDimensions } from 'react-native'
import { useAdminAuthentication } from '../admin-utils/admin-auth-context'
import { baseUrlImage } from '../../utils/urls'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '../../utils/state-context'

export function AdminProfile(){
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const {auth} = useAdminAuthentication()
    return (
        <View style={{
            paddingVertical: 10,paddingLeft: 10,
            alignItems:'center',justifyContent:'center'
        }}>
            <View style={[styles.profile,{marginBottom: 20}]}>
                {auth.profile !=null ?
                <Image source={{ uri:`${baseUrlImage}${auth.profile}` }}
                style={[styles.profile,{width: 50,height:50, borderRadius: 25}]}
                />:<MaterialCommunityIcons name='account' size={50} color={'#1A8CF1'}/>}
            </View>
            <Text style={styles.username}>{auth.username}</Text>
            <Text style={styles.email}>{auth.email}</Text>
        </View>
    )
}



const styles = StyleSheet.create({
    profile:{
        height: 70,
        width: 70,
        borderRadius: 35,
        borderWidth: 2,
        borderColor:'#1A8CF1',
        // backgroundColor: '#D6D1D1',
        alignItems:'center',justifyContent:'center'
    },
    username:{
        fontSize: 16,
        fontFamily:'roboto-bold',
        fontWeight:'bold',
    },
    email:{
        fontSize: 16,
        fontFamily:'roboto-bold',
        fontWeight:'bold',
        color:'#1A8CF1'
    }
})