import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet,Image, useWindowDimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { baseUrlImage } from '../../utils/urls';
import { useTheme } from '../../utils/state-context';

const DeleteConfirmationModal = ({ visible, onCancel, onConfirm,data }) => {
    const {width: windowWidth} = useWindowDimensions()
    const {darkTheme} = useTheme()
    const isNarrowScreen = windowWidth < 800
    return (
        <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={onCancel}
        >
            <View style={styles.modalContainer}>
                <View style={[styles.modalContent,{
                    width: isNarrowScreen?'60%':'40%',
                    backgroundColor: darkTheme?'#12222F':'white',
                }]}>
                    <Text style={styles.modalText}>{`Are you sure you want to delete? ${data.email}`}</Text>
                    <View style={{
                            width: 32,height: 32, backgroundColor: '#D6D1D1',
                            borderRadius: 16,alignItems:'center',justifyContent:'center',
                            marginVertical:10
                        }}>
                            <Image source={{uri:`${baseUrlImage}${data.profile}`}}
                            style={{ width: 30,height: 30,borderRadius: 15,alignSelf:'center'
                            }}/>
                    </View>
                    <Text style={styles.boldText}>{`${data.username}`}</Text>
                    <Text style={styles.boldText}>{`${data.email}`}</Text>
                    <View style={[styles.buttonContainer,{width: isNarrowScreen?'100%':'60%'}]}>
                        <TouchableOpacity onPress={onCancel} style={styles.cancelButton}>
                            <Text style={styles.buttonText}>Cancel</Text>
                        </TouchableOpacity>
                            <TouchableOpacity onPress={onConfirm} style={styles.confirmButton}>
                            <Text style={styles.buttonText}>Delete</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        padding: 20,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalText: {
        fontSize: 16,
        marginBottom: 20,
    },
    buttonContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    cancelButton: {
        backgroundColor: 'red',
        paddingHorizontal: 10,paddingVertical: 5,
        borderRadius: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center'
    },
    confirmButton: {
        backgroundColor: '#1A8CF1',
        paddingHorizontal: 10,paddingVertical: 5,
        borderRadius: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center'
    },
    buttonText: {
        color: 'white',
        fontWeight:'bold',
    },
    boldText:{
        fontWeight:'bold',
        fontFamily:'roboto-bold',
        fontSize: 16
    }
});

export default DeleteConfirmationModal;
