import * as React from 'react'
import { Text, Linking } from 'react-native'

const LinkText = ({ url, alias, linkStyle }) => (
    <Text style={linkStyle} onPress={() => Linking.openURL(url)}>
        {alias}
    </Text>
)
export const formatText = (input:any) => {
    // Regular expression to identify URLs
    const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g
    // Regular expression to identify list items
    const unorderedListRegex = /^\s*-\s/gm
    const bulletedListMarker = '\u2022'

    const formattedText = input
        .replace(unorderedListRegex, `${bulletedListMarker} `)

    return formattedText
}

export const formatBlogText = (input:any) => {
    // Regular expression to identify URLs
    const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g
    // Regular expression to identify list items
    const unorderedListRegex = /^\s*-\s/gm
    const bulletedListMarker = '\u2022'
    const parts = input.split(urlRegex)

    return <React.Fragment>
    {parts.map((part:string, index:number) => {
        if (index % 2 === 0) {
            return (
            <Text key={index}>
                {part.replace(unorderedListRegex, `${bulletedListMarker} `)}
            </Text>
            );
        // } else {
        //     const alias = part.replace(/(https?|ftp):\/\/|www\./g, '');
        //     const url = `https://${part}`;
        //     return (
        //     <LinkText key={index} url={url} alias={alias} linkStyle={{ color: '#1A8CF1' }} />
        //     );
        }
        })}
    </React.Fragment>
}