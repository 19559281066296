import CryptoJS from 'crypto-js'

const secretKey = 'your-secret-key'

export const encryptData = (data) => {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
    return cipherText
}

export const decryptData = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
}
