import * as React from 'react'
import {View, StyleSheet,Text,Pressable, useWindowDimensions} from 'react-native'
import { useTheme } from '../utils/state-context'
import { useRoute } from '@react-navigation/native'


export function NavigationButton({navItem,onPress,index}){
    const route = useRoute()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const [hoveredIndex, setHoveredIndex] = React.useState(null)

    const handleMouseEnter = (index:number) => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndex(null)
    }

    const isActive = (routeName: string) => route.name === routeName

    return(
        <Pressable onPress={onPress}
        //@ts-ignore
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => handleMouseLeave()}
        style={({ pressed }) => [
            {
                backgroundColor: isActive(navItem==='Accounts'?'AdminAccountsList':navItem==='Home'?'Admin':
                navItem ==='BlogsHome'?'Home': navItem
                )
                ? darkTheme?'#2B2A2A':'#FFFEFE' :
                (hoveredIndex===index && (darkTheme?'#2B2A2A':'#FFFEFE')),
                marginTop:10,paddingHorizontal:10,paddingVertical:5,
                borderRadius: 5,marginBottom: 1,flexDirection:'row',flexWrap:'wrap',
                alignItems:'center',marginHorizontal: 5,borderWidth:1.5,
                borderColor:(isActive(navItem==='Accounts'?'AdminAccountsList':navItem==='Home'?'Admin':
                navItem ==='BlogsHome'?'Home': navItem
                )
                || hoveredIndex) ? (darkTheme?'#454545':'#EDEAEA'):'transparent',
            },
        ]}
        >
            <Text style={{ fontSize: isNarrowScreen? 18:20,
                color:darkTheme?'#ffffff':'#121212',fontWeight:'bold'
            }}>{navItem==='BlogsHome'?'Home':navItem}</Text>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container:{
        borderRadius:5,
        backgroundColor:''
    }
})
