// this component is a card that describes the layout of the blogs card
// its style is defined inside this file with imported dimensions

import React from 'react'
import {View, Text,TouchableOpacity, useWindowDimensions} from 'react-native'
import { ExeCardStyle } from '../../utils/app-styles'
import FormData from 'form-data'
import axios from 'axios'
import { baseUrl, baseUrlImage } from '../../utils/urls'
import {Image} from 'expo-image'
import { blurHash } from '../../utils/extra-utils'
import DateTimeFormatter from '../../utils/date-time-util-formatter'
import { useTheme } from '../../utils/state-context'
import { useAuthentication } from '../../utils/auth-context'

const ExecuteCard=({data,navigation})=>{
    // const  nav = useNavigation()
    const styles = ExeCardStyle()
    const {auth} = useAuthentication()
    const{darkTheme} = useTheme()
    const token = auth.token
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    //date management
    const createdDate = new Date(data.created_at)
    const {day,month,year} = DateTimeFormatter(createdDate)
    // converts story object to string
    const content = data.story
    // set for form-data post format
    const formData= new FormData()
    formData.append('id',data.id)

    const deleteBlog = async()=>{
        await axios.delete(
            `${baseUrl}delete/`,
            {
                headers:
                {
                    Authorization: `Token ${token}`,
                },
                data: formData,
            }
        ).then((response)=>{
            alert(response.data)
            navigation.reset({
                index: 0,
                routes: [{ name: 'Profile' }],
            })
        }).catch((err)=>{
            alert(err)
        })
    }
    return(
        <View style={styles.container} key={data.id}>
            <View style={{width: '70%',marginRight:5}}>
                <TouchableOpacity
                onPress={()=> navigation.navigate('AuthBlogRead',{id: data.id})}
                >
                    <Text style={styles.txt}>{data.title}</Text>
                    <View style={{flexDirection:'row',marginTop: 5, flexWrap:'wrap'}}>
                        <Text style={styles.txtMetrics}>Published</Text>
                        <Text style={styles.txtMetricPoints}>{`${month} ${day}, ${year}`}</Text>
                        <View style={{width:20}}/>
                        <Text style={styles.txtMetrics}>Views</Text>
                        <Text style={styles.txtMetricPoints}>{data.views}</Text>
                        <View style={{width:20}}/>
                        <Text style={styles.txtMetrics}>Likes</Text>
                        <Text style={styles.txtMetricPoints}>{data.likes}</Text>
                        <View style={{width:20}}/>
                        <Text style={styles.txtMetrics}>Dislikes</Text>
                        <Text style={styles.txtMetricPoints}>{data.dislikes}</Text>
                    </View>
                </TouchableOpacity>
                <View style={{flexDirection:'row',flexWrap:'wrap'}} >
                    <TouchableOpacity style={{
                        borderWidth: 1,
                        paddingVertical: 5,paddingHorizontal: 10,
                        marginTop: 10, marginBottom: 5,
                        marginHorizontal: 5,
                        borderRadius: 5,
                        borderColor:darkTheme?'#f3f3f3':'#121212',
                        width: isNarrowScreen?'25%':'15%',alignItems:'center',justifyContent:'center'
                    }} onPress={()=>{
                        const encodedData = encodeURIComponent(JSON.stringify(data))
                        navigation.navigate('UpdatePost',{data: encodedData})
                    }}>
                        <Text style={{
                            fontSize: 12,fontFamily:'roboto-regular',fontWeight: '400',
                            color:darkTheme?'#f3f3f3':'#121212'
                        }}>Edit</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={{
                        borderWidth: 1,borderColor:darkTheme?'#f3f3f3':'#121212',
                        paddingVertical: 5,paddingHorizontal: 10,
                        marginTop: 10, marginBottom: 5,
                        marginHorizontal: 5,
                        borderRadius: 5,
                        width: isNarrowScreen?'25%':'15%',alignItems:'center',justifyContent:'center'
                    }} onPress={deleteBlog}>
                        <Text style={{
                            fontSize: 12,fontFamily:'roboto-regular',fontWeight: '400',
                            color:darkTheme?'#f3f3f3':'#121212'
                        }}>Delete</Text>
                    </TouchableOpacity> */}
                </View>
            </View>
            {
            data.contentImages.length>0 &&
            <Image
            contentFit="contain" placeholder={blurHash}
            source={{ uri: `${baseUrlImage}${data.contentImages[0].media}` }} style={{
                height: 100,
                width: '30%',
            }}/>
            }
        </View>
    )
}
export default ExecuteCard
