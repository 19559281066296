import * as React from 'react'
import { NewBlogContextProps, Story } from './create-story-data'


export const NewStoryContext = React.createContext<NewBlogContextProps | undefined>(undefined)

export const NewStoryProvider = ({children}:React.PropsWithChildren):JSX.Element=>{
    const [newBlog,setNewBlog] = React.useState<Story>({})
    return <NewStoryContext.Provider value={{
        newBlog,setNewBlog
    }}>{children}</NewStoryContext.Provider>
}
