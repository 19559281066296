import * as React from 'react'
import { Feather, Ionicons,MaterialCommunityIcons } from '@expo/vector-icons'
import {View,Text,TouchableOpacity, useWindowDimensions} from 'react-native'
import * as Linking from 'expo-linking'
import { BottomSideStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import { LinearGradients } from './custom-components/gradient-applier'
import { useNavigation } from '@react-navigation/native'

export function Contacts({navigation}):React.JSX.Element{
    const [hoveredIndex, setHoveredIndex] = React.useState(null)
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const instagramLink = ()=>{
        alert('to be provided soon!')
        // Linking.openURL('https://bumho-nisubire.web.app')
    }
    const emailLink = () =>{
        Linking.openURL('mailto:youstory@gmail.com')
    }
    const linkWarning=()=>{
        alert('to be provided soon!')
    }
    const bottomSideStyle = BottomSideStyle()
    const handleMouseEnter = (index) => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndex(null)
    }
    return (
        <View>
            <View style={{
                marginTop:20,
                flexDirection:'row',flexWrap:'wrap',
                width:'100%',
                borderRadius: 10,padding: 7,alignItems:'center',justifyContent:'center'
            }}>
                <TouchableOpacity style={[bottomSideStyle.linkWrapper,{paddingHorizontal: 10}]} onPress={emailLink}>
                    <MaterialCommunityIcons name='gmail'size={20}
                    color={hoveredIndex === 0 ? '#1A8CF1' : darkTheme?'#ffffff':'#4B4A4A'}
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={() => handleMouseLeave()}/>
                </TouchableOpacity>
                <TouchableOpacity style={[bottomSideStyle.linkWrapper,{paddingHorizontal: 10}]} onPress={instagramLink }>
                    <LinearGradients
                    iconSize={20}
                    styles={{ borderRadius: 5,alignItems:'center',justifyContent:'center' }}
                    colors={['#405DE6', '#5851DB', '#833AB4', '#C13584', '#E1306C', '#FD1D1D']}
                    >
                            <Feather name="instagram" color="#ffffff" size={20} />
                    </LinearGradients>
                </TouchableOpacity>
                <TouchableOpacity style={[bottomSideStyle.linkWrapper,{paddingHorizontal: 10}]}onPress={linkWarning }>
                    <Feather name='message-square'size={20}
                    color={hoveredIndex === 2 ? '#1A8CF1' : darkTheme?'#ffffff':'#4B4A4A'}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={() => handleMouseLeave()}/>
                </TouchableOpacity>
                <TouchableOpacity style={[bottomSideStyle.linkWrapper,{paddingHorizontal: 10}]}onPress={linkWarning }>
                    <Ionicons name='logo-whatsapp'size={20}
                    color={hoveredIndex === 3 ? '#1A8CF1' : darkTheme?'#ffffff':'#4B4A4A'}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={() => handleMouseLeave()}/>
                </TouchableOpacity>
            </View>
            <View style={bottomSideStyle.bottomContent}>
                {/* <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper}
                onPress={instagramLink }
                >
                    <Text style={bottomSideStyle.bottomLinkText}>📞Help</Text>
                </TouchableOpacity> */}
                <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper} onPress={()=>{
                    navigation.navigate('About')
                }}>
                    <Text style={bottomSideStyle.bottomLinkText}>📌 About YourStory</Text>
                </TouchableOpacity>
                <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper} onPress={()=>{
                    navigation.navigate('Terms')
                }}
                >
                    <Text style={bottomSideStyle.bottomLinkText}>📜Terms and Conditions</Text>
                </TouchableOpacity>
                <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper} onPress={()=>{
                    navigation.navigate('Policy')
                }}
                >
                    <Text style={bottomSideStyle.bottomLinkText}>👀Privacy and Policy</Text>
                </TouchableOpacity>
            </View>
            <View style={{flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                justifyContent:'center',alignSelf:'center'
            }}>
                <Text style={bottomSideStyle.copyRight}> Your Story </Text>
                <MaterialCommunityIcons name="copyright" size={16} color={darkTheme?'#ffffff':'#121212'} />
                <Text style={{ color: darkTheme?'#ffffff':'#121212' }} >2024</Text>
            </View>
        </View>
    )
}