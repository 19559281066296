import { Author, BlogContentStory, BlogStory, ContentImage, Trend,Comment } from "./story-data";
import { BlobImage, ContentStory, Story } from "./create-story-data";

export function mainBlogSetter(story:
    {
        id: any; title: any;topic:any;
        owner: any; created_at: any; update_at: any;
        likes: any; dislikes: any; views: any;
        story:any,
        contentImages: any,
        commission: any;
    }):BlogStory{
    return {
        id: story.id,
        title:story.title,
        topic:story.topic,
        author: authorSetter(story.owner),
        created_at: story.created_at,
        content:blogContentSections(story.story),
        contentImages: contentImagesSetter(story.contentImages),
        update_at: story.update_at,
        likes: story.likes,
        dislikes: story.dislikes,
        views: story.views,
        commission: story.commission
    }
}

export function allBlogsSetter(story:any):BlogStory[]{
    return story.map((item:any)=>mainBlogSetter(item))
}

export function filteredBlogsSetter(story:any):BlogStory[]{
    return story.map((item:any)=>mainBlogSetter(item))
}

export function suggestedBlogsSetter(story:any):BlogStory[]{
    return story.map((item:any)=>mainBlogSetter(item))
}

export function contentImagesSetter(contentImages: any):ContentImage[]{
    return  contentImages.map((item: any)=> contentImageSetter(item))
}

export function contentImageSetter(data:any):ContentImage{
    return{
        id:data.id,
        index: data.index,
        media: data.media,
        type: data.type,
        imageCopyright:data.imageCopyright

    }
}

export function blogContentSections(data:any):BlogContentStory[]{
    return data.map((storyInstance:any)=> blogContentSection(storyInstance))
}

export function blogContentSection(data:any):BlogContentStory{
    return{
        id: data.id,
        index: data.index,
        story: data.story,
        type: data.type
    }
}


export function authorSetter(owner:
    {
        owner_name: any; owner_description: any; owner_profile:any;
        owner_links:any;
    }): Author{
    return{
        name: owner.owner_name,
        description: owner.owner_description,
        profile: owner.owner_profile,
        links: owner.owner_links,
    }
}

export function createBlogSetter(data: {
    title: any; content: any[]; contentImages: any;
    topic?: any;
}):Story{
    return{
        title:data.title,
        content: createContent(data.content),
        contentImages: createImages(data.contentImages),
        topic: data.topic,
    }
}

export function createContent(content: any[]):ContentStory[]{
    return content.map((storyInstance:any)=> createStoryInstance(storyInstance))
}

export function createStoryInstance(storyInstance:
    {
        logicalIndex: any; text: any; type: any;
    }): ContentStory{
    return{
        logicalIndex:storyInstance.logicalIndex,
        text: storyInstance.text,
        type: storyInstance.type,
    }
}

export function createImages(images: any[]):BlobImage[]{
    return images.map((img: any)=>createImage(img))
}

export function createImage(img:
    {
        logicalIndex: any; blob: any; filename: any; type: any;
    }):BlobImage{
    return{
        logicalIndex: img.logicalIndex,
        blob: img.blob,
        filename: img.filename,
        type: img.type
    }
}


export function trendingSetter(data:{
    topic: string,
    total_views: number,
    total_blogs: number,
}): Trend{
    return {
        topic: data.topic,
        total_blogs: data.total_blogs,
        total_views: data.total_views,
    }
}

export function commentSetter(data:{
    id:number,user_name: string,user_email:string,
    user_id: number,user_profile:string,comment:string,
    created_at: string,updated_at:string,blog_id: number
}):Comment{
    return {
        id:data.id,user_name: data.user_name,
        user_email: data.user_email,user_id: data.user_id,
        user_profile: data.user_profile,blog_id:data.blog_id,
        comment: data.comment,created_at:data.created_at,
        updated_at: data.updated_at
    }
}

export function commentsSetter(data: any[]):Comment[]{
    return data.map((datum: any)=>commentSetter(datum))
}
