import * as React from 'react'
import { Ionicons } from "@expo/vector-icons"
import { TouchableOpacity, View,Text, Pressable } from "react-native"

export function TrendBar({trending,darkTheme,navigation,isNarrowScreen,isFilter}){
    const [hoveredIndex, setHoveredIndex] = React.useState(null)

    const pressHandler = (item:any) => {
        navigation.navigate('FilterBlogs',{topic:item.topic})
    }
    const handleGoBack = () => {
        navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
        })
    }

    const handleMouseEnter = (index:number) => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndex(null)
    }

    return(
        <View style={{
            justifyContent:'space-between',
            alignItems:'center',
            marginTop: isNarrowScreen && 10,marginBottom: 10,
            backgroundColor:isNarrowScreen && (darkTheme?'#121212':'#ffffff'),
            borderColor:isNarrowScreen && (darkTheme?'#454545':'#EDEAEA'),
            padding: 10,borderRadius:10,
            width:'100%',
            flexDirection:isNarrowScreen?'row':'column',flexWrap:'wrap',
            paddingHorizontal:5,
        }}>
            <View style={{
                flexDirection:isNarrowScreen ?'row':'column',flexWrap:'wrap',paddingHorizontal: 5,
                justifyContent:'space-evenly', alignContent:'center',
                width: '95%'
            }}>
                {isNarrowScreen && isFilter && <TouchableOpacity style={{
                    alignItems:'baseline',alignSelf:'baseline',
                    padding:5, flexDirection:'row',
                }}
                onPress={handleGoBack}
                >
                    <Ionicons name="arrow-back-circle-outline" size={isNarrowScreen?22:24}
                    color={'#1A8CF1'}
                    />
                </TouchableOpacity>}
                {trending.length > 0 && trending.map((item: any, index:number) => (
                    <Pressable onPress={()=>pressHandler(item)}
                    //@ts-ignore
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave()}
                    style={({ pressed }) => [
                        {
                            backgroundColor: pressed ? darkTheme?'#2B2A2A':'#FFFEFE' : (hoveredIndex===index && (darkTheme?'#2B2A2A':'#FFFEFE')),
                            borderRadius: 5,padding: 3,marginVertical: 5,flexDirection:'row',flexWrap:'wrap',
                            alignItems:'baseline'
                        },
                    ]} key={index}
                    >
                        <Text style={{
                            fontSize: isNarrowScreen? 18:20,marginRight: 5,
                            color:'#1A8CF1',fontWeight:'bold'
                        }}>
                            {`#${index+1}`}
                        </Text>
                        <Text key={index} style={{
                            fontFamily:'roboto-regular',
                            fontWeight: hoveredIndex==index ?'bold':'500',
                            fontSize: hoveredIndex===index?(isNarrowScreen? 15:16):(isNarrowScreen? 14:15),
                            color:'#1A8CF1'
                        }}
                        >{`${item.topic}`}</Text>
                    </Pressable>
                ))}
            </View>
        </View>
    )
}