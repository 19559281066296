import * as React from 'react'
import {NavigationContainer,useNavigation} from '@react-navigation/native'
import {createStackNavigator} from '@react-navigation/stack'
import Home from './screens/home'
import Blogs from './screens/blogs'
import NavigationBar from './components/navigation-bar'
import BlogRead from './screens/blog-read'
import linking from './utils/linker'
import { useTheme } from './utils/state-context'
import NewPostMultiple from './screens/create-post'
import MyBlogs from './screens/author-blogs'
import { Profile } from './screens/profile'
import { View ,Text, useWindowDimensions,TouchableOpacity} from 'react-native'
import { NavigationBarStyle } from './utils/app-styles'
import { SearchBar } from './components/custom-components/search-bar'
import FilterBlogs from './screens/filter-blogs'
import BlogReadAuthor from './screens/author-read-blog'
import UpdatePost from './screens/update-post'
import SignIn from './screens/sign-in'
import SignUp from './screens/sign-up'
import { Admin } from './admin/admin'
import AdminSignIn from './admin/admin-login'
import AdminNavigationBar from './admin/admin-components/admin-navigation-bar'
import { SearchScreen } from './screens/blog-search-screen'
import { AdminAccountsList } from './admin/admin-accounts-screen'
import { Bookmarks } from './screens/bookmarks'
import { Latest } from './screens/latest-blogs-screen'
import { AdminRequestScreen } from './admin/admin-requests-screen'
import { useAuthentication } from './utils/auth-context'
import Terms from './policies/terms'
import Policy from './policies/policy-screen'
import About from './policies/about'


const Stack = createStackNavigator()

function AllStacks(){
    const navBarStyle = NavigationBarStyle()
    const navigation = useNavigation<any>()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    return(
        <Stack.Navigator
        screenOptions={({route})=>({
            headerShown: true,
            headerMode:'float',
            headerStyle:{
                backgroundColor: darkTheme? '#12222F':'#12222F'
            },
            headerLeft:()=>{
                return(
                    route.name==='Admin' || route.name === 'AdminSignIn'|| route.name==='AdminAccountsList'||
                    route.name==='AdminRequestScreen'
                    ?
                    <View style={[navBarStyle.titleWrapper,{paddingLeft:20,flexDirection:'row'}]}>
                        <Text style={navBarStyle.title}>Admin </Text>
                    </View>:
                    <View style={[navBarStyle.titleWrapper,{paddingLeft:20,flexDirection:'row'}]}>
                    <TouchableOpacity
                    onPress={()=>{
                        // Reload the entire web app
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        })
                    }}
                    >
                        <Text style={navBarStyle.title}>Your Story</Text>
                    </TouchableOpacity>
                    {!isNarrowScreen && <SearchBar navigation={navigation}/>}
                    </View>
                )
            },
            headerRight:()=>{
                return( route.name ==='Admin'|| route.name === 'AdminSignIn'|| route.name==='AdminAccountsList'||
                route.name==='AdminRequestScreen'
                ?<AdminNavigationBar navigation={navigation}/>:
                    <NavigationBar
                    navigation={navigation}
                    />
                )
            },
            headerTitle:'',
        })}
        >
            <Stack.Screen name='Home' component={Home}/>
            <Stack.Screen name='Blogs' component={Blogs} />
            <Stack.Screen name='Register' component={SignUp} />
            <Stack.Screen name='SignIn' component={SignIn}/>
            <Stack.Screen name='Profile' component={Profile} />
            <Stack.Screen name='Bookmarks' component={Bookmarks} />
            <Stack.Screen name='NewPost' component={NewPostMultiple}/>
            <Stack.Screen name='BlogRead' component={BlogRead} />
            <Stack.Screen name='MyBlogs' component={MyBlogs}/>
            <Stack.Screen name='FilterBlogs' component={FilterBlogs}/>
            <Stack.Screen name='Latest' component={Latest} />
            <Stack.Screen name='AuthBlogRead' component={BlogReadAuthor}/>
            <Stack.Screen name='UpdatePost' component={UpdatePost}/>
            <Stack.Screen name='Admin' component={Admin}/>
            <Stack.Screen name='AdminAccountsList'component={AdminAccountsList}/>
            <Stack.Screen name='AdminRequestScreen' component={AdminRequestScreen}/>
            <Stack.Screen name='AdminSignIn' component={AdminSignIn}/>
            <Stack.Screen name='Search' component={SearchScreen}/>
            <Stack.Screen name='About' component={About}/>
            <Stack.Screen name='Terms' component={Terms}/>
            <Stack.Screen name='Policy' component={Policy}/>
        </Stack.Navigator>
    )
}

export default function Navigation({colorScheme}){
    const {setDarkTheme} = useTheme()
    const {auth,loadUserDataFromFile,fetchUser,loggedOut} = useAuthentication()

    React.useEffect(()=>{
        const fetchData = async () => {
            if (!loggedOut) {
                await loadUserDataFromFile();
                auth && fetchUser();
            }
        };
        fetchData();
    },[auth, loggedOut, loadUserDataFromFile, fetchUser])
    const userIsAuthenticated = () => {
        return auth === null || loggedOut;
    }
    React.useEffect(()=>colorScheme === 'dark' ? setDarkTheme(false) : setDarkTheme(false))
    return(
        <NavigationContainer
        linking={linking}
        >
            <AllStacks/>
        </NavigationContainer>
    )
}
