import * as React from 'react'
import { View, Text, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { NavigationBarStyle } from '../../utils/app-styles'
import { useTheme } from '../../utils/state-context'
import { useAdminAuthentication } from '../admin-utils/admin-auth-context'


const AdminNavigationBar = ({ navigation }) => {
    const navBarStyle = NavigationBarStyle()
    const {darkTheme} = useTheme()
    const route = useRoute()
    const [token,setToken] = React.useState(null)
    const {auth,logout} = useAdminAuthentication()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    React.useEffect(() => {
        if(auth !==null){
            setToken(auth.token)
        }
    }, [route.name,auth])

    const isActive = (routeName: string) => route.name === routeName

    return (
        <View
        style={navBarStyle.btnWrapper}
        >
            <View style={{ marginHorizontal: 10 }}>{
                auth ? <View style={{ paddingHorizontal: 30 }}>
                    <TouchableOpacity
                        style={navBarStyle.btnLogin}
                        onPress={()=>{
                            logout()
                            navigation.navigate('AdminSignIn')
                        }}
                    >
                        <Text style={navBarStyle.textLogin} >Sign Out</Text>
                    </TouchableOpacity>
                </View>
                :<View style={{ paddingHorizontal: 30 }}>
                    <TouchableOpacity
                        style={navBarStyle.btnLogin}
                        onPress={()=>navigation.navigate('SignIn')}
                    >
                        <Text style={navBarStyle.textLogin} >Sign In</Text>
                    </TouchableOpacity>
                </View>
            }</View>
        </View>
    )
};

export default AdminNavigationBar
