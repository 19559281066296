export interface Request{
    id: number;
    user_id: number;
    email: string;
    name:string;
    profile?: string;
    created_at:string;
    updated_at:string;
    isExecuted:boolean;
}

export function requestSetter(data:{
    id:number,user_id:number,user_name:string,user_email:string,created_at:string,
    updated_at:string,isExecuted: boolean,user_profile?: string
}):Request{
    return{
        id:data.id, user_id:data.user_id,name:data.user_name,
        email:data.user_email, created_at: data.created_at,
        updated_at:data.updated_at,isExecuted: data.isExecuted,
        profile: data.user_profile
    }
}

export function requestsSetter(data:any):Request[]{
    return data.map((item:any)=>requestSetter(item))
}