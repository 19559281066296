import * as React from 'react'
import { baseUrl } from '../utils/urls'
import axios from 'axios'
import { BlogStory } from '../utils/story-data'
import { allBlogsSetter } from './story-interface-setter'

export const AllBlogsContext = React.createContext<{
    allBlogs: BlogStory[];
    refreshAllBlogs: ()=> void;
}>({
    allBlogs: [],
    refreshAllBlogs: ()=> {},
})

export const AllBlogsProvider = ({children}: React.PropsWithChildren):JSX.Element=>{
    const [allBlogs,setAllBlogs] = React.useState<BlogStory[]>([])

    const refreshAllBlogs= React.useCallback(()=>{
        axios.get(`${baseUrl}`)
        .then((response)=>{
            setAllBlogs(allBlogsSetter(response.data))
        })
    },[])

    React.useEffect(()=>{
        refreshAllBlogs()
    },[refreshAllBlogs])
    return <AllBlogsContext.Provider
    value={{allBlogs,refreshAllBlogs}}
    >{children}</AllBlogsContext.Provider>
}