import { StyleSheet, useWindowDimensions } from "react-native"
import { useTheme } from "./state-context"
import { windowHeight } from "./dimensions"


export const CredentialFormStyle= ()=>{
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        inputField: {
            width:'100%',
            borderWidth: 2,
            borderColor:'#1A8CF1',
            backgroundColor: darkTheme?'#121212':'#f3f3f3',
            overlayColor:'#1A8CF1',
            paddingVertical: 8,
            paddingHorizontal: 10,
            borderRadius:5,
            marginBottom: 10,
            fontFamily:'roboto-medium',
            color:darkTheme?'#ffffff':'#121212',
            // @ts-ignore
            outlineColor:'#1A8CF1',
        },
        focusedInput: {
            borderColor: 'blue', // Change this to the desired color when focused
        },
    })
    return styles
}

export const NavigationBarStyle =()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        titleWrapper: {
            alignItems: 'center',
            justifyContent:'center',alignContent:'center',
            alignSelf:'flex-start',
        },
        btnWrapper:{
            alignContent:'space-between',
            alignItems: 'center',
            flexDirection:'row',
            alignSelf:'flex-end',
            justifyContent:'space-between',
            marginBottom: 10,
            marginTop: 10, paddingRight: 10,
            // width: isNarrowScreen? 200: 300
        },
        btn:{
            borderRadius: 5,
            borderWidth:1,
            borderColor:'#f3f3f3',
            color: '#ffffff',
            alignItems: 'center',
            alignContent: 'center',
            paddingHorizontal:20,
            height: 30,
            alignSelf: 'center',
            flexDirection: 'row',
        },
        btnActive:{
            borderRadius: 5,
            borderWidth:1,
            borderColor: '#f3f3f3',
            color: '#2B2A2A',
            backgroundColor: '#22415a',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            paddingRight: 20,
            height: 30,
            alignSelf: 'center',
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5
        },
        btnActiveBar:{
            backgroundColor: '#1A8CF1',
            width: 5,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            height: 30,
            marginRight: 2
        },
        btnLogin:{
            borderRadius: 5,
            borderWidth:1,
            borderColor:'#f3f3f3',
            color: '#2B2A2A',
            paddingHorizontal:10,
            paddingVertical: 5,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title:{
            fontSize: 32,
            fontWeight: 'bold',
            color: '#1A8CF1',
            fontFamily: 'roboto-bold'
        },
        titleMini:{
            fontSize: 12,
            color:'#2E2E2E',
            alignSelf: "center",
            fontFamily: 'roboto-italic'
        },
        btnText:{
            fontFamily:'roboto-bold',
            fontSize: 18,
            fontWeight: 'bold',
            color: '#ffffff',
            alignSelf:'center'
        },
        textLogin:{
            fontFamily: 'roboto-regular',
            color:'#ffffff',
            fontSize: 16,
            fontWeight:'400',
        },
    })
    return styles
}


export const LoginStyle= ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        container:{
            flex:1,
            backgroundColor: darkTheme?'#121212':'#f3f3f3',
            alignItems:'center',justifyContent:'center',
            width:'100%'
        },
        topContainer:{
            // paddingHorizontal: 5,
            width: isNarrowScreen?'90%':400,
            borderRadius: 10,
            borderColor:darkTheme?'#EDEAEA':'#19646454',
            backgroundColor:darkTheme? '#12222F':'#ffffff',
            borderWidth: 1,
            paddingBottom: 10,
            alignSelf:'center',
            alignContent:'center',alignItems:'center',
        },
        topBar:{
            backgroundColor: '#12222F',
            borderTopRightRadius:10,
            borderColor:darkTheme?'#EDEAEA':'#4545454',
            borderTopLeftRadius:10,
            width: '100%'
        },
        button: {
            backgroundColor: 'lightblue',
            padding: 10,
            alignItems: 'center',
        },
        inputContainer: {
            width: isNarrowScreen?'80%':'50%',
            // marginBottom: 20,
        },
        textContainer:{
            alignItems: 'center',
            alignContent: 'center',
            marginVertical:5
        },
        bottomLinkText:{
            fontSize: isNarrowScreen? 12:14,
            // fontWeight: 'bold',
            fontFamily:'roboto-regular',
            color: darkTheme?'#ffffff':'#3B3B3B',
        },
        btn:{
            borderWidth: 1.5,
            borderColor: darkTheme?'#f3f3f3':'#141414',
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 7,
            paddingVertical: 5,
            width: '40%',
            alignSelf:'center',
            marginTop: 10
        },
        extendedBtn:{
            marginTop:10,
            alignItems: 'center',
            justifyContent:'center',
            backgroundColor: '#1A8CF1',
            paddingVertical: 10,
            paddingHorizontal: 30,
            borderRadius: 20,
        },
        extendText:{
            color: '#ffffff',
            fontSize: 14,
            fontWeight: '200',
            fontFamily: 'roboto-regular',
        },
        textDesc:{
            fontSize: 14,
            fontFamily:'roboto-regular',
            marginTop: 10,
            color: darkTheme?'#ffffff':'#6B6B6B',
        },
        text: {
            fontWeight: 'bold',
            fontSize: 16,
            justifyContent: 'center',
            alignItems: 'center',
            color: darkTheme?'#ffffff':'#121212',
            fontFamily:'roboto-regular',
        },
        registerText:{
            fontFamily: 'roboto-italic',
            fontSize: 16,
            justifyContent: 'center',
            fontWeight:'900',
            color:'#1A8CF1'
        }
    })
    return styles
}

// handles home styling
export const HomeStyle  = ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        trendingWrapper:{
            flexDirection:'column',
            paddingLeft: 10,
            alignContent: 'space-around',
            alignItems: 'flex-start',
            marginBottom: 20
        },
        topicsWrapper:{
            padding: 5,
            alignItems: 'flex-start',
        },
        listedText:{
            fontSize:isNarrowScreen?14:16,
            color:darkTheme?'#f3f3f3':'#2F2E2E',
            fontWeight:'600',
            fontFamily:'roboto-regular',
        },
        networkText:{
            fontSize: 14,
            fontFamily:'roboto-italic',
            fontWeight: '100',
            color: darkTheme?'#F3F3F3':'#6B6B6B'
        },
    })
    return styles
}

export const BlogsStyle =()=> {
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const  styles = StyleSheet.create({
        starter:{
            minHeight: 150,
            width: '100%',
            alignContent: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingTop: 70,
            paddingBottom: 50,
            marginBottom: 5,
            backgroundColor: darkTheme?'#9A9999': '#F3F3F3'
        },
        container:{
            flex:1,
            alignItems:'center',
            backgroundColor: darkTheme?'#121212':'#ffffff',
            paddingLeft: 15,
            paddingRight:15,
        },
        topicsWrapper:{
            padding: 10,
            alignItems: 'center',
            alignContent: 'space-around',
            width: isNarrowScreen?windowWidth*0.9:windowWidth*0.45,
        },
        trendingWrapper:{
            flexDirection:'column',
            padding: 10,
            alignItems: 'center',
            alignContent: 'space-around',
            width: isNarrowScreen?windowWidth:windowWidth*.4,
        },
        btn:{
            color: '#fff',
            marginTop: windowWidth>400 ?20:10,
            borderRadius: 20,
            backgroundColor:'#1A8CF1',
            padding: 10,
            alignItems: 'center',
        },
        text: {
            fontSize: 16,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#121212',
            fontFamily: 'roboto-italic',
            alignSelf: 'center'
        },
        trendingText:{
            fontSize:16,
            color:'#121212',
            fontWeight:'700',
            fontFamily: 'roboto-bold',
            margin: 15,
        },
        titleText:{
            fontFamily: 'roboto-bold',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: isNarrowScreen?30: 36,
            color: '#121212',
            flexWrap: 'wrap',
            alignSelf: 'center'
        },
        preText:{
            fontFamily: 'roboto-italic',
            fontSize: isNarrowScreen?20: 25,
            color: '#ffffff',
            width: windowWidth *0.4
        },
    })
    return styles
}


export const FormStyle= ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        inputField: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            color:darkTheme?'#f3f3f3':'#121212',
            // width:'100%',
            backgroundColor: 'transparent',
            fontFamily: 'roboto-medium',
            // @ts-ignore
            outlineColor: 'transparent',
        },
        profileInputField:{
            padding: 10,
            marginTop: 5,
            marginBottom: 10,
            paddingLeft:5,
            alignItems: 'center',
            color:darkTheme?'#f3f3f3':'#121212',
            justifyContent:'center',
            alignContent:'center',
            borderRadius: 5,
            outlineColor: 'transparent',
        }
    })

    return styles
}

export const SearchBarStyle = ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const{darkTheme} = useTheme()
    const styles = StyleSheet.create({
        inputField:{
            backgroundColor: darkTheme?'#121212':'#F0F0F0',
            fontSize: 14,
            minWidth: windowWidth *.3,
            height: 40,
            marginHorizontal:5,
            paddingVertical: 5,
            color: darkTheme?'#f3f3f3':'666',
            paddingHorizontal: 10,
            // @ts-ignore
            outlineColor: 'transparent',
        }
    })
    return styles
}

export const ListCardStyle =()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        container:{
            flex:1,
            flexDirection: 'row',
            marginBottom: 30,
            alignContent: 'flex-start',
            alignItems: 'center',
            justifyContent:'flex-start',
            width: '100%',
            height:150,
            // borderBottomWidth: 1,
            // borderBottomColor: darkTheme?'#454545':'#EDEAEA',
        },
        txt:{
            // fontSize:  isNarrowScreen?16:18,
            fontWeight: '700',
            fontFamily: 'roboto-medium',
            color:darkTheme?'#ffffff':'#242424',
            padding: 5,
            width: '100%'
        },
        authorText:{
            color: darkTheme?'#ffffff':'#2F2F2F',
            fontSize: isNarrowScreen? 11: 13,
            fontWeight: '100',
            fontFamily: 'roboto-bold',
            width: '100%'
        },
        contentText:{
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontSize: isNarrowScreen? 12: 14,
            fontFamily: 'merriweather-regular',
            width: '100%'
        },
        dateText:{
            fontSize: isNarrowScreen? 11:12,
            fontWeight: '700',
            color: darkTheme?'#ffffff':'#121212',
            fontFamily: 'roboto-italic'
        },
        viewsSection:{
            flexDirection: 'row',
            paddingVertical: 2,
            alignItems: 'center',
            paddingHorizontal: 10,
            backgroundColor:'#1A8CF1',
            borderRadius: 4,marginTop: 5,
            marginHorizontal:5,
            borderColor:'#6B6B6B'
        },
    })
    return styles
}

export const BlogCardStyle =()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        container:{
            marginRight: 5,
            marginBottom: 5,marginTop: 10,
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            justifyContent:'flex-start',
            // width: '45%'

        },
        txt:{
            // fontSize:  isNarrowScreen?12:14,
            fontWeight: '700',
            fontFamily: 'roboto-medium',
            color:darkTheme?'#ffffff':'#242424',
        },
        authorText:{
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'roboto-bold',
        },
        contentText:{
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontSize: isNarrowScreen? 11: 12,
            fontWeight: '200',
            fontFamily: '6B6B6B',
            width: '100%'
        },
    })
    return styles
}

export const ExeCardStyle =()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        container:{
            flex:1,
            flexDirection: 'row',
            paddingVertical: 10,paddingHorizontal: 5,
            marginBottom: 30,
            alignContent: 'space-between',
            alignItems: 'center',
            justifyContent:'space-between',
            width: '100%',
            height:100,
            borderRadius: 5, backgroundColor: darkTheme?'#12222F':'#FFFFFF'
        },
        txt:{
            fontSize:  isNarrowScreen?14:16,
            fontWeight: '700',
            fontFamily: 'roboto-medium',
            color:'#1A8CF1',
            paddingTop: 5,
            width: '100%'
        },
        txtMetrics:{
            fontSize:  isNarrowScreen?13:14,
            fontWeight: '700',
            fontFamily: 'roboto-medium',
            color:darkTheme?'#ffffff':'#6D6D6D',
            paddingTop: 10,
            marginRight: 10,
        },
        txtMetricPoints:{
            fontSize:  isNarrowScreen?13:14,
            fontFamily: 'roboto-medium',
            color:darkTheme?'#ffffff':'#6D6D6D',
            paddingTop: 10,
        },
        authorText:{
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'roboto-bold',
        },
        contentText:{
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontSize: isNarrowScreen? 14: 16,
            fontFamily: '6B6B6B',
            width: '100%'
        },
    })
    return styles
}

export const BlogContentStyle = ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        upperWrapper:{
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            width: '100%'
        },
        lowerWrapper:{
            flexWrap: 'wrap',
            paddingHorizontal: 10,
            width: '100%',
        },
        actionWrapper:{
            alignItems:'center',
            justifyContent: 'center',
            marginTop:10,
            minHeight: 50,
            flexDirection: isNarrowScreen ? 'row':'column'
        },
        actionBtn:{
            alignItems: 'center',
            alignContent: 'center',
            alignSelf: 'center',
            paddingVertical: 10,
            // paddingHorizontal: 20,
            marginHorizontal: 10,
            borderRadius: 5,

        },
        timeStampWrapper:{
            flexDirection:isNarrowScreen? 'row':'column',
            flexWrap: 'wrap' ,
            padding:isNarrowScreen?1: 5,
            justifyContent: isNarrowScreen?'flex-start': 'center',
            alignItems:'flex-start',
            marginBottom: 20
        },
        imgContentWrapper:{
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
        },
        img:{
            width: '100%',
            height: windowWidth<=600?200:400,
            borderRadius: 5,
        },
        imgCopyrightText:{
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'roboto-italic',
            color: darkTheme?'#ffffff':'#121212',
        },
        title:{
            fontSize: isNarrowScreen? 32: 42,
            color: darkTheme?'#ffffff':'#121212',
            flexWrap: 'wrap',
            fontFamily: 'roboto-Bold',
            letterSpacing: 1,
        },
        timeText:{
            fontSize: isNarrowScreen? 12:14,
            fontWeight: 'bold',
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontFamily: 'roboto-italic'
        },
        dateText:{
            fontSize: isNarrowScreen? 12:14,
            fontWeight: 'bold',
            color: darkTheme?'#ffffff':'#6B6B6B',
            fontFamily: 'roboto-italic'
        },
        editorText:{
            fontSize: isNarrowScreen? 16:18,
            color: darkTheme?'#ffffff':'#121212',
            fontFamily: 'roboto-bold'
        },
        contentText:{
            color: darkTheme?'#ffffff':'#3B3B3B',
            fontSize: 16,
            fontFamily: 'merriweather-regular',
            // letterSpacing: .8,
        },
        subTitleText:{
            color: darkTheme?'#ffffff':'#3B3B3B',
            fontSize: isNarrowScreen? 25: 30,
            fontFamily: 'roboto-bold',
            marginBottom: 10,width:'100%'
        },
        extendedBtn:{
            alignItems: 'center',
            alignContent:'center',
            backgroundColor: darkTheme?'#121212':'#ffffff',
            paddingVertical: 10,
        },
        extendText:{
            color: '#1A8CF1',
            fontSize: isNarrowScreen?16: 18,
            fontWeight: 'bold',
            fontFamily: 'roboto-italic'
        },
    })

    return styles
}

export const ErrorStyle=()=>{
    const {width:windowWidth} = useWindowDimensions()
    const {darkTheme}= useTheme()
    const isNarrowScreen = windowWidth < 800
    const styles = StyleSheet.create({
        errText:{
            color: darkTheme?'#ffffff':'#3B3B3B',
            fontSize: 16,
            fontFamily: 'merriweather-regular',
            // letterSpacing: .8,
        },
    })

    return styles
}

export const BlogReadStyle = ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        trendingWrapper:{
            flexDirection:'column',
            padding: 10,
            alignItems:'center',
            alignContent: 'space-around',
        },
        trendingText:{
            fontSize:isNarrowScreen? 14:16,
            color:'#1A8CF1',
            fontWeight:'bold',
            margin: 15,
        },
        extendedBtn:{
            alignItems: 'center',
            alignContent:'center',
            alignSelf:'center',
            backgroundColor: darkTheme?'#12222F':'#ffffff',
            paddingVertical: 10,
            flexDirection:'row',
            marginVertical: 10,
            borderColor: darkTheme?'#ffffff':'#242424',
        },
        extendText:{
            color: darkTheme?'#ffffff':'#242424',
            fontSize: isNarrowScreen?16: 18,
            fontWeight: 'bold',
            fontFamily: 'roboto-italic',
            marginRight: 5
        },
        sidePositioning:{
            // @ts-ignore
            position: 'sticky',
            top: 10,
            zIndex: 100
        }
    })

    return styles
}

export const TopicStyle = ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        extendedBtn:{
            alignItems: 'center',
            justifyContent:'center',
            backgroundColor: darkTheme?'#22415a':'#F3F0F0',
            paddingVertical: 5,
            paddingHorizontal: 5,
            flexDirection:'row',
            marginVertical: 5,
            marginHorizontal:5,
            borderRadius: 20,
        },
        extendText:{
            color: darkTheme?'#ffffff':'#242424',
            fontSize: isNarrowScreen?12: 14,
            fontWeight: '200',
            fontFamily: 'roboto-regular',
        },
    })
    return styles
}

export const  BottomSideStyle = ()=>{
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        container:{
            backgroundColor:darkTheme?'#2B2A2A':'#FFFFFF',
            padding: 10,
            paddingBottom: 20,
            width: windowWidth,
            alignItems: 'center',alignSelf: 'stretch',
            justifyContent: 'center',
            borderTopColor:'#CDCCCC',
            borderTopWidth: .1,
            // position: 'absolute', bottom: 0, left: 0, right: 0
        },
        connectWrapper:{
            flexDirection: 'column',
            alignContent:'center',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
            width: windowWidth,
        },
        linkWrapper:{
            flexDirection: 'row',
            paddingVertical: 10,
            paddingHorizontal: isNarrowScreen?5:20,
        },
        bottomContent:{
            alignItems:'center',justifyContent:'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width:'100%'
        },
        bottomLinkWrapper:{
            marginHorizontal: isNarrowScreen?10:20,
            marginVertical: 5,
        },
        copyRight:{
            color: '#1A8CF1',
            fontFamily:'roboto-regular',fontWeight:'700',
            fontSize: 14, alignSelf:'center'
        },
        bottomLinkText:{
            fontSize: isNarrowScreen? 12:16,
            // fontWeight: 'bold',
            fontFamily:'roboto-regular',
            color: darkTheme?'#ffffff':'#3B3B3B',
        },
    })
    return styles
}

export const NewMultipleStyle= ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        topContainer:{
            paddingTop:10,flexDirection: 'column',
            backgroundColor: darkTheme?'#121212':'#ffffff',
        },
        contentWrapper:{
            alignContent: 'center',alignItems: 'center',
            padding: 10,
        },
        imgContentWrapper:{
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
        },
        img:{
            aspectRatio: 1,
            width: isNarrowScreen?windowWidth*.8: windowWidth*.6,
            height: undefined,
            maxHeight: 400,
            borderRadius: 5,
        },
        btn:{
            padding: 10,
            borderRadius: 4,
            width: windowWidth *.2,
            alignItems: 'center',
            borderColor: '#141414',
            borderWidth: 1
        },
        btnText:{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#141414',
            fontFamily: 'roboto-regular'
        },
        addContent: {
            flexDirection: 'row',
            paddingVertical: 10,
            alignItems: 'center',
            marginHorizontal: 10,
            justifyContent: 'center',
        },
        btnAdd:{
            padding: 10,
            margin: 10,
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
        },
        btnAddSection:{
            paddingVertical: 10,
            paddingHorizontal:10,
            marginHorizontal: 5,
            backgroundColor: darkTheme?'#22415a':'#F3F3F3',
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
        },
        btnTextAdd:{
            fontSize: 16,
            fontWeight: 'bold',
            color: darkTheme?'#f3f3f3':'#141414',
            fontFamily: 'roboto-italic'
        },
        btnTextSection:{
            fontSize: 12,
            fontWeight: 'bold',
            color: darkTheme?'#f3f3f3':'#141414',
            fontFamily: 'roboto-italic'
        },
        uploadButton: {
            backgroundColor: '#63bde1',
            color: 'white',
            padding: 10,
            borderRadius: 5,
            // @ts-ignore
            cursor: 'pointer',
        },
        input: {
            display: 'none',
        },
    })
    return styles
}

export const ProfileStyle= ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles = StyleSheet.create({
        drawer:{
            alignContent: 'center',
            alignItems: 'center',
            paddingTop: 20,
        },
        btnActive:{
            backgroundColor: '#FFFFFF',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            height: 30,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            width:isNarrowScreen?'50%':'100%',
            marginBottom: 10,
        },
        btnActiveBar:{
            backgroundColor: '#63bde1',
            width: 5,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            height: 30,
        },
        btn:{
            alignItems: 'center',
            alignContent: 'center',
            height: 30,
            flexDirection: 'row',
            width:'100%',
            marginBottom: 10,
        },
        btnText:{
            fontFamily:'roboto-bold',
            fontSize: 14,
            fontWeight: '100',
            color: '#2E2E2E',
            alignSelf:'center'
        },
        textAuth:{
            fontSize:16,
            fontWeight:'bold',
            marginVertical:5,
            fontFamily:'roboto-regular'
        }
    })
    return styles
}

export const authorBlogsStyle = ()=>{
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const styles= StyleSheet.create({
        sectionLayout:{
            marginBottom: 10,
            alignItems: 'center',
            padding: 10, backgroundColor:darkTheme?'#12222F':'#FFFFFF',
            borderRadius: 10, alignContent:'center',justifyContent:'center',
            borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
            width: windowWidth<600?'70%':isNarrowScreen?'40%':'25%',
            shadowColor: darkTheme?'#121212':'#EDEAEA',
            shadowOpacity: 2, shadowRadius: 10
        },
        subtitle:{
            fontSize: 16,
            fontFamily:'roboto-regular',
            fontWeight: '500',
            marginBottom: 10,
            color: darkTheme?'#f3f3f3':'#121212'
        },
        rowLayout:{
            flexDirection: 'column',marginVertical: 10,
            paddingHorizontal: 10,
            alignItems: 'center'
        },
        metricsText:{
            fontSize: 24,
            fontWeight: 'bold',
            fontFamily:'roboto-bold',
            color: darkTheme?'#f3f3f3':'#121212'
        },
        trendingText:{
            fontSize:16,
            color:darkTheme?'#f3f3f3':'#121212',
            fontWeight:'700',
            fontFamily: 'roboto-bold',
            margin: 15,
        },
        networkText:{
            fontSize: 14,
            fontFamily:'roboto-italic',
            fontWeight: '100',
            color: darkTheme?'#F3F3F3':'#6B6B6B'
        },
        hanger:{
            // @ts-ignore
            position: 'sticky',
            top: 0,
            zIndex: 100
        }
    })
    return styles
}
