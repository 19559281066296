import * as React from 'react'
import { View, Text, ScrollView, Animated, PanResponder, TouchableOpacity } from 'react-native'
import { useTheme } from '../utils/state-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { windowWidth } from '../utils/dimensions'

export function CustomPullToRefresh({ children, onRefresh }) {
    const { darkTheme } = useTheme()
    const[isPulled,setIsPulled] = React.useState(false)
    const [isRefreshing, setIsRefreshing] = React.useState(false)
    const pullDownHeight = React.useRef(new Animated.Value(0)).current

    const panResponder = React.useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: (evt, gestureState) => {
                return gestureState.dy > 0 && !isRefreshing;
            },
            onPanResponderMove: (evt, gestureState) => {
                pullDownHeight.setValue(gestureState.dy);
            },
            onPanResponderRelease: (evt, gestureState) => {
                if (gestureState.dy > 50) {
                    // setIsRefreshing(true);
                    setIsPulled(true)
                    Animated.timing(pullDownHeight, {
                        toValue: 50, // Adjust the height of the indicator during refresh
                        duration: 300,
                        useNativeDriver: false,
                    })
                    .start()
                } else {
                    Animated.timing(pullDownHeight, {
                        toValue: 0,
                        duration: 300,
                        useNativeDriver: false,
                    }).start()
                }
            },
        })
    ).current

    const handleRefresh = () => {
        setIsPulled(false)
        setIsRefreshing(true);
        Animated.timing(pullDownHeight, {
            toValue: 50,
            duration: 300,
            useNativeDriver: false,
        })
        .start(() => {
            onRefresh(() => {
                setIsRefreshing(false)
                Animated.timing(pullDownHeight, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: false,
                }).start()
            })
        })
    }

    const handleCancel=()=>{
        Animated.timing(pullDownHeight, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start()
        setIsPulled(false)
        setIsRefreshing(false)
    }

    return (
        <View style={{
            backgroundColor: darkTheme ? '#121212' : '#f3f3f3',
            flexShrink: 1,
            justifyContent: 'center',
            width:'100%',
            flexGrow: 1,
            flex: 1
        }}>
            <ScrollView
                {...panResponder.panHandlers}
                onScroll={() => { }}
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
                style={{ flex: 1 }}
            >
                <Animated.View
                    style={{
                        height: pullDownHeight,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: darkTheme ? '#121212' : '#f3f3f3',
                    }}
                >
                    {isRefreshing ?
                        <Text style={{ color: darkTheme ? '#ffffff' : '#121212' }}>Refreshing...</Text> :
                        isPulled ?<View style={{
                            flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                            justifyContent:'center'
                        }}>
                                <TouchableOpacity onPress={handleRefresh}>
                                    <View style={{ backgroundColor: '#1A8CF1', paddingHorizontal: 10,
                                        paddingVertical:5, borderRadius: 20 }}>
                                        <Text style={{ color: 'white' }}>Refresh</Text>
                                    </View>
                                </TouchableOpacity>
                                <View style={{ marginLeft:10 }}>
                                    <TouchableOpacity onPress={handleCancel}>
                                        <View style={{ backgroundColor: '#B93A3A', paddingHorizontal: 5,
                                        paddingVertical:5, borderRadius: 20 }}>
                                            <MaterialCommunityIcons name='close' color={'white'}/>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View> :
                            null
                    }
                </Animated.View>
                {/* Your content goes here */}
                {children}
            </ScrollView>
        </View>
    )
}
