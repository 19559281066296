import * as React from 'react'
import { View, Text } from 'react-native'


export function LinearGradients({children,colors,styles,iconSize}):JSX.Element{
    const [iconDimensions, setIconDimensions] = React.useState({ width: 0, height: 0 })

    React.useEffect(() => {
        setIconDimensions({ width: iconSize+1, height: iconSize+1 })
    }, [])
    return(
        <View style={{ alignItems:'center',justifyContent:'center',padding:0 }}>
            <Text
            // @ts-ignore
            style={[{ color: 'transparent', backgroundImage: `linear-gradient(${colors})`,
                alignItems:'center',
                alignContent:'center',
                justifyContent:'center',
                padding:0,
                width: iconDimensions.width,
                height: iconDimensions.height,
            },styles]}
            >
                {children}
            </Text>
        </View>
    )
}