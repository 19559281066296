// this component is a card that describes the layout of the blogs card
// its style is defined inside this file with imported dimensions

import * as React from 'react'
import {View, Text,Pressable, useWindowDimensions, StyleSheet,
    TouchableOpacity} from 'react-native'
import { ListCardStyle } from '../../utils/app-styles'
import { baseUrlImage } from '../../utils/urls'
import {Feather} from '@expo/vector-icons'
import { timeAgo } from '../../utils/time-ago'
import { useTheme } from '../../utils/state-context'
import {Image} from 'expo-image'
import { blurHash } from '../../utils/extra-utils'
import DateTimeFormatter from '../../utils/date-time-util-formatter'


export function AdminListCard({onPress,data}){

    const styles = ListCardStyle()
    const {darkTheme} = useTheme()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    //date management
    const created= new Date(data.created_at)
    const {day,month,year, hours,minutes} = DateTimeFormatter(created)

    const [isHovered, setIsHovered] = React.useState(false)

    const pressHandler = () => {
      // Handle press
        onPress && onPress()
    }
    return(
        <View style={{ width: '100%' }}>
            <Pressable
            onPress={pressHandler}
            //@ts-ignore
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={({ pressed }) => [
                {
                    backgroundColor: pressed ? darkTheme?'#22415a':'#EDF2F6' : (isHovered && (darkTheme?'#22415a':'#EDF2F6')),
                    borderRadius: 10,padding: 7
                },cardStyles.container
            ]} key={data.id}>
                <View style={{ width: data.contentImages.length>0?'60%':'100%'}}>
                    <View style={{
                        marginTop: 10,marginBottom: 5,width:'100%',flexDirection:'row',
                        alignContent:'center',alignItems:'center'
                    }}>
                        <View style={{
                            width: 26.5,height: 26.5, backgroundColor: '#D6D1D1', marginRight:10,
                            borderRadius: 13,alignItems:'center',justifyContent:'center',
                        }}>
                            <Image source={{uri:`${baseUrlImage}${data.author?.profile}`}}
                            style={{ width: 25,height: 25,borderRadius: 12.5,alignSelf:'center'
                            }}/>
                        </View>
                        <Text style = {styles.authorText}>
                            {`By ${data?.author?.name}`}
                        </Text>
                    </View>
                    <View style={{  marginBottom: 2,width:'100%'}}>
                        <Text style={styles.txt}>{data.title}</Text>
                    </View>
                    {data?.content?.length>0 &&<View style={{  marginBottom: 10,width:'100%'}}>
                    <Text numberOfLines={data.contentImages.length>0?3:2} ellipsizeMode="tail" style = {styles.contentText}>
                        {data?.content[0]?.story}
                    </Text></View>
                    }
                    <View style={{ flexDirection: 'row',flexWrap:'wrap' ,width: '100%'}} >
                        <View style={{ ...styles.viewsSection,backgroundColor:'#76B947' }}>
                            <Feather name="eye" size={16} color={'#ffffff'}/>
                            <Text style={{ ...styles.dateText,marginLeft: 2,color:'#ffffff' }}>{` ${data.views}`}</Text>
                        </View>
                        <View style={{ ...styles.viewsSection,backgroundColor:'#FFA500'}}>
                            <Text style={{
                                ...styles.dateText,fontFamily:'roboto-regular',color:'#ffffff',
                                fontSize: isNarrowScreen? 13:14
                            }}>{` #${data.topic}`}</Text>
                        </View>
                        <View style={styles.viewsSection}>
                            <Text style={{ ...styles.dateText,fontWeight: 'bold',color:'#ffffff',}}>
                                {`${month} ${day}, ${year} at ${hours}:${minutes} `}
                            </Text>
                        </View>
                    </View>
                </View>
                {data.contentImages.length>0&&
                <Image source={{ uri:`${baseUrlImage}${data.contentImages[0].media}` }}
                style={{ width: '40%',height: 150,borderRadius: 5 }} contentFit='cover' placeholder={blurHash}/>}
            </Pressable>
            <View style={{ flexDirection:'row',flexWrap:'wrap',
                width:'100%',
                marginBottom: 10,
                justifyContent: 'flex-end',
                alignItems:'center',
                // marginTop: 10
            }}>
                <TouchableOpacity style={cardStyles.button} onPress={()=>{}}>
                    <Text style={cardStyles.buttonText}>{data.commission?'Decommission blog': 'Commission Blog'}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const cardStyles= StyleSheet.create({
    container:{
        flex:1,
        flexDirection: 'row',
        alignContent: 'flex-start',
        alignItems: 'center',
        justifyContent:'flex-start',
        width: '100%',
        height:150
    },
    sections:{
        marginVertical:5,
    },
    button:{
        borderBottomStartRadius: 10,
        // borderRadius:20,
        backgroundColor:'#1A8CF1',
        paddingHorizontal:10,
        paddingVertical: 5,
        // marginHorizontal: 10,
        alignItems:'center',
        alignContent:'center',
        justifyContent:'center'
    },
    buttonText:{
        color:'white'
    },
    boldText:{
        fontWeight:'bold',
        fontFamily:'roboto-bold',
    }
})