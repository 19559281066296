import * as React from 'react'
import { View} from 'react-native'
import { useTheme } from '../utils/state-context'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { BookmarkHandler } from './bookmarks-handler-screen'
import { useAuthentication } from '../utils/auth-context'

export function Bookmarks({navigation}){
    const {darkTheme} = useTheme()
    const{auth}= useAuthentication()
    const [notification,setNotification] = React.useState({
        message:'',level:''
    })
    return(
        <View style={{
            flex:1,flexGrow:1,flexShrink:1,
            width:'100%', paddingHorizontal: 10,
            backgroundColor:darkTheme?'#121212':'#f3f3f3',
        }}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            {auth && <BookmarkHandler navigation={navigation} setNotification={setNotification}/>}
        </View>
    )
}