import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import {View,Text} from 'react-native'
import { TouchableOpacity } from 'react-native'
import * as Linking from 'expo-linking'
import { BottomSideStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'


const BottomSide= ({navigation}) => {
    const {darkTheme} = useTheme()
    const instagramLink = ()=>{
        Linking.openURL('https://bumho-nisubire.web.app')
    }
    const bottomSideStyle = BottomSideStyle()
    return(
        <View style={bottomSideStyle.container}>
            <View style={bottomSideStyle.connectWrapper}>
                <View style={bottomSideStyle.bottomContent}>
                    <MaterialCommunityIcons name="copyright" size={24} color={darkTheme?'#ffffff':'#121212'} />
                    <Text style={bottomSideStyle.copyRight}> Your Story </Text>
                    <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper}
                    onPress={instagramLink }
                    >
                        <Text style={bottomSideStyle.bottomLinkText}>Help</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper}>
                        <Text style={bottomSideStyle.bottomLinkText}>About YourStory</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper}>
                        <Text style={bottomSideStyle.bottomLinkText}>Terms</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={bottomSideStyle.bottomLinkWrapper}>
                        <Text style={bottomSideStyle.bottomLinkText}>Privacy</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

export default BottomSide

