import * as React from 'react'
import {View, useWindowDimensions, StyleSheet,ScrollView,
    TextInput,FlatList} from 'react-native'
import { useTheme } from '../utils/state-context'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { NavigationButton } from '../components/nav-button'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useAdminAuthentication } from './admin-utils/admin-auth-context'
import { AdminAllInfo } from './admin-components/admin-global-info'
import { baseUrl } from '../utils/urls'
import { Contacts } from '../components/contacts'
import { AdminProfile } from './admin-components/admin-profile'
import { BlogStory } from '../utils/story-data'
import { allBlogsSetter } from '../utils/story-interface-setter'
import axios from 'axios'
import { AdminListCard } from './admin-components/admin-blog-card'


export function AdminBlogsList({navigation}){
    const{auth} = useAdminAuthentication()
    const {darkTheme} = useTheme()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })
    const [searchQuery, setSearchQuery] = React.useState('')
    const[filteredBlogs,setFilteredBlogs] = React.useState([])
    const [data,setData] = React.useState<BlogStory[]>([])
    React.useEffect(()=>{
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${baseUrl}admin-blogs/`,{
                    headers:{
                        Authorization: `Token ${auth.token}`,
                    }
                },)
                console.log(response.data)
                setData(allBlogsSetter(response.data))
            } catch (error) {
                setNotification({message:'Error while handling request',level:'warning'})
            }
        }
        fetchBlogs()
    },[auth])

    const handleSearch = (searchText: string) => {
        setSearchQuery(searchText)
        if (searchText.trim() === '') {
            // If the search query is empty, hide the search results
            setFilteredBlogs(data)
        } else {
            // Filter blogs based on title or topic
            const filteredBlogs = data.filter(
                (blog) =>
                blog.title.toLowerCase().includes(searchText.toLowerCase())
            )
            setFilteredBlogs(filteredBlogs)
        }
    }

    return(
        <View style={{
            backgroundColor:darkTheme?'#121212':'#f3f3f3',
            flexShrink:1,
            justifyContent:'center',
            flexGrow: 1,
            flexDirection: 'row',
            width:'100%',
            height: '100%',
            flex: 1
        }}>
            {!isNarrowScreen && <View
                // @ts-ignore
                style={{
                    width: isNarrowScreen ? '100%' : '15%',alignSelf: 'flex-start',
                    marginHorizontal:!isNarrowScreen && 10,
                    position: !isNarrowScreen && 'sticky',
                    top: !isNarrowScreen &&10,
                    zIndex: !isNarrowScreen &&100
            }}>
                {auth && <AdminAllInfo/>}
            </View>}
            <View style={{
                width: isNarrowScreen ? '100%' : '50%',
            }}>
                {notification.level !=='' && <NotificationPanel notification={notification}/>}
                <ScrollView
                showsVerticalScrollIndicator={false}
                >
                    {
                        <View style={{
                            width: '100%', flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                            justifyContent:isNarrowScreen?'center':
                            'flex-start',alignSelf:isNarrowScreen?'center':'flex-start'
                        }} >
                            <NavigationButton navItem={'Home'} onPress={()=> navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'Admin' }],
                                })}
                                index={0}
                            />
                            <NavigationButton navItem={'Accounts'} onPress={()=> navigation.navigate('AdminAccountsList')}
                                index={1}
                            />
                            <NavigationButton navItem={'Requests'} onPress={()=> navigation.navigate('AdminRequestScreen')}
                                index={2}
                            />
                        </View>
                    }
                    <View style={{
                        marginTop: 10,
                        borderRadius: 10,
                        borderWidth: 1.5,
                        width: '90%',
                        justifyContent:'space-evenly',
                        backgroundColor:darkTheme?'#12222F':'#ffffff',
                        borderColor:darkTheme?'#454545':'#EDEAEA',
                        minWidth: windowWidth *.3,
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginHorizontal:5,
                        marginBottom:10,
                        paddingVertical: 10,
                        paddingHorizontal: 10,
                    }}>
                        <TextInput
                            inlineImageLeft='search_icon'
                            style={styles.inputField}
                            underlineColorAndroid={'transparent'}
                            placeholder='Search Title'
                            placeholderTextColor={darkTheme?'#f3f3f3':'#666'}
                            value={searchQuery}
                            onChangeText = {(search)=> handleSearch(search)}
                        />
                        <MaterialCommunityIcons name='magnify' size={24} color={darkTheme?'#ffffff':'#141414'}/>
                    </View>
                    <View style={{
                        width:'100%',
                        borderWidth:1.5,
                        borderColor:darkTheme?'#454545':'#EDEAEA',
                        backgroundColor:darkTheme?'#12222F':'#ffffff',
                        paddingHorizontal: 5,marginTop: 10,paddingVertical:5,
                        borderRadius: 10
                    }}>
                        <FlatList
                        data={searchQuery===''?data:filteredBlogs}
                        numColumns={1}
                        keyExtractor={(homeData, index) => index.toString()}
                        renderItem={({item})=>(
                            <View
                            //@ts-ignore
                            style={{
                                alignContent:'center',
                                justifyContent:'center',alignItems:'center',
                                alignSelf:'center',
                                width: '100%',
                            }}
                            >
                                <AdminListCard data={item} key={item.id}
                                    onPress={() =>
                                        navigation.navigate('BlogRead', {
                                        id: item.id,
                                        })
                                    }
                                />
                            </View>)}
                        />
                    </View>
                </ScrollView>
            </View>
            {!isNarrowScreen && <View style={{
                marginBottom: 10,marginTop: 10,
                marginLeft:20,borderRadius:10,
                width:'30%', flexDirection:'column',
                backgroundColor:darkTheme?'#12222F':'#ffffff',
                paddingHorizontal: 10, alignSelf:'flex-start'
            }}>
                <AdminProfile/>
                <Contacts navigation={navigation}/>
            </View>}
        </View>

    )
}


const styles = StyleSheet.create({
    inputField:{
        backgroundColor: '#f3f3f3',
            fontSize: 14,
            minWidth: '70%',
            height: 40,
            marginHorizontal:5,
            borderRadius: 10,
            paddingVertical: 5,
            color: '666',
            paddingHorizontal: 10,
            // @ts-ignore
            outlineColor: 'transparent',
    },
    profile:{
        height: 70,
        width: 70,
        borderRadius: 35,
        borderWidth: 2,
    }
})
