import * as React from "react"
import { encryptData, decryptData } from './encryptionUtils'
import { saveToFile,readFromFile, clearData } from "./file-manager"
import { AuthContextProps } from "./authentication-interface"
import { baseUrl } from "./urls"
import axios from "axios"




export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined)


export const useAuthentication = () => {
    return React.useContext(AuthContext)
}

export const AuthProvider = ({children}:React.PropsWithChildren):JSX.Element=>{
    const [authenticatedUser, setAuthenticatedUser] = React.useState(null)
    const [loggedOut, setLoggedOut] = React.useState(false)

    const login = (response:any) => {
        const encryptedData = encryptData({
            token: response.data.token,
            username: response.data.username,
            email: response.data.email,
            description: response.data.description,
            profile: response.data.profile,
            links: response.data.links,
            bookmarks:response.data.bookmarks,
            is_Writer:response.data.is_Writer,
            likes: response.data.likes
        })
        // Save the encrypted data to a file
        saveToFile(encryptedData)

        // Set the decrypted user data in the state
        setAuthenticatedUser(decryptData(encryptedData))
    }

    const logout = async () => {
        // Remove the encrypted data from state
        clearData()
        setLoggedOut(true)
        setAuthenticatedUser(null)
    }

    const loadUserDataFromFile = async () => {
        const fileContent = readFromFile()
        if (fileContent){
            const decryptedData = decryptData(fileContent)
            setAuthenticatedUser(decryptedData)
            return decryptedData
        }
    }

    const fetchUser = async () => {
        // Check if the user is still authenticated before proceeding
        if (authenticatedUser && !loggedOut) {
            try {
                const response = await axios.post(`${baseUrl}user/`, {}, {
                headers: {
                    Authorization: `Token ${authenticatedUser.token}`,
                },
                });
                // Only login if the user is still authenticated
                login(response);
            } catch (error) {
            }
        }
    };

    React.useEffect(() => {
    loadUserDataFromFile()
    }, [])

    return <AuthContext.Provider value={{
        auth: authenticatedUser, login, logout,loadUserDataFromFile,fetchUser,
        setLoggedOut,
        loggedOut
    }}>
        {children}
    </AuthContext.Provider>
}
