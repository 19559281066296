import * as React from 'react'
import { baseUrl } from './urls'
import axios from 'axios'
import { BlogStory, Trend } from './story-data'
import { allBlogsSetter } from './story-interface-setter'


export const BlogsContext = React.createContext<{
    latestBlogs: BlogStory[];
    popular:BlogStory[];
    refreshLatestBlogs: ()=> void;
    trending: Trend[];
}>({
    latestBlogs:[], refreshLatestBlogs:()=>{},
    trending:[],
    popular:[],
})

export const BlogProvider=({children}:React.PropsWithChildren):JSX.Element=>{
    const [latestBlogs,setLatestBlogs] = React.useState<BlogStory[]>([])
    const [trending,setTrending]= React.useState<Trend[]>([])
    const [popular,setPopular] = React.useState<BlogStory[]>([])

    const refreshLatestBlogs= React.useCallback(()=>{
        axios.get(`${baseUrl}trending/`)
        .then((response)=>{
            setTrending(response.data.trending_topics)
        })
        axios.get(`${baseUrl}latest/`)
        .then((response)=>{
            setLatestBlogs(allBlogsSetter(response.data))
        })
        axios.get(`${baseUrl}most-viewed/`)
        .then((response)=>{
            setPopular(allBlogsSetter(response.data))
        })
    },[])

    React.useEffect(()=>{
        refreshLatestBlogs()
    },[refreshLatestBlogs])

    return <BlogsContext.Provider
    value={{ latestBlogs,refreshLatestBlogs,trending,popular }}
    >{children}</BlogsContext.Provider>
}
