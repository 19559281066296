import { TouchableOpacity, View,Image,Text } from "react-native"
import { FormInput } from "../custom-components/formInput"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { baseUrlImage } from "../../utils/urls"
import DragHandle from "./drag-handle"
import { formatBlogText, formatText } from "../../utils/text-formatter"


export function  SubtitleEntry({index,item,isNarrowScreen,content,deleteContent,
    handleSectionChange,handleReorder,newMultipleStyle}){
    return <View key={index} style={{ width: '100%',paddingTop: 20 }}>
        <DragHandle onMove={handleReorder} />
        <FormInput
        fontSize={isNarrowScreen?22:30}
        fontWeight={'bold'}
        key={index}
        value = {item.story}
        inputHeight={content[index].inputHeight}
        lineNum={1}
        placeholder={`Subtitle section`}
        onChangeText={(text: any) => handleSectionChange(index, text)}
        onContentSizeChange={(e: any)=>{
            content[index].inputHeight= e.nativeEvent.contentSize.height
        }}
        label={undefined}/>
        <ContentCommands index={index} newMultipleStyle={newMultipleStyle}
        deleteContent={deleteContent} handleReorder={handleReorder}
        content={content}
        />
    </View>
}


export function ParagraphEntry({index,item,isNarrowScreen,content,deleteContent,
    handleSectionChange,handleReorder,newMultipleStyle}){

    return <View key={index} style={{ width: '100%',paddingTop: 20 }}>
        <DragHandle onMove={handleReorder} />
        <FormInput
        fontSize={isNarrowScreen?16:18}
        fontWeight={'50'}
        key={index}
        value = {formatText(item.story)}
        lineNum={1}
        inputHeight={content[index].inputHeight}
        placeholder={`Paragraph Section`}
        onChangeText={(text: any) => {
            handleSectionChange(index, text)
        }}
        onContentSizeChange={(e: any)=>{
            content[index].inputHeight= e.nativeEvent.contentSize.height
        }}
        label={undefined}
        />
        <ContentCommands index={index} newMultipleStyle={newMultipleStyle}
        deleteContent={deleteContent} handleReorder={handleReorder} content={content}
        />
    </View>
}



export function ImageEntry({index,item,isNarrowScreen,content,deleteContent,
    changeImage,handleReorder,newMultipleStyle, setImgCopyright}){
    return <View key={index} style={[newMultipleStyle.imgContentWrapper,{paddingTop: 20}]}>
        <DragHandle onMove={handleReorder} />
        {item.assets && item.assets.length>0 ?<Image source={{ uri:item.assets[0].uri }}
        style={newMultipleStyle.img}
        />: <Image
            source={{ uri:`${baseUrlImage}${item.media}` }}
            style={newMultipleStyle.img}
            />}
        <View style={{
            marginTop: 10,
            alignSelf:'flex-end',
            flexDirection: 'row',flexWrap:'wrap'
        }}>
            <TouchableOpacity
            style={[newMultipleStyle.btn,{
                width: 100,paddingHorizontal: 10,
                paddingVertical: 5,
                marginHorizontal: 10,
                backgroundColor: '#F3F3F3',
                borderColor: 'transparent',
                alignItems:'center',alignContent:'center',
                justifyContent:'center'
            }]}
            onPress={()=>changeImage(index)}
            >
                <Text>Change Photo</Text>
            </TouchableOpacity>
            <TouchableOpacity
            style={[newMultipleStyle.btn,{
                width: 50,paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: '#F3F3F3',
                borderColor: 'transparent'
            }]}
            onPress={()=>deleteContent(index)}
            >
                <MaterialCommunityIcons name='delete-outline' size={24}/>
            </TouchableOpacity>
        </View>
        {/* setting up imageCopyright */}
        <View>
            <FormInput
            fontSize={isNarrowScreen?14:16}
            fontWeight={'50'}
            lineNum={1}
            inputHeight={content[index].inputHeight}
            label={undefined}
            value={item.imageCopyright}
            placeholder={'source of the image'}
            onChangeText= {
                (element: string | number)=> setImgCopyright(element,index)
            }
            />
        </View>
    </View>
}


export function ContentCommands({newMultipleStyle,deleteContent,index,handleReorder,content}){
    return <View style={{ marginTop: 10,
    alignSelf:'flex-start',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-evenly'
    }}>
        <TouchableOpacity
        style={[newMultipleStyle.btn,{
            width: 50,
            backgroundColor: '#F3F3F3',
            borderColor: 'transparent'
        }]}
        onPress={()=>deleteContent(index)}
        >
            <MaterialCommunityIcons name='delete-outline' size={24}/>
        </TouchableOpacity>
    </View>
}
