import * as React from 'react'
import { View, Text, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { NavigationBarStyle } from '../utils/app-styles'
import { PopMenu } from './custom-components/popup-menu'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '../utils/state-context'
import { useAuthentication } from '../utils/auth-context'


const NavigationBar = ({ navigation }) => {
    const navBarStyle = NavigationBarStyle()
    const {darkTheme} = useTheme()
    const route = useRoute()
    const [token,setToken] = React.useState(null)
    const {auth} = useAuthentication()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    React.useEffect(() => {
        if(auth !==null){
            setToken(auth.token)
        }
    }, [route.name,auth])

    const isActive = (routeName: string) => route.name === routeName

    const navList = ['Create']

    return (
        <View
        style={navBarStyle.btnWrapper}
        >
            {
                !isNarrowScreen && auth &&auth.is_Writer && navList.map((navItem,index)=>
                <View
                key={index}
                style={[navBarStyle.btn,isActive(navItem==='Create'?'NewPost':navItem) && navBarStyle.btnActive]}
                >
                    <TouchableOpacity
                        onPress={() => navigation.navigate(navItem==='Create'?'NewPost':navItem)}
                        >
                        <Text
                            style={navBarStyle.btnText}
                        >
                            {navItem}
                        </Text>
                    </TouchableOpacity>
            </View>)
            }
            {
                auth && auth.is_Writer && <View
                style={{ marginHorizontal: 20 }}
                >
                    <TouchableOpacity
                        onPress={()=> navigation.navigate('Profile')}
                    >
                        <MaterialCommunityIcons name='view-dashboard-outline'
                            size={isNarrowScreen?28:30} color={isActive('Profile')?'#ffffff':'#ADACAC'}
                        />
                    </TouchableOpacity>
                </View>
            }
            {
                auth && <View
                style={{ marginHorizontal: 20 }}
                >
                    <TouchableOpacity
                        onPress={()=> navigation.navigate('Bookmarks')}
                    >
                        <MaterialCommunityIcons name='bookmark-multiple-outline'
                            size={isNarrowScreen?28:30} color={isActive('Bookmarks')?'#ffffff':'#ADACAC'}
                        />
                    </TouchableOpacity>
                </View>
            }
            <View style={{ marginHorizontal: 10 }}>{
                auth ? <PopMenu navigation={navigation} auth={auth}/>
                :<View style={{ paddingHorizontal: 30 }}>
                    <TouchableOpacity
                        style={navBarStyle.btnLogin}
                        onPress={()=>navigation.navigate('SignIn')}
                    >
                        <Text style={navBarStyle.textLogin} >Sign In</Text>
                    </TouchableOpacity>
                </View>
            }</View>
        </View>
    )
};

export default NavigationBar
