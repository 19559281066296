

function DateTimeFormatter(dateObject:Date):
{
    year:number,month:string,
    day:number,hours:number,minutes:number
}{
    //date management
    const monthList = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ]
    const year = dateObject.getFullYear()
    const monthInt = dateObject.getMonth()
    const day = dateObject.getDate()
    const hours = dateObject.getHours()
    const minutes = dateObject.getMinutes()
    const month = monthList[monthInt]

    return{year, month,day,hours,minutes}
}

export default DateTimeFormatter
