// SignInScreen.js
import React, { useEffect, useState } from 'react';
import { View, Button, Text, Animated, useWindowDimensions, TouchableOpacity } from 'react-native';
import { LoginStyle } from '../utils/app-styles';
import CredentialFormInput from '../components/custom-components/credentialsFormComponent';
import { useTheme } from '../utils/state-context'
import {BlurView} from 'expo-blur'
import { baseUrl } from '../utils/urls';
import axios from 'axios'
import FormData from 'form-data'
import CircularProgressIndicator from '../components/custom-components/progress-indicator'
import { useAuthentication } from '../utils/auth-context';


const SignIn = ({navigation}) => {
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const loginStyles = LoginStyle()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [animation] = useState(new Animated.Value(0))
    const{login} = useAuthentication()

    //set alert notification
    const [notification,setNotification] = useState('')
    //showing loader
    const [isProgress,setIsProgress] = useState(false)
    //progress setter
    const [progress, setProgress] = useState(0)
    // authentication context setting
    const data = new FormData()

    //progress timer
    useEffect(() => {
        const interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress + 1) % 101)
        }, 30)
        return () => {
        clearInterval(interval)
        }
    }, [])

    //posts the request to the backend
    const SignInButton=async()=>{
        setIsProgress(true)
        data.append('email', email)
        data.append('password', password)
        await axios.post(`${baseUrl}login/`,data)
        .then((response)=>{
            login(response)
            // Reload the entire web app
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            })
        })
        .catch((err)=> {
            setIsProgress(false)
            Animated.timing(animation, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false,
            }).start()
            if(err.response.status===401){
                setNotification('Wrong email or password')
            }else if(err.response.status===500){
                setNotification('There is a problem with the server')
            }else{
                setNotification('A problem Occurred, wait for fixing!')
            }
        })
    }

    const moveAnimation = () => {
        Animated.timing(animation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
        }).start()
    };

    const emailNextHandler = () => {
        moveAnimation();
    };

    return (
        <View style={loginStyles.container}>
        <Animated.View
            style={{
            transform: [
                {
                translateY: animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [100, -600],
                }),
                },
            ],
            width: isNarrowScreen? '100%':'60%'
            }}
        >
            <View style={loginStyles.topContainer}>
                <BlurView tint={darkTheme?'dark':'light'} style={loginStyles.topBar}
                intensity={darkTheme?100:100}
                >
                    <Text style={{
                        fontSize: isNarrowScreen?18:24,
                        fontFamily:'roboto-bold',
                        fontWeight: '400',marginTop:isNarrowScreen?5:10,
                        marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                        color: '#1A8CF1',
                    }} >
                        Your Story
                    </Text>
                </BlurView>
                <Text style={{
                    fontSize: isNarrowScreen?16:18,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }} >
                    Sign In
                </Text>
                <Text style={{
                    fontSize: isNarrowScreen?14:16,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }}>Enter your Email Address: </Text>
                <CredentialFormInput
                    darkTheme={darkTheme}
                    secureTextEntry={false}
                    title={'Email:'}
                    label={email}
                    lineNum={1}
                    placeholder={'youremail@yourstory.com'}
                    keyboardType = 'email-address'
                    onChangeText = {(content: React.SetStateAction<string>) => setEmail(content)}
                />
                <Text style={loginStyles.textDesc}>
                    Don't have an Account
                </Text>
                <TouchableOpacity style={loginStyles.textContainer}
                    onPress={()=>navigation.navigate('Register')}
                >
                    <Text style={loginStyles.registerText}> Register</Text>
                </TouchableOpacity>
                {
                    notification!=='' && <Text style={{ color:'red' }}>
                        {notification}
                    </Text>
                }
                <View style={[loginStyles.extendedBtn]} >
                    <TouchableOpacity onPress={email.length>0 && emailNextHandler}>
                        <Text style={loginStyles.extendText} >Next</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Animated.View>

        <Animated.View
            style={{
            transform: [
                {
                translateY: animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [400, -200],
                }),
                },
            ],
            width: isNarrowScreen? '100%':'60%'
            }}
        >
            <View style={loginStyles.topContainer}>
                <BlurView tint={darkTheme?'dark':'light'} style={loginStyles.topBar}
                intensity={100}
                >
                    <Text style={{
                        fontSize: isNarrowScreen?18:24,
                        fontFamily:'roboto-bold',
                        fontWeight: '400',marginTop:isNarrowScreen?5:10,
                        marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                        color: '#1A8CF1',
                    }} >
                        Your Story
                    </Text>
                </BlurView>
                <Text style={{
                    fontSize: isNarrowScreen?16:18,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }}>
                    Sign In
                </Text>
                <Text style={{
                    fontSize: isNarrowScreen?14:16,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }}>Enter your Password: </Text>
                <CredentialFormInput
                    darkTheme={darkTheme}
                    title={'Password:'}
                    label={password}
                    lineNum={1}
                    secureTextEntry = {true}
                    placeholder={''}
                    onChangeText = {(content: React.SetStateAction<string>) => setPassword(content)}
                />
                {isProgress &&
                <CircularProgressIndicator
                    radius={25}
                    strokeWidth={1}
                    progress={progress}
                />}
                <View style={{ marginVertical:5 }}>
                    <Text
                    onPress={()=>{
                        Animated.timing(animation, {
                            toValue: 0,
                            duration: 500,
                            useNativeDriver: false,
                            }).start()
                    }}
                    style={{
                        fontSize: isNarrowScreen?12:14,
                        fontFamily:'roboto-bold',
                        fontWeight: '400',
                        color: '#1A8CF1',
                    }}
                    >Go back</Text>
                </View>
                <View style={[loginStyles.extendedBtn]} >
                    <TouchableOpacity
                    onPress={()=>password.length>0 && SignInButton()}
                    >
                        <Text style={loginStyles.extendText} >Sign In</Text>
                    </TouchableOpacity>
                </View>
                <View style={{
                    flexDirection:'row', flexWrap:'wrap',width:'90%',
                    alignItems: 'center', justifyContent:'center',marginVertical:10
                }}>
                    <Text style={loginStyles.bottomLinkText}>
                        Click “Sign in” to agree to YourStory's
                    </Text>
                    <TouchableOpacity onPress={()=> navigation.navigate('Terms')}>
                        <Text style={{
                        fontWeight: '400',
                        color: '#1A8CF1',
                        fontFamily: 'roboto-italic',
                        fontSize: 14,
                        }}> Terms of Service </Text>
                    </TouchableOpacity>
                    <Text style={loginStyles.bottomLinkText}>
                        and acknowledge that YourStory's
                    </Text>
                    <TouchableOpacity onPress={()=> navigation.navigate('Policy')}>
                        <Text style={{
                        fontWeight: '400',
                        color: '#1A8CF1',
                        fontFamily: 'roboto-italic',
                        fontSize: 14,
                        }}> Privacy Policy </Text>
                    </TouchableOpacity>
                    <Text style={loginStyles.bottomLinkText}>
                        applies to you.
                    </Text>
                </View>
            </View>
        </Animated.View>
        </View>
    );
};

export default SignIn;

