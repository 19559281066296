import * as React from 'react'
import { TouchableOpacity, View,Text, useWindowDimensions } from 'react-native'
import { baseUrl } from '../utils/urls'
import FormData from 'form-data'
import axios from 'axios'
import SubFormInput from './custom-components/sub-form-input'
import { useTheme } from '../utils/state-context'

export function SubscriptionSection({setNot}):JSX.Element{
    const{darkTheme} = useTheme()
    const [username,setUsername] = React.useState<string>('')
    const [email,setEmail] = React.useState<string>('')
    const data = new FormData()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    //posts the request to the backend
    const SubButton=async({username,email})=>{
        data.append('email', email)
        data.append('name', username)
        await axios.post(`${baseUrl}register/`,data)
        .then((response)=>{
            setNot({
                message:`${username} Subscribed Successfully!`,level:'success'
            })
        })
        .catch((err)=> setNot({
            message:`Experience a problem: ${err.message}!`,level:'warning'
        }))
    }
    return  <View style={{
            padding: 10,width: '100%',
            marginBottom: 10,
            paddingBottom: 10,
        }}>
        <Text style={{
            fontSize:18,
            color:darkTheme?'#f3f3f3':'##121212',
            fontWeight:'bold',
            fontFamily: 'roboto-regular',
            marginHorizontal: 10,alignSelf:'flex-start' }}
        >
            Sign Up for Updates
        </Text>
        <SubFormInput
            title={'Username:'}
            label={username}
            lineNum={1}
            placeholder={'Username'}
            onChangeText = {(content: React.SetStateAction<string>)=> setUsername(content)}
        />
        <SubFormInput
            title={'Email:'}
            label={email}
            lineNum={1}
            placeholder={'you@yourstory.com'}
            keyboardType = 'email-address'
            onChangeText = {(content: React.SetStateAction<string>) => setEmail(content)}
        />
        <View style={{
            alignItems: 'flex-end',
            marginTop: 10
        }}>
            <TouchableOpacity
                style={{
                    borderWidth: 1.5,
                    borderColor: darkTheme?'#ffffff':'#141414',
                    borderRadius: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingHorizontal: 7,
                    paddingVertical: 5,
                    width: '50%',
                    alignSelf:'center'
                }}
                onPress={()=>SubButton({username,email})}
            >
                <Text
                style={{ fontSize: 16, fontWeight: 'bold',color:darkTheme?'#ffffff':'#121212' }}
                >Submit</Text>
            </TouchableOpacity>
        </View>
    </View>
}
