import * as React from 'react'
import * as Font from 'expo-font'
import {baseUrl} from './urls'
import axios from 'axios'


export const ThemeContext = React.createContext<StateContextProps | undefined>(undefined)

export const ColorThemeProvider = ({children})=>{
    const [roboFont,setRoboFont] = React.useState({
        'roboto-bold': null,
        'roboto-italic': null,
        'roboto-regular': null,
        'roboto-medium':null,
        'montserrat':null,
        'merriweather-regular':null,
    })
    const [darkTheme,setDarkTheme] = React.useState(false)
    const [topics, setTopics] = React.useState([])
    const [searchTopics, setSearchTopics] = React.useState([])
    const[viewedBlog,setViewedBlog] = React.useState(JSON.parse(localStorage.getItem('view')))

    const toggleTheme = () => {
        setDarkTheme(prevTheme => !prevTheme)
    }
    const [isFontsLoaded, setIsFontsLoaded] = React.useState(false)

    const fetchFonts = async () => {
        await Font.loadAsync({
            'roboto-bold': require('../../assets/fonts/Roboto-Bold.ttf'),
            'roboto-italic': require('../../assets/fonts/Roboto-Italic.ttf'),
            'roboto-regular': require('../../assets/fonts/Roboto-Regular.ttf'),
            'roboto-medium': require('../../assets/fonts/Roboto-Medium.ttf'),
            'merriweather-regular': require('../../assets/fonts/Merriweather-Regular.ttf'),
            'montserrat': require('../../assets/fonts/Montserrat-VariableFont_wght.ttf')
        })
    }

    const loadFonts = async () => {
        try {
            await fetchFonts();
            setIsFontsLoaded(true)
            setRoboFont({
                'roboto-bold': require('../../assets/fonts/Roboto-Bold.ttf'),
                'roboto-italic': require('../../assets/fonts/Roboto-Italic.ttf'),
                'roboto-regular': require('../../assets/fonts/Roboto-Regular.ttf'),
                'roboto-medium': require('../../assets/fonts/Roboto-Medium.ttf'),
                'merriweather-regular': require('../../assets/fonts/Merriweather-Regular.ttf'),
                'montserrat': require('../../assets/fonts/Montserrat-VariableFont_wght.ttf'),
            })
        } catch (error) {
            console.error('Error loading fonts', error)
        }
    };

    // Function to shuffle the array randomly
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    React.useEffect(() => {
        loadFonts()
        // fetches topics
        axios.get(`${baseUrl}topics/`)
        .then((response)=>{
            setTopics(response.data)
            const toShuffle = response.data
            shuffleArray(toShuffle)
            setSearchTopics(toShuffle.slice(0, 15))
        })
      }, []); // Run only once when the component mounts

    if (!isFontsLoaded) {
        // Fonts are still loading, show AppLoading
        return null
    }

    return(
        <ThemeContext.Provider value={{
            darkTheme,setDarkTheme,
            toggleTheme,
            topics,setTopics,
            searchTopics,setSearchTopics,
            viewedBlog,setViewedBlog
            }}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () =>{
    return React.useContext(ThemeContext)
}


export interface StateContextProps{
    darkTheme: any;setDarkTheme: any;
    toggleTheme: any; topics: any,setTopics: any;
    searchTopics: any,setSearchTopics:any,
    viewedBlog:any, setViewedBlog:any,
}