import * as React from 'react'
import {View,Text,
    FlatList,
    useWindowDimensions} from 'react-native'
import axios from 'axios'
import { authorBlogsStyle} from '../utils/app-styles'
import ExecuteCard from '../components/cards/execute-card'
import { baseUrl } from '../utils/urls'
import { BlogStory, Statistics } from '../utils/story-data'
import { UpdateProfile } from '../components/update-profile'
import { useTheme } from '../utils/state-context'
import { useAuthentication } from '../utils/auth-context'


const MyBlogs=({navigation,setNot})=>{
    // initializing styles
    const authStyles = authorBlogsStyle()
    const {darkTheme} = useTheme()
    const{auth} = useAuthentication()
    //hooks for storing data
    const [data, setData] = React.useState<Statistics>({
        blogs: 0,
        total_views: 0,
        likes: 0,
        dislikes: 0})
    const [blogs, setBlogs] = React.useState<BlogStory[]>([])
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const [isLoading,setIsLoading] = React.useState(true)

    const fetchStatistics = async()=>{
        await axios.get(`${baseUrl}statistics/`,{
            headers:{
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
        .then((response)=>{
            if(response.data){
                setData({
                    blogs: response.data.blogs,
                    total_views: response.data.total_views,
                    likes: response.data.likes,
                    dislikes: response.data.dislikes
                })
            }
        })
        .catch((err)=> {
            setData({
                total_views:0,likes:0,dislikes:0,blogs:0
            })
        })
    }
    const fetchBlogs= async()=>{
        await axios.get(`${baseUrl}user_blogs/`,{
            headers:{
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
        .then((response)=>{
            setBlogs(response.data)
            setIsLoading(false)
        })
        .catch((err)=> {
            setBlogs([])
            setIsLoading(false)
        })
    }
    React.useEffect(()=>{
        fetchBlogs()
        fetchStatistics()
    }, [])

    return(
            <View style={{
                padding: 10, width:'100%'
            }}>
                <Text style={{
                    fontSize: !isNarrowScreen?20: 18,
                    fontWeight: 'bold',
                    fontFamily: 'roboto-bold',
                    marginBottom: 20,
                    color:darkTheme?'#f3f3f3':'121212'
                }}>
                    Account Performance
                </Text>
                <View style={{
                    flexDirection:'row', flexWrap:'wrap',
                    justifyContent: 'space-around',
                }}>
                    <View style={authStyles.sectionLayout}>
                        <View style={authStyles.rowLayout}>
                            <Text style={authStyles.trendingText}>
                                Traffic
                            </Text>
                            <Text style={authStyles.metricsText}>
                                {` ${data.total_views}`}
                            </Text>
                        </View>
                    </View>
                    <View
                    style={authStyles.sectionLayout}
                    >
                        <View style={authStyles.rowLayout}>
                            <Text style={authStyles.trendingText}>
                                Total Blogs
                            </Text>
                            <Text style={authStyles.metricsText}>
                                {` ${data.blogs}`}
                            </Text>
                        </View>
                    </View>
                    <View
                    style={authStyles.sectionLayout}
                    >
                        <Text style={authStyles.trendingText}>
                            Total Votes
                        </Text>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={authStyles.rowLayout}>
                                <Text style={authStyles.subtitle}>
                                    Likes
                                </Text>
                                <Text style={authStyles.metricsText}>
                                    {` ${data.likes}`}
                                </Text>
                            </View>
                            <View style={authStyles.rowLayout}>
                                <Text style={authStyles.subtitle}>
                                    Dislikes
                                </Text>
                                <Text style={authStyles.metricsText}>
                                    {` ${data.dislikes}`}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ flexDirection:'row',flexWrap:'wrap' }}>
                    <View
                    style={{
                        width: isNarrowScreen?'100%':'30%',
                    }}>
                        <View style={{marginTop: 10}}>
                            <Text style={{
                                fontSize: windowWidth>600?20: 18,
                                fontWeight: 'bold',
                                fontFamily: 'roboto-bold',
                                color:darkTheme?'#f3f3f3':'#121212',
                                marginBottom: 20}}>
                                Profile
                            </Text>
                        </View>
                        <UpdateProfile darkTheme={darkTheme} setNot={setNot} navigation={navigation}/>
                    </View>
                    <View style={{width:isNarrowScreen?'100%': '68%',marginTop: 10}}>
                        <View style={{marginTop: 10}}>
                            <Text style={{
                                fontSize: windowWidth>600?20: 18,
                                fontWeight: 'bold',
                                fontFamily: 'roboto-bold',
                                color:darkTheme?'#f3f3f3':'#121212',
                                marginBottom: 10}}>
                                Posts
                            </Text>
                        </View>
                        {blogs.length !==0?
                        <View style={{
                            borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                            paddingTop:10,
                            backgroundColor:darkTheme?'#12222F':'#FFFFFF',borderRadius: 10,paddingHorizontal:5}}
                        >
                            <FlatList
                            data={blogs}
                            keyExtractor={(_data, index) => index.toString()}
                            renderItem={({ item }) => <ExecuteCard data={item} navigation={navigation}/>}
                            numColumns = {1}
                            />
                        </View>:<View style={{ alignSelf: 'center',marginBottom:30 }}>
                            <Text style={authStyles.networkText} >
                                    {isLoading?'Fetching...':'Failed to Fetch Information'}
                            </Text>
                        </View>}
                    </View>
                </View>
            </View>
    )
}

export default MyBlogs

