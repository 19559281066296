import React from 'react'
import {View,TextInput} from 'react-native'
import { FormStyle } from '../../utils/app-styles'
import { useTheme } from '../../utils/state-context'


export const FormInput = ({label, placeholder,fontSize,fontWeight,lineNum,inputHeight, ...rest}) =>{
    const {darkTheme} = useTheme()
    const formStyle = FormStyle()
    return(
        <View>
            <TextInput
                style= {[formStyle.inputField,{fontSize:fontSize,fontWeight:fontWeight,height:inputHeight}]}
                value={label}
                numberOfLines={lineNum}
                placeholderTextColor={darkTheme?'#f3f3f3':'#121212'}
                underlineColorAndroid="transparent"
                placeholder={placeholder}
                multiline={true}
                autoCapitalize='sentences'
                {...rest}
            />
        </View>
    )
}


export const FormInputUpdate = ({
    label, placeholder,fontSize,fontWeight,width,
    lineNum,inputHeight,editable, ...rest}) =>{
    const {darkTheme} = useTheme()
    const formStyle = FormStyle()
    return(
        <View>
            <TextInput
                style= {[formStyle.profileInputField,{
                    fontSize:fontSize,
                    fontWeight:fontWeight,
                    height:inputHeight,
                    backgroundColor:editable && (darkTheme?'#2B2A2A':'#F3F3F3'),
                    width:'100%',
                }]}
                value={label}
                numberOfLines={lineNum}
                placeholderTextColor={darkTheme?'#f3f3f3':'#121212'}
                underlineColorAndroid="transparent"
                placeholder={placeholder}
                multiline={true}
                autoCapitalize='sentences'
                editable={editable}
                {...rest}
            />
        </View>
    )
}
