export const STORAGE_KEY = 'encryptedUserData'

export const saveToFile = (data) => {
    try {
        const serializedData = JSON.stringify(data)
        localStorage.setItem(STORAGE_KEY, serializedData)
    } catch (error) {
        alert(`Error saving data to localStorage: ${error.message}`)
    }
}

export const readFromFile = () => {
    try {
        const serializedData = localStorage.getItem(STORAGE_KEY)
        const data = JSON.parse(serializedData)
        return data
    } catch (error) {
        alert(`Error retrieving data from localStorage:${error.message}`)
    }
}

export const clearData = () => {
    try {
        localStorage.removeItem(STORAGE_KEY)
    } catch (error) {
        alert(`Error clearing data from localStorage: ${error.message}`)
    }
}