import * as React from 'react'
import {View,TextInput,Text,StyleSheet, TouchableOpacity} from 'react-native'
import { useAuthentication } from '../../utils/auth-context'
import { Image } from 'react-native'
import { baseUrl, baseUrlImage } from '../../utils/urls'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import FormData from 'form-data'
import axios from 'axios'

function CommentInput({placeholder,lineNum, progressCount,count,inputHeight,darkTheme,...rest}){
    return <View>
        <TextInput style={[styles.input,{height:inputHeight,color: darkTheme?'#f3f3f3':'#121212'}]}
        placeholder={placeholder}
        autoCapitalize='sentences'
        numberOfLines={lineNum}
        scrollEnabled={false}
        underlineColorAndroid="transparent"
        placeholderTextColor={darkTheme?'#f3f3f3':'#2D2D2D'}
        {...rest}
        />
        <View style={{ justifyContent:'flex-end',width:'100%',marginVertical:5 }}>
            <Text style={{
                color: darkTheme?'#f3f3f3':'#121212', fontWeight:'bold' }}
            >{`${progressCount}/${count}`}</Text>
        </View>
    </View>
}

function CommentSend({darkTheme,isNarrowScreen,onPress}){
    return <View style={{ marginTop: 5,alignSelf:'flex-end' }}>
        <TouchableOpacity onPress={onPress}>
            <MaterialCommunityIcons name='send' size={isNarrowScreen?20:24}
            color={ darkTheme?'#f3f3f3':'#22415a'}/>
        </TouchableOpacity>
    </View>
}

export function CommentTextInput ({darkTheme,isNarrowScreen,blog_id,setNot,setIfSent}){
    const [name,setName]= React.useState('')
    const [nameHeight,setNameHeight] = React.useState(40)
    const [email,setEmail] = React.useState('')
    const [emailHeight,setEmailHeight] = React.useState(40)
    const[comment, setComment] = React.useState('')
    // const [commentHeight,setCommentHeight] = React.useState(100)
    const [commentHeight,setCommentHeight] = React.useState(60)
    const{auth} = useAuthentication()

    const sendComment=async()=>{
        const toUpload = new FormData()
        if(auth){
            toUpload.append('user_name',auth.username)
            toUpload.append('user_profile',auth.profile)
            toUpload.append('user_email',auth.email)
            toUpload.append('comment', comment)
            toUpload.append('blog_id',blog_id)
        }else{
            toUpload.append('user_name',name)
            toUpload.append('user_email',email)
            toUpload.append('comment',comment)
            toUpload.append('blog_id',blog_id)
        }

        await axios.post(`${baseUrl}comment-create/`,toUpload)
        .then((response)=>{
            setComment('')
            setName('')
            setEmail('')
            setNot({message:'Comment sent successfully',level:'success'})
            setIfSent(true)
        }).catch((error)=>{
            setNot({message:`error Occurred ${error.message}`,level:'warning'})
        })

    }


    return <View style={{ flexDirection:'row', flexWrap:'wrap',marginVertical:10,padding:10,
        alignItems: 'flex-start',width:'100%'
    }}>
        {auth?
            <View style={styles.commentSectionLayout}>
                <View style={styles.imgCircleBlock}>
                    {auth.profile ? <Image source={{ uri:`${baseUrlImage}${auth.profile}` }}
                    style={styles.imageCircle}
                    />:<View style={styles.imageCircle}/>}
                </View>
                <View style={styles.commentLayout}>
                    <CommentInput
                        placeholder='Comment...'
                        value={comment}
                        inputHeight={comment.length<200?60:commentHeight}
                        onChangeText={(text:string)=>setComment(text)}
                        onContentSizeChange={(e: any)=>{
                            setCommentHeight(e.nativeEvent.contentSize.height)
                        }}
                        darkTheme={darkTheme}
                        lineNum={3}
                        multiline
                        maxLength={400}
                        count={400}
                        progressCount={comment.length}
                    />
                    <CommentSend darkTheme={darkTheme} onPress={sendComment}
                    isNarrowScreen={isNarrowScreen}/>
                </View>
            </View>:
            <View style={styles.commentSectionLayout}>
                <View style={styles.imgCircleBlock}>
                    <View style={styles.imageCircle}/>
                </View>
                <View style={styles.commentLayout}>
                    <CommentInput
                        placeholder='Name'
                        value={name}
                        onChangeText={(text:string)=>setName(text)}
                        onContentSizeChange={(e: any)=>{
                            setNameHeight(e.nativeEvent.contentSize.height)
                        }}
                        inputHeight={40}
                        darkTheme={darkTheme}
                        lineNum={1}
                        maxLength = {50}
                        count={50}
                        progressCount={name.length}
                    />
                    <CommentInput
                        placeholder='Email'
                        value={email}
                        onChangeText={(text:string)=>setEmail(text)}
                        onContentSizeChange={(e: any)=>{
                            setEmailHeight(e.nativeEvent.contentSize.height)
                        }}
                        inputHeight={40}
                        darkTheme={darkTheme}
                        lineNum={1}
                        maxLength = {100}
                        count={100}
                        progressCount={email.length}
                    />
                    <CommentInput
                        placeholder='Comment...'
                        value={comment}
                        inputHeight={comment.length<200?60:commentHeight}
                        onChangeText={(text:string)=>setComment(text)}
                        onContentSizeChange={(e: any)=>{
                            setCommentHeight(e.nativeEvent.contentSize.height)
                        }}
                        darkTheme={darkTheme}
                        lineNum={3}
                        multiline
                        maxLength={400}
                        count={400}
                        progressCount={comment.length}
                    />
                    <CommentSend darkTheme={darkTheme} onPress={sendComment}
                    isNarrowScreen={isNarrowScreen}/>
                </View>
            </View>
        }
    </View>
}

const styles = StyleSheet.create({
    imgCircleBlock:{
        backgroundColor:'#1A8CF1',
        width: 27,height:27,
        borderRadius: 13.5,
        margin: 10, alignItems:'center',justifyContent:'center'
    },
    imageCircle:{
        width: 25,height:25,
        borderRadius: 12.5,
        backgroundColor: '#22415a',
    },
    commentSectionLayout:{
        flexDirection:'row',
        flexWrap: 'wrap',
        width:'100%',
    },
    commentLayout:{
        width:'80%',
    },
    input:{
        width:'100%',
        paddingHorizontal: 10,
        paddingVertical:5,
        marginVertical: 5,
        borderRadius:5,
        borderWidth:1.5,borderColor:'#EDEAEA',
        // @ts-ignore
        outlineColor: 'transparent',
    }
})