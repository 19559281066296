import * as React from 'react'
import { TouchableOpacity, View,Image } from "react-native"
import { FormInput } from "../custom-components/formInput"
import { ContentCommands } from "./update-entries"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import DragHandle from "./drag-handle"
import { formatText } from '../../utils/text-formatter'

export function CreateSubtitleEntry({index,isNarrowScreen,content,
    handleSectionChange,deleteContent,handleReorder,
    newMultipleStyle}){
    return <View key={index} style={{ width: '100%',paddingTop: 20 }}>
        <DragHandle onMove={handleReorder} />
        <FormInput
        fontSize={isNarrowScreen?22:30}
        fontWeight={'bold'}
        key={index}
        inputHeight={content[index].inputHeight}
        lineNum={1}
        placeholder={`Subtitle section`}
        onChangeText={(text: any) => handleSectionChange(index, text)}
        onContentSizeChange={(e: any)=>{
            content[index].inputHeight= e.nativeEvent.contentSize.height
        }}
        label={undefined}/>
        <ContentCommands index={index} newMultipleStyle={newMultipleStyle}
        deleteContent={deleteContent} handleReorder={handleReorder}
        content={content}
        />
    </View>
}

export function CreateParagraphEntry({index,isNarrowScreen,content,
    newMultipleStyle,handleSectionChange,deleteContent,handleReorder}){
    return <View key={index} style={{ width: '100%',paddingTop: 20 }}>
        <DragHandle onMove={handleReorder} />
        <FormInput
        fontSize={isNarrowScreen?16:18}
        fontWeight={'50'}
        key={index}
        lineNum={1}
        inputHeight={content[index].inputHeight}
        placeholder={`Paragraph Section`}
        onChangeText={(text: any) => {
            handleSectionChange(index, text)
        }}
        onContentSizeChange={(e: any)=>{
            content[index].inputHeight= e.nativeEvent.contentSize.height
        }}
        label={formatText(content[index].text)}
        />
        <ContentCommands index={index} newMultipleStyle={newMultipleStyle}
        deleteContent={deleteContent} handleReorder={handleReorder}
        content={content}
        />
    </View>
}

export function CreateImageEntry({index,newMultipleStyle,item,
    deleteContent,isNarrowScreen,setImgCopyright,content,handleReorder}){
    return <View key={index} style={[newMultipleStyle.imgContentWrapper,{paddingTop:20}]}>
        <DragHandle onMove={handleReorder} />
        <Image source={{ uri:item.assets[0].uri }}
        style={newMultipleStyle.img}
        />
        <View style={{ marginTop: 10,
        alignSelf:'flex-end'
        }}>
            <TouchableOpacity
            style={[newMultipleStyle.btn,{
                width: 50,
                backgroundColor: '#F3F3F3',
                borderColor: 'transparent'
            }]}
            onPress={()=>deleteContent(index)}
            >
                <MaterialCommunityIcons name='delete-outline' size={24}/>
            </TouchableOpacity>
        </View>
        {/* setting up imageCopyright */}
        <View>
            <FormInput
            fontSize={isNarrowScreen?14:16}
            fontWeight={'50'}
            lineNum={1}
            inputHeight={content[index].inputHeight}
            label={undefined}
            placeholder={'source of the image'}
            onChangeText= {
                (element: string | number)=> setImgCopyright(element,index)
            }
            />
        </View>
    </View>
}
