import * as React from 'react'
import { TouchableOpacity,View,Text, useWindowDimensions, Image } from 'react-native'
import { FormInputUpdate } from './custom-components/formInput'
import { ProfileStyle } from '../utils/app-styles'
import axios from 'axios'
import { baseUrl, baseUrlImage } from '../utils/urls'
import FormData from 'form-data'
import * as ImagePicker from 'expo-image-picker'
import blogGenerator from '../utils/blob-generator'
import { useAuthentication } from '../utils/auth-context'
import AccountDeleteModal from './modal-components/delete-account-model'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export function UpdateProfile({darkTheme,setNot,navigation}):JSX.Element{
    const profileStyle = ProfileStyle()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {auth,login,logout} = useAuthentication()
    const [name,setName] = React.useState(auth?auth.username:null)
    const [email,setEmail] = React.useState(auth?auth.email:null)
    const [description,setDescription] = React.useState(auth?auth.description:null)
    const [profile,setProfile] = React.useState(null)
    const [links,setLinks] = React.useState(auth?JSON.parse(auth.links):null)
    const [nameHeight,setNameHeight] = React.useState<number>(28)
    const [emailHeight,setEmailHeight] = React.useState<number>(28)
    const [descriptionHeight,setDescriptionHeight] = React.useState<number>(28)
    const [instaHeight,setInstaHeight] = React.useState<number>(28)
    const [xHeight,setXHeight] = React.useState<number>(28)
    const [urlHeight,setUrlHeight] = React.useState<number>(28)
    const[editable,setEditable] = React.useState(false)
    const user_data = new FormData()

    const [modalVisible, setModalVisible] = React.useState(false)

    const handleCancel = () => {
        // Handle cancel action and hide the modal
        setModalVisible(false)
    }
    //upload update profile
    const saveProfile =async()=>{
        user_data.append('username',name)
        user_data.append('email',email)
        user_data.append('description',description)
        if(profile){
            user_data.append('profile',profile.blob,profile.filename)
        }
        user_data.append('links',JSON.stringify(links))

        await axios.put(`${baseUrl}update_user/`,user_data,
        {
            headers:{
                Authorization: `Token ${auth.token}`,
            }
        },
        )
        .then((response)=>{
            login(response)
            setNot({
                message:'Updated Profile successfully', level:'success'
            })
            setEditable(false)
        })
        .catch((err)=>{
            setNot({
                message:`Error Updating Profile: ${err.message}`, level:'warning'
            })
        })
    }

    const deleteAccount=async()=>{
        await axios.delete(`${baseUrl}delete-user/`,
            {
                headers:{
                    Authorization: `Token ${auth.token}`,
                }
            },
        )
        .then(()=>{
            logout()
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            })
            setNot({
                message:'YourAccount was Deleted successfully', level:'success'
            })
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            })
        })
        .catch((err)=>{
            setNot({
                message:`Error Deleting Account: ${err.message}`, level:'warning'
            })
        })
    }

    const handleLinks=(text:{type:string,url:string})=>{
        setLinks(prevLinks => {
            // If links is initially null, initialize it as an empty array
            const currentLinks = prevLinks || []
            const existingIndex = currentLinks.findIndex((link:{type:string,url:string}) => link.type === text.type)
            if (existingIndex !== -1) {
               // Update existing link
                const updatedLinks = [...currentLinks]
                updatedLinks[existingIndex] = text
                return updatedLinks
            } else {
               // Add new link
                return [...currentLinks, text]
            }
        })
    }

    // changes content images
    const changeImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            allowsMultipleSelection: false,
        })
        if (!result.canceled) {
            const genAnswer = await blogGenerator({imageContent:result,
                index:  0})
            const {blob,filename,contentType} = genAnswer
            setProfile({
                ...result,blob: blob,'filename': filename,
            })
        }
    }

    return(
        <View
        style={{ alignContent:'center',
            alignItems:'center', backgroundColor: darkTheme?'#12222F': '#FFFFFF',
            borderRadius: 10,paddingHorizontal:10,width: '95%',
            borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
            paddingTop:10,shadowColor: darkTheme?'#121212':'#EDEAEA',
            shadowOpacity: 2, shadowRadius: 10
        }}
        >
            <View
            style={{
                flexDirection: 'row',
            width: '100%',
            marginBottom: 10,
            paddingHorizontal:7,
            paddingVertical: 7,
            alignItems: 'center',
            justifyContent:'center'
            }}>
                <TouchableOpacity
                style={{
                    paddingHorizontal:10,
                    paddingVertical: 10,
                    borderRadius: 5,
                    backgroundColor:'#F3F3F3',
                    borderWidth: 1,
                    borderColor: '#E3E1E1',
                    alignItems: 'center',
                    justifyContent:'center',
                    width: '30%'
                }}
                onPress={()=> {
                    setEditable(true)
                }}
                >
                    <Text style={[profileStyle.btnText,{
                        fontSize: isNarrowScreen && 12
                    }]}>Edit</Text>
                </TouchableOpacity>
                <View style={{ marginHorizontal: 10,width:1,height: 30,backgroundColor:'#E3E1E1' }} />
                <TouchableOpacity
                style={{
                    paddingHorizontal:10,
                    paddingVertical: 10,
                    borderRadius: 5,
                    backgroundColor:'#F3F3F3',
                    borderWidth: 1,
                    borderColor: '#E3E1E1',
                    alignItems: 'center',
                    justifyContent:'center',
                    width: '30%'
                }}
                onPress={()=> editable && setEditable(false)}
                >
                    <Text style={[profileStyle.btnText,{
                        fontSize: isNarrowScreen && 12,color: editable===false && '#7F7D7D'
                    }]}>Cancel</Text>
                </TouchableOpacity>
            </View>
            <View style={{ width:'100%',justifyContent:'center',alignItems:'center' }}>
                {auth && profile===null && auth.profile ===null &&<View style={{
                    width: 70,height:70,borderRadius:35,borderColor:'#7F7D7D',borderWidth: 1.5,
                    justifyContent:'center',alignItems:'center'
                }}><MaterialCommunityIcons name='account' size={60} color={'#7F7D7D'}/></View>}
                {auth && auth.profile!==null && profile && <Image source={{
                    uri:`${profile.assets[0].uri}`
                }} style={{ width: 70,height:70,borderRadius:35 }}
                />}
                {auth && profile===null && auth.profile && <Image source={{uri:`${baseUrlImage}${auth.profile}`}}
                style={{ width: 70,height:70,borderRadius:35 }}/>}
                {editable && <TouchableOpacity
                style={{
                    marginTop:10,
                    paddingHorizontal:10,
                    paddingVertical: 10,
                    borderRadius: 5,
                    backgroundColor:'#F3F3F3',
                    borderWidth: 1,
                    borderColor: '#E3E1E1',
                    alignItems: 'center',
                    justifyContent:'center',
                    width: 100
                }}
                onPress={()=> changeImage()}
                >
                    <Text style={[profileStyle.btnText,{
                        fontSize: isNarrowScreen && 12,
                    }]}>Change</Text>
                </TouchableOpacity>}
            </View>
            <View style={{paddingBottom: 20,paddingLeft: 10}}>
                <Text style={profileStyle.textAuth}>{auth.username}</Text>
                {editable &&<FormInputUpdate
                    fontSize={16}
                    fontWeight={'bold'}
                    inputHeight={nameHeight}
                    lineNum={1}
                    placeholder={'username'}
                    onChangeText={(text: string) => setName(text)}
                    onContentSizeChange={(e: any)=>{
                        setNameHeight(e.nativeEvent.contentSize.height)
                    }}
                    label={name}
                    editable = {editable}
                    width={.2}
                />}
                <Text style={[
                    profileStyle.textAuth,{color:'#1A8CF1',fontWeight:'400'}
                ]}>{auth.email}</Text>
                {editable &&<FormInputUpdate
                    fontSize={16}
                    fontWeight={'bold'}
                    inputHeight={emailHeight}
                    lineNum={1}
                    placeholder={'Email'}
                    onChangeText={(text: string) => setEmail(text)}
                    onContentSizeChange={(e: any)=>{
                        setEmailHeight(e.nativeEvent.contentSize.height)
                    }}
                    label={email}
                    editable = {editable}
                    width={.2}
                />}
                <Text style={[profileStyle.textAuth,{fontWeight:'500'}]}>{auth.description}</Text>
                {editable &&<FormInputUpdate
                    fontSize={16}
                    fontWeight={'400'}
                    lineNum={2}
                    placeholder={'Short description'}
                    onChangeText={(text: string) => setDescription(text)}
                    onContentSizeChange={(e: any)=>{
                        setDescriptionHeight(e.nativeEvent.contentSize.height)
                    }}
                    inputHeight={descriptionHeight}
                    label={description}
                    editable = {editable}
                    width={.3}
                    maxLength={400}
                />}
                {editable && <View style={{ alignSelf: 'flex-start' }}>
                    <Text style={profileStyle.btnText}>
                        {`${description !== null?description.length: 0}/400`}
                    </Text>
                </View>}
                {editable && <View style={{ width: '100%' }}>
                    <FormInputUpdate
                        fontSize={16}
                        fontWeight={'400'}
                        lineNum={2}
                        placeholder={'Instagram'}
                        onChangeText={(text: string) => handleLinks({type:'Instagram',url:text})}
                        onContentSizeChange={(e: any)=>{
                            setInstaHeight(e.nativeEvent.contentSize.height)
                        }}
                        inputHeight={instaHeight}
                        label={links && links.find((link:{type:string,url:string}) => link.type === 'Instagram')?.url}
                        editable = {editable}
                        width={.3}
                        maxLength={400}
                    />
                    <FormInputUpdate
                        fontSize={16}
                        fontWeight={'400'}
                        lineNum={2}
                        placeholder={'X / Twitter'}
                        onChangeText={(text: string) => handleLinks({type:'X',url:text})}
                        onContentSizeChange={(e: any)=>{
                            setXHeight(e.nativeEvent.contentSize.height)
                        }}
                        inputHeight={xHeight}
                        label={links && links.find((link:{type:string,url:string}) => link.type === 'X')?.url}
                        editable = {editable}
                        width={.3}
                        maxLength={400}
                    />
                    <FormInputUpdate
                        fontSize={16}
                        fontWeight={'400'}
                        lineNum={2}
                        placeholder={'Other links'}
                        onChangeText={(text: string) => handleLinks({type:'Other',url:text})}
                        onContentSizeChange={(e: any)=>{
                            setUrlHeight(e.nativeEvent.contentSize.height)
                        }}
                        inputHeight={urlHeight}
                        label={links && links.find((link:{type:string,url:string}) => link.type === 'Other')?.url}
                        editable = {editable}
                        width={.3}
                        maxLength={400}
                    />
                </View>}
            </View>
            <View style={{
                flexDirection:'row',flexWrap:'wrap',justifyContent:'center',
                width: '95%'
            }}><TouchableOpacity
                style={{
                    paddingHorizontal:10,
                    paddingVertical: 10,
                    borderRadius: 5,
                    backgroundColor:editable?'#1A8CF1':'#F3F3F3',
                    borderWidth: 1,
                    borderColor: '#E3E1E1',
                    alignItems: 'center',
                    justifyContent:'center',
                    width: 70,
                    marginBottom: 20
                }}
                onPress={()=> editable && saveProfile()}
                >
                    <Text style={[profileStyle.btnText,{
                        fontSize: isNarrowScreen && 12,
                        color:editable?'#ffffff':'#7F7D7D'
                    }]}>Save</Text>
                </TouchableOpacity>
                <View style={{width: 10}}/>
                <TouchableOpacity
                style={{
                    paddingHorizontal:10,
                    paddingVertical: 10,
                    borderRadius: 5,
                    backgroundColor:'#B93A3A',
                    borderWidth: 1,
                    borderColor: '#B93A3A',
                    alignItems: 'center',
                    justifyContent:'center',
                    width: 70,
                    marginBottom: 20
                }}
                onPress={()=> setModalVisible(true)}
                >
                    <Text style={[profileStyle.btnText,{
                        fontSize: isNarrowScreen && 12,color:'#ffffff'
                    }]}>Delete</Text>
                </TouchableOpacity>
                <AccountDeleteModal visible={modalVisible} sendRequest={deleteAccount} onCancel={handleCancel} />
            </View>
        </View>
    )
}
