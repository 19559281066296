import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as React from 'react'
import { TouchableOpacity, View, Text, Image,useWindowDimensions,TouchableWithoutFeedback } from 'react-native'
import { NavigationBarStyle } from '../../utils/app-styles'
import { baseUrlImage } from '../../utils/urls'
import { useTheme } from '../../utils/state-context'
import { useAuthentication } from '../../utils/auth-context'

export function PopMenu({navigation,auth}):JSX.Element{
    const [isDropped,setIsDropped] = React.useState(false)
    const {logout,setLoggedOut} = useAuthentication()
    const {darkTheme} = useTheme()
    const navBarStyle = NavigationBarStyle()
    const [showSearchResults, setShowSearchResults] = React.useState(false)
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const SignOutControl=()=>{
        // Logout after resetting navigation
        logout()
        setLoggedOut(true)
        navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
        })
    }

    const collapseDropdown = () => {
        setIsDropped(false);
        setShowSearchResults(false);
    }

    return <View style={{ flexDirection: 'row', alignItems: 'center',
        alignSelf:'flex-end'
        }}>
        <TouchableOpacity onPress={()=> setIsDropped(!isDropped)}
        onFocus={() => setShowSearchResults(true)}
        onBlur={()=>setShowSearchResults(true)}
        >
            {
                auth.profile && auth.profile !== null?
                <View
                style={{ width: 44,height:44, borderRadius:22,
                    borderWidth:2,borderColor:'#1A8CF1',alignItems:'center',
                    alignContent:'center',justifyContent:'center'
                }}
                >
                    <Image source={{uri:`${baseUrlImage}${auth.profile}`}}
                    style={{ width: 40, height:40,borderRadius:20 }}
                    />
                </View>:
                <MaterialCommunityIcons name='account-circle-outline' size={isNarrowScreen?28:36} color={'#1A8CF1'}/>
            }
        </TouchableOpacity>
        {isDropped && showSearchResults && <View style={{
            width: isNarrowScreen?300:250,
            position: 'absolute',
            top: 40,right: 0,
            backgroundColor: darkTheme?'#12222F':'white', // Set background color as needed
            zIndex: 1, // Ensure it's above other content
            elevation: 5, // Adjust elevation as needed
            paddingTop: 10,
            borderRadius: 5,
            borderWidth: 1,
            borderColor:darkTheme?'#545454':'#CDCCCC',marginBottom: 10,marginTop:5,
        }}>
            <View style={{ paddingHorizontal:10,paddingBottom:10,paddingTop:5 }} >
                <Text style={{
                    fontFamily:'roboto-bold',fontSize: isNarrowScreen?14:16,
                    color: darkTheme?'#ffffff':'#121212'
                }} >{auth.username}</Text>
                <Text style={{
                    fontFamily:'roboto-regular',fontSize:isNarrowScreen? 12:14,
                    color: '#1A8CF1'
                }} >{auth.email}</Text>
            </View>
            <View
            style={{
                height:0.5, backgroundColor: darkTheme?'#545454':'#CDCCCC',
                width: '95%', marginTop: 5, marginBottom:10,
                alignSelf:'center'
            }}
            />
            <View style={{ alignSelf:'flex-start' }} >
                {auth.is_Writer &&<TouchableOpacity onPress={() => {
                    setIsDropped(false)
                    navigation.navigate('NewPost')
                }}
                style={{ marginVertical: 10,borderRadius: 5,
                    marginHorizontal:5,backgroundColor:darkTheme?'#22415a':'#f9f9f9',
                    width: isNarrowScreen?290:240,paddingVertical: 10,paddingHorizontal: 5
                }}
                >
                    <Text style={[navBarStyle.textLogin,{
                        color:darkTheme?'#ffffff':'#2B2B2B',
                        fontFamily:'roboto-bold'
                    }]}>Create</Text>
                </TouchableOpacity>}
                {auth.is_Writer && <TouchableOpacity onPress={() => {
                    setIsDropped(false)
                    navigation.navigate('Profile')
                }}
                style={{ marginVertical: 10,
                    marginHorizontal:5,backgroundColor:darkTheme?'#22415a':'#f9f9f9',
                    width: isNarrowScreen?290:240,paddingVertical: 10,paddingHorizontal: 5,
                    borderRadius:5
                }}>
                    <Text style={[navBarStyle.textLogin,{
                        color:darkTheme?'#ffffff':'#2B2B2B',
                        fontFamily:'roboto-bold'
                    }]}>DashBoard</Text>
                </TouchableOpacity>}
                <TouchableOpacity onPress={() => {
                    setIsDropped(false)
                    navigation.navigate('Bookmarks')
                }}
                style={{ marginVertical: 10,
                    marginHorizontal:5,backgroundColor:darkTheme?'#22415a':'#f9f9f9',
                    width: isNarrowScreen?290:240,paddingVertical: 10,paddingHorizontal: 5,
                    borderRadius:5
                }}>
                    <Text style={[navBarStyle.textLogin,{
                        color:darkTheme?'#ffffff':'#2B2B2B',
                        fontFamily:'roboto-bold'
                    }]}>Library</Text>
                </TouchableOpacity>
            </View>
            <View
            style={{
                height:0.5, backgroundColor: darkTheme?'#545454':'#CDCCCC',
                width: '95%', marginTop: 5, marginBottom:10,
                alignSelf:'center'
            }}
            />
            <TouchableOpacity onPress={() => SignOutControl()}
            style={{ marginBottom: 20,paddingHorizontal:10 }}>
                <Text style={[navBarStyle.textLogin,{
                    fontWeight:'700',color:'#1A8CF1'
            }]}>Sign Out</Text>
            </TouchableOpacity>
        </View>}
    </View>
}