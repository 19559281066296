import { AdminRequestScreen } from "../admin/admin-requests-screen";
import About from "../policies/about";
import Policy from "../policies/policy-screen";
import Terms from "../policies/terms";
import FilterBlogs from "../screens/filter-blogs";

const linking = {
    prefixes: [
        'https://yourstory.co.ke/',
        'http://localhost:19006/',
        'http://127.0.0.1:8000/',
        // Linking.createURL("/")
    ],
    config: {
        screens: {
            Home: "/",
        //  ... other screens
        BlogRead: "blog/:id",
        SignIn: "SignIn/",
        SignUp: "SignUp/",
        Blogs: 'Blogs/',
        MyBlogs: 'MyBlogs/',
        NewPost: 'NewPost/',
        Profile: 'Profile/',
        Bookmarks:'Bookmarks/',
        FilterBlogs: 'FilterBlogs/',
        Latest: 'Latest/',
        AuthBlogRead:'AuthBlogRead/',
        UpdatePost:'UpdatePost/',
        Admin: 'Admin/',
        AdminSignIn:'AdminSignIn/',
        SearchScreen:'Search/',
        Terms:'Terms',
        Policy:'Policy',
        About: 'About',
        AdminAccountsList:'AdminAccountsList/',
        AdminRequestScreen:'AdminRequestScreen/',
        },
    },
};

export default linking