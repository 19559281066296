import * as React from 'react'
import {View,Text,useWindowDimensions,
    ScrollView} from 'react-native'
import BottomSide from '../components/bottom-side'
import { useTheme } from '../utils/state-context'
import { BlogStory } from '../utils/story-data'
import axios from 'axios'
import { baseUrl } from '../utils/urls'
import { mainBlogSetter } from '../utils/story-interface-setter'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import AuthorBlogContent from '../components/auth-blog-content'
import CircularProgressIndicator from '../components/custom-components/progress-indicator'


// display loading page
const FetchingLoader= ()=>{
    const {
        darkTheme,
    } = useTheme()
    return <View
    style=
    {{
        backgroundColor:darkTheme?'#121212':'#ffffff',
        alignItems: 'center',alignContent:'center',
        height: '100%',
        marginVertical: 30,
    }}><Text>Fetching...</Text></View>
}

const BlogReadAuthor = ({route,navigation}) => {
    const scrollViewRef = React.useRef(null)
    const {id} = route.params
    const [data,setData] = React.useState<BlogStory>()
    //progress setter
    const [progress, setProgress] = React.useState(0)
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })
    const {
        darkTheme,
    } = useTheme()
    React.useEffect(() => {
        // Scroll the ScrollView to the top when the route parameter changes
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ y: 0, animated: false })
        }
        // Variable to track if fast forward is needed
        let shouldFastForward = false
        // Set up the interval for progress update
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
            // Check if fast forward is needed
            if (shouldFastForward) {
                clearInterval(interval) // Stop the regular progress update interval
                return 100 // Set progress to 100%
            }
            // Continue regular progress update
            return (prevProgress + 5) % 101
            })
        }, 30)
        // Set up the homeData after 2 seconds (adjust as needed)
        const timeout = setTimeout(() => {
            axios.get(`${baseUrl}get_blog/${id}/`)
            .then((response)=>{
                setData(mainBlogSetter(response.data))
            })
            shouldFastForward = true // Set the flag to trigger fast forward
        }, 2000)
        // Clear intervals and timeouts on component unmount or dependencies change
        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
    }, [id])

    return(
        <View style={{
            backgroundColor:darkTheme?'#121212':'#f3f3f3', flexGrow: 1,flexShrink:1,
            width:'100%',
        }}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            <ScrollView
            showsVerticalScrollIndicator={false}
            ref={scrollViewRef}
            >
                <View style={{
                alignItems:'center',
                // backgroundColor: darkTheme?'#2B2A2A':'#f3f3f3',
                }}>
                    {data?<View
                    style={{
                        maxWidth:isNarrowScreen?windowWidth*.9:windowWidth *.6,
                        backgroundColor: darkTheme?'#12222F':'#ffffff',
                        padding:5,borderRadius: 10,
                        marginVertical:10
                    }}
                    >
                        <AuthorBlogContent blog={data} setNot={setNotification}/>
                    </View>:
                    <View style={{
                            marginTop:'20%',
                            maxWidth:isNarrowScreen?windowWidth*.9:windowWidth *.6,
                            alignSelf:'center',alignContent:'center',
                            alignItems:'center', justifyContent:'center',
                            marginBottom: 20
                    }}>
                        <CircularProgressIndicator
                        radius={25}
                        strokeWidth={1}
                        progress={progress}
                        />
                    </View>}
                    {isNarrowScreen&&data && <BottomSide navigation={undefined}/>}
                </View>
            </ScrollView>
        </View>
    )
}

export default BlogReadAuthor
