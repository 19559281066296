//describes the title, image and content paragraphs layout visible to the customers.
//styling is described below the component.
import * as React from 'react'
import {View, Text,Image, useWindowDimensions} from 'react-native'
import axios from 'axios'
import FormData from 'form-data'
import { BlogContentStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import { baseUrl, baseUrlImage } from '../utils/urls'
import { timeAgo } from '../utils/time-ago'
import DateTimeFormatter from '../utils/date-time-util-formatter'



function TimeStamp({blog}){
    const {darkTheme} = useTheme()
    //date management
    const dateObject = timeAgo(blog.created_at)
    const blogContentStyle = BlogContentStyle()
    const {hours,minutes} = DateTimeFormatter(new Date(blog.created_at))
    // converts story object to string
    const content = blog?.content

    return(
        <View style={blogContentStyle.timeStampWrapper}>
            <View style={{ flexDirection: 'row' }}>
                <Text style={blogContentStyle.dateText}>{`Posted ${dateObject}`}</Text>
                <Text style={blogContentStyle.timeText}>{` . ${hours} : ${minutes} ${hours>11?'pm': 'am'} `}</Text>
            </View>
            <Text style={{ ...blogContentStyle.dateText,fontWeight: 'bold' }}>
                {/*calculates approximate duration able to read the blog*/}
                {`.   ${Math.ceil(content[0].story.length/1500*content.length)} min Read`}
            </Text>
        </View>
    )
}

function BioContent({blog}){
    const blogContentStyle = BlogContentStyle()
    return <View>
        <Text style = {blogContentStyle.editorText}>
                {`Written by ${blog?.author?.name}`}
            </Text>
            <View >
                <Text style={blogContentStyle.contentText}>
                    {` ${blog?.author?.description}`}
                </Text>
            </View>
    </View>
}


function AuthorBlogContent({blog,setNot}){
    const {darkTheme} = useTheme()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    const blogContentStyle = BlogContentStyle()

    // converts story object to string
    const selectedImages = blog?.contentImages
    const mergedContent = []
    // converts story object to string
    const content = blog?.content

    if (content && selectedImages) {
        const combinedData = [...content, ...selectedImages]
        combinedData.sort((a, b) => {
            return a.index - b.index
        })
        for (let i = 0; i < combinedData.length; i++) {
            mergedContent.push(combinedData[i])
        }
    }

    // rendered component
    return(
        <View style={{ flexDirection: 'column'}}>
            <View key={blog.id}>
                {/* Upper Wrapper */}
                <View style={blogContentStyle.upperWrapper}>
                    {/* title section */}
                    <View style={{
                        width: '95%',
                        alignItems: 'center',alignSelf:'center', justifyContent:'center',
                        marginTop: isNarrowScreen?10: 30,
                        marginBottom: 20
                        }}>
                            <Text style={blogContentStyle.title}>
                                {blog.title}
                            </Text>
                    </View>
                    {/* timestamp of story */}
                    <TimeStamp blog={blog}/>
                </View>
                {/* Lower Wrapper */}
                <View style={blogContentStyle.lowerWrapper}>
                        {mergedContent.map((item, index) => (
                            <View key={index}>
                                {item.type === 'subtitle' && (
                                    <View style={{
                                        flexDirection: 'row',
                                        alignContent:'flex-start',justifyContent:'flex-start',
                                        alignItems: 'flex-start',alignSelf:'flex-start'
                                    }} >
                                        <Text key={index} style={blogContentStyle.subTitleText}>
                                            {item.story}
                                        </Text>
                                    </View>
                                )}
                                {item.type === 'paragraph' && (
                                    <Text key={index} style={blogContentStyle.contentText}>
                                    {item.story}
                                    </Text>
                                )}
                                {item.type === 'image' &&  (
                                    <View style={blogContentStyle.imgContentWrapper}>
                                        <Image
                                        source={{ uri:`${baseUrlImage}${item.media}` }}
                                        style={blogContentStyle.img}
                                        resizeMethod='auto'
                                        resizeMode='contain'
                                        />
                                    </View>
                                )}
                            </View>
                        ))}
                    {/* choices and other reaction sections */}
                    {/* legal acknowledgement text */}
                    <View style={{
                        marginVertical: 10 ,alignItems:'flex-start',alignContent:'flex-start',
                        alignSelf:'flex-start'
                        }} >
                        {/* Bio section */}
                        <BioContent blog={blog}/>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default AuthorBlogContent