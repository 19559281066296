// this component is a card that describes the layout of the blogs card
// its style is defined inside this file with imported dimensions

import React from 'react'
import {View, Text,Pressable, useWindowDimensions} from 'react-native'
import { BlogCardStyle } from '../../utils/app-styles'
import { timeAgo } from '../../utils/time-ago'
import { baseUrlImage } from '../../utils/urls'
import {Image} from 'expo-image'
import { useTheme } from '../../utils/state-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'


const BlogTrendCard=({onPress,data,index})=>{
    const styles = BlogCardStyle()
    const {darkTheme} = useTheme()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const [isHovered, setIsHovered] = React.useState(false)

    const pressHandler = () => {
      // Handle press
        onPress && onPress()
    }

    //date management
    const dateObject = timeAgo(data.created_at)
    // converts story object to string
    const content = data.content
    const author = data.author
    return(
        <Pressable onPress={pressHandler}
        //@ts-ignore
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={({ pressed }) => [
            {
                // backgroundColor: pressed ? darkTheme?'#22415a':'#FFFEFE' : (isHovered && (darkTheme?'#22415a':'#F7F4F4')),
                borderRadius: 10, width: isHovered ?'50%':'45%'
            },styles.container
        ]} key={index}>
            <View style={{ width: '100%' }}>
                <View style={{ width:'100%' }}>
                    <View style={{
                            width:'100%',height: 100, borderTopLeftRadius: isNarrowScreen?5:10,
                            borderTopRightRadius: isNarrowScreen?5:10,backgroundColor:darkTheme?'#454545':'#EDEAEA'
                    }}>
                        <Image source={{ uri:`${baseUrlImage}${data.contentImages[0].media}` }}
                        contentFit='cover' style={{
                            width:'100%',height: 100, borderTopLeftRadius: 5,
                            borderTopRightRadius: 5
                        }}
                        />
                    </View>
                    {data.contentImages[0] && <View style={{
                        position: 'absolute', top: 10, right: 3,
                        backgroundColor:isHovered&&( darkTheme?'rgba(18, 34, 47, 0.7)' : 'rgba(255, 255, 255, 0.7)'),
                        borderRadius: 10,paddingHorizontal:5,paddingVertical:2,
                    }}>
                        <View style={{
                            flexDirection:'row',flexWrap:'wrap', justifyContent:'space-evenly',
                            alignItems:'center',width: '100%'
                        }}>
                            <Text style={{
                                fontWeight: 'bold',color: isHovered?'#1A8CF1':'transparent',
                                marginRight:5,maxWidth:100,fontSize: 12
                            }} ellipsizeMode="tail" numberOfLines={1}>
                                {data.contentImages[0].imageCopyright}
                            </Text>
                            <MaterialCommunityIcons name='information-outline'
                            size={18} color={isHovered?'#1A8CF1':'transparent'}/>
                        </View>
                    </View>}
                    <View style={{
                        width:isHovered?36:34,
                        height: isHovered?36:34,
                        backgroundColor: darkTheme?'#12222F':'#ffffff',
                        borderRadius: isHovered?18:17,
                        justifyContent: 'center',
                        alignItems: 'center',position: 'absolute', top: -20, left: 3,
                    }}>
                        <View style={{
                            width: isHovered?32:30,
                            height: isHovered?32:30,
                            backgroundColor: darkTheme?'#12222F':'#ffffff',
                            borderColor: '#1A8CF1',
                            borderWidth:2,
                            borderRadius: isHovered?16:15,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Text style={{
                                fontSize: isNarrowScreen? 16:18,
                                fontFamily: 'roboto-bold',color:'#1A8CF1'
                            }}>{index+1}</Text>
                        </View>
                    </View>
                </View>
                <View style={{  marginBottom: 1,width:'100%',
                paddingVertical:7,paddingRight:7,
                flexDirection:'row',flexWrap:'wrap',
                alignItems:'flex-start',alignContent:'center',
                borderWidth: 1.5, borderTopWidth: 0,
                borderColor:darkTheme?'#454545':'#EDEAEA',
                borderRadius: 5,
                borderTopEndRadius: 0, borderTopStartRadius: 0,
                }}>
                <View style={{ width: '100%',paddingLeft: 5 }}>
                    <View style={{
                        width:'100%',
                        flexDirection:'row',flexWrap:'wrap',
                        alignItems:'center',paddingBottom:5,
                    }}>
                        <View style={{
                            width: 24,height: 24, backgroundColor: isHovered?'#1A8CF1':'#D6D1D1',
                            marginRight:10,
                            alignItems:'center',justifyContent:'center',
                            borderRadius: 12,marginTop: 2
                        }}>
                            <Image source={{uri:`${baseUrlImage}${data.author?.profile}`}}
                            style={{ width: 22,height: 22,borderRadius: 11
                            }}/>
                        </View>
                        <Text style = {styles.authorText}>
                            {`${author?.name}`}
                        </Text>
                    </View>
                    <View style={{ width: '100%',paddingLeft: isNarrowScreen? 20:30 }}>
                        <Text style={[
                            styles.txt,{fontSize:isHovered?(isNarrowScreen?14:16):(isNarrowScreen?12:14)}
                        ]} numberOfLines={isHovered?3:1}>{data.title}</Text>
                        {data.content.length>0 &&
                        <Text style={{ ...styles.authorText,fontWeight: 'bold' }}>
                            {`.  ${Math.ceil(content[0].story.length/1500*content.length)} min Read  ${dateObject}`}
                        </Text>}
                    </View>
                </View>
                </View>
            </View>
        </Pressable>
    )
}
export default BlogTrendCard
