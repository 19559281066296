import { TouchableOpacity,Text } from "react-native";
import { useTheme } from "../utils/state-context";

export function SubButton({setSubscribe}){
    const {darkTheme} = useTheme()
    return (
        <TouchableOpacity
        style={{
            // borderWidth: 1.5,
            // borderColor: darkTheme?'#f3f3f3':'#141414',
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 7,
            paddingVertical: 5,
            width: '50%',
            alignSelf:'center',
            marginVertical: 20
        }}
        onPress={()=> setSubscribe(true)}
        >
            <Text
            style={{ fontSize: 16, fontWeight: 'bold',color:darkTheme?'#f3f3f3':'#121212' }}
            >Want to Subscribe</Text>
        </TouchableOpacity>
    )
}