import * as React from 'react'
import { View, Text,
    TouchableOpacity,
    useWindowDimensions,
    ScrollView} from 'react-native'
import {FormInput} from '../components/custom-components/formInput'
import { NewMultipleStyle } from '../utils/app-styles'
import * as ImagePicker from 'expo-image-picker'
import { useTheme } from '../utils/state-context'
import blogGenerator from '../utils/blob-generator'
import StoryControls from '../components/story-create-control'
import TopicButton from '../components/topic-button'
import { CreateContentControls } from '../components/create-content-controls'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import BottomSide from '../components/bottom-side'
import { CreateImageEntry, CreateParagraphEntry, CreateSubtitleEntry } from '../components/data-entry/create-entry'
import { formatText } from '../utils/text-formatter'


const NewPostMultiple=({navigation})=>{
    //initialize  the global theme
    const {darkTheme,topics} = useTheme()
    // initializing the styles
    const newMultipleStyle = NewMultipleStyle()
    const blog_id = ''
    // initialize data to upload to post api endpoint
    const [title, setTitle] = React.useState('')
    // title height
    const [titleHeight,setTitleHeight] = React.useState(60)
    // Initialize content
    const [content, setContent] = React.useState([])
    // initialize for topic
    const [selectedTopic, setSelectedTopic] = React.useState(null)
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })

    // Function to toggle the visibility of the topics views
    const toggleTopicsVisibility = () => {
        setSelectedTopic(null)
    }

    //add a subtitle
    const handleSubtitle=()=>{
        setContent([...content,{type:'subtitle',text:'',
        inputHeight:40,index:content.length===0?0:content.length}])
    }

    //add paragraph to contents
    const handleAddParagraph=()=>{
        setContent([...content,{type:'paragraph',text:'',
        inputHeight: 40, index: content.length===0?0:content.length}])
    }

    //handling changes in paragraphs
    const handleSectionChange =(index: number,text: any):void=>{
        const updatedContent = [...content]
        updatedContent[index].text = text
        setContent(updatedContent)
    }

    // adds content images
    const selectImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            allowsMultipleSelection: false,
        })
        if (!result.canceled) {
            const genAnswer = await blogGenerator({imageContent:result,
                index:  content.length+1})
            const {blob,filename,contentType} = genAnswer
            setContent([...content,
                {...result,type:'image',blob: blob,'filename': filename,imageCopyright:'',
                contentType:contentType,
                inputHeight:40,index: content.length===0?0:content.length}
            ])
        }
    }

    const setImgCopyright=(text: any,index: string | number)=>{
        const updatedContent = [...content]
        updatedContent[index].imageCopyright = text
        setContent(updatedContent)
    }

    // deletes images in content
    const deleteContent = (index: number) => {
        const newContent = [...content]
        newContent.splice(index, 1)
        // Update indices for the remaining items
        newContent.forEach((item, i) => {
            item.index = i
        })
        setContent(newContent)
    }

    const handleReorder = (fromIndex:number, dragDistance:any) => {
        // Determine drag direction
        const direction = dragDistance < 0 ? -1 : 1
        // Adjust toIndex based on drag direction
        const toIndex = fromIndex + direction

        // Ensure toIndex is within the valid range
        if (toIndex >= 0 && toIndex < content.length) {
            setContent(prevContent => {
            const reorderedContent = [...prevContent]

            // Remove the dragged item from the original position
            const [movedItem] = reorderedContent.splice(fromIndex, 1)
            // Insert the dragged item at the new position
            reorderedContent.splice(toIndex, 0, movedItem)

            // Update the index property based on the new order
            const updatedContent = reorderedContent.map((item, index) => ({
                ...item,
                index,
            }))
            return updatedContent
            })
        }
    }

    return(
        <View style={{
        backgroundColor: darkTheme?'#121212':'#f3f3f3',
        width:'100%',
        height:'100%'
        }}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={[newMultipleStyle.topContainer,{
                    marginTop: 10,width:isNarrowScreen?'90%':'70%',
                    backgroundColor: darkTheme?'#12222F':'#ffffff',
                    alignItems: 'center',
                    alignContent: 'center',
                    alignSelf: 'center',
                    padding: 5,borderRadius: 10,paddingTop: 10,
                    borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                }]}>
                    <StoryControls
                        title = {title}
                        content={content}
                        navigation={navigation}
                        selectedTopic={selectedTopic}
                        darkTheme={darkTheme}
                        setNot={setNotification}
                        isStatus={'create'}
                        blog_id={blog_id}
                        toDelete={null}
                    />
                    {/* Button to open the topics modal */}
                    <View style={[newMultipleStyle.btnAdd,
                        {alignSelf:'flex-start',marginHorizontal: 10,marginVertical: 5
                    }]}>
                            <Text style={newMultipleStyle.btnTextAdd}>Select Topic</Text>
                    </View>
                    {/* display of the topics */}
                    {selectedTopic&&<View style={{
                        flexWrap:'wrap',
                        flexDirection: 'row', alignSelf: 'center',
                        width:'90%'
                    }}>
                        <View>
                            <TopicButton topic={selectedTopic}/>
                            <TouchableOpacity onPress={toggleTopicsVisibility} style={
                                [newMultipleStyle.btnAdd,{alignSelf:'flex-start'}]
                                }>
                                <Text style={newMultipleStyle.btnTextSection}>Change Topic</Text>
                            </TouchableOpacity>
                        </View>
                    </View>}
                    {!selectedTopic && <View style={{
                        flexWrap:'wrap',
                        flexDirection: 'row', alignSelf: 'center',
                        width: '99%'
                    }} >
                        {topics.map((topic: any,index: React.Key)=>(
                            <TouchableOpacity onPress={()=>{
                                setSelectedTopic(topic)
                            }} key={index} >
                                <TopicButton topic={topic}/>
                            </TouchableOpacity>)
                        )}
                    </View>}
                </View>
                <View style={{
                    backgroundColor: darkTheme?'#12222F':'#ffffff',
                    width:isNarrowScreen?'90%':'70%',
                    borderRadius: 10,borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                    alignSelf:'center',marginTop:10,marginBottom: 20,
                    padding: 5
                }} >
                    <View style={newMultipleStyle.contentWrapper}>
                        {/* sets the title of the blog */}
                        <View style={{ width: '100%' }}>
                            <FormInput
                            fontSize={isNarrowScreen?32:42}
                            fontWeight={'bold'}
                            label={title}//title data viewing
                            lineNum={1}
                            maxLength={100}
                            inputHeight={titleHeight}
                            placeholder={'Title Section'}
                            onChangeText = {(content: any) => setTitle(content) }
                            onContentSizeChange={(e: any)=>{
                                setTitleHeight(e.nativeEvent.contentSize.height)
                            }}
                            />
                        </View>
                        {/* list of content -> subtitles, paragraphs and images */}
                        {
                            content.map((item,index)=>{
                                const isFirstIndex = index === 0;
                                const isLastIndex = index === content.length - 1
                                const dragThreshold = 20
                                if(item.type ==='paragraph'){
                                    return(
                                        <CreateParagraphEntry
                                        isNarrowScreen={isNarrowScreen} index={index}
                                        handleSectionChange={handleSectionChange}
                                        newMultipleStyle={newMultipleStyle} content={content}
                                        deleteContent={deleteContent}
                                        handleReorder={(dragDistance:any) => {
                                            if (
                                                (dragDistance < 0 && !isFirstIndex && Math.abs(dragDistance) > dragThreshold) ||
                                                (dragDistance > 0 && !isLastIndex && dragDistance > dragThreshold)
                                            ) {
                                                handleReorder(index, dragDistance);
                                            }
                                        }}
                                        />
                                    )
                                } else if(item.type ==='subtitle'){
                                    return(
                                        <CreateSubtitleEntry
                                        isNarrowScreen={isNarrowScreen} index={index}
                                        handleSectionChange={handleSectionChange}
                                        newMultipleStyle={newMultipleStyle} content={content}
                                        deleteContent={deleteContent}
                                        handleReorder={(dragDistance:any) => {
                                            if (
                                                (dragDistance < 0 && !isFirstIndex && Math.abs(dragDistance) > dragThreshold) ||
                                                (dragDistance > 0 && !isLastIndex && dragDistance > dragThreshold)
                                            ) {
                                                handleReorder(index, dragDistance);
                                            }
                                        }}
                                        />
                                    )
                                }else if (item.type === 'image'){
                                    return(
                                        <CreateImageEntry
                                        index={index} isNarrowScreen={isNarrowScreen}
                                        newMultipleStyle={newMultipleStyle} deleteContent={deleteContent}
                                        setImgCopyright={setImgCopyright} content={content}
                                        item={item}
                                        handleReorder={(dragDistance:any) => {
                                            if (
                                                (dragDistance < 0 && !isFirstIndex && Math.abs(dragDistance) > dragThreshold) ||
                                                (dragDistance > 0 && !isLastIndex && dragDistance > dragThreshold)
                                            ) {
                                                handleReorder(index, dragDistance);
                                            }
                                        }}
                                        />
                                    )
                                }
                            })
                        }
                        {/* buttons to add paragraph, subtitle or image*/}
                        <View style={{ alignSelf:!isNarrowScreen? 'center': 'flex-start' }}>
                            <CreateContentControls handleAddParagraph={handleAddParagraph}
                            handleSubtitle={handleSubtitle} selectImage={selectImage}/>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

export default NewPostMultiple