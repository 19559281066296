import Navigation from './lib/navigation'
import {useColorScheme} from 'react-native'
import { ColorThemeProvider } from './lib/utils/state-context'
import { BlogProvider } from './lib/utils/blog-context'
import { AllBlogsProvider } from './lib/utils/all-blogs-context'
import { NewStoryProvider } from './lib/utils/new-blog-context'
import { AuthProvider } from './lib/utils/auth-context'
import { AdminAuthProvider } from './lib/admin/admin-utils/admin-auth-context'
import { AdminBlogsProvider } from './lib/admin/admin-utils/admin-blogs-context'

export default function App() {
  const colorScheme = useColorScheme()

  return (
    <ColorThemeProvider>
      <AdminAuthProvider>
        <AuthProvider>
          <AdminBlogsProvider>
            <NewStoryProvider>
              <BlogProvider>
                <AllBlogsProvider>
                  <Navigation colorScheme={colorScheme}/>
                </AllBlogsProvider>
              </BlogProvider>
            </NewStoryProvider>
          </AdminBlogsProvider>
        </AuthProvider>
      </AdminAuthProvider>
    </ColorThemeProvider>
  )
}