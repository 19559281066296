import * as React from 'react'
import {ScrollView, View,useWindowDimensions,Text} from 'react-native'
import { useTheme } from '../utils/state-context'
import { UpdateProfile } from '../components/update-profile'
import axios from 'axios'
import { baseUrl } from '../utils/urls'
import { useAuthentication } from '../utils/auth-context'
import { allBlogsSetter } from '../utils/story-interface-setter'
import { HomeSectionList } from '../components/section-list-home'
import CircularProgressIndicator from '../components/custom-components/progress-indicator'
import FormData from 'form-data'

export function BookmarkHandler({navigation, setNotification}){
    const{auth} = useAuthentication()
    const {darkTheme} = useTheme()
    const [progress, setProgress] = React.useState(0)
    const [homeData,setHomeData] = React.useState([])
    const[isLoading,setIsLoading] = React.useState(true)
    const [bookmarks,setBookmarks] = React.useState<number[]>(auth && auth.bookmarks!==null?JSON.parse(auth.bookmarks):[])
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    React.useEffect(()=>{
        const fetchBookmarks = async () => {
            if(auth !==null){
                try {
                    const bookmarkForm = new FormData();
                    if (bookmarks && bookmarks.length > 0) {
                        bookmarks.forEach((item: any) => {
                            bookmarkForm.append('id', item)
                        })
                    }
                    const response = await axios.post(`${baseUrl}bookmarks/`,bookmarkForm,{
                            headers: {
                            Authorization: `Token ${auth.token}`,
                            'Content-Type': 'multipart/form-data',
                            },
                    })
                    setHomeData([{ title: '', data: allBlogsSetter(response.data) }])
                } catch (error) {
                    setNotification({message:`Error while handling request: ${error.message}`,level:'warning'})
                }
            }
        }
        fetchBookmarks()
    },[])

    return(
        <ScrollView showsVerticalScrollIndicator={false}>
            <View style={{
                width:'100%',flexDirection: 'row',flexWrap:'wrap',
                justifyContent:'center'
            }}>
                {!auth.is_Writer && <View
                style={{
                    width: isNarrowScreen?'100%':'30%',
                }}>
                    <View style={{marginTop: 10}}>
                        <Text style={{
                            fontSize: windowWidth>600?20: 18,
                            fontWeight: 'bold',
                            fontFamily: 'roboto-bold',
                            color:darkTheme?'#f3f3f3':'#121212',
                            marginBottom: 20}}>
                            Profile
                        </Text>
                    </View>
                    <UpdateProfile darkTheme={darkTheme} setNot={setNotification} navigation={navigation}/>
                </View>}
                <View style={{
                    width: isNarrowScreen ? '100%' : '60%',
                }}>
                    {bookmarks?homeData.length>0?<View>
                        <View style={{marginTop: 10}}>
                            <Text style={{
                                fontSize: windowWidth>600?20: 18,
                                fontWeight: 'bold',
                                fontFamily: 'roboto-bold',
                                color:darkTheme?'#f3f3f3':'#121212',
                                marginBottom: 20}}>
                                Bookmarks
                            </Text>
                        </View>
                        <View style={{
                            width:'100%',
                            backgroundColor:darkTheme?'#12222F':'#ffffff',
                            paddingHorizontal: 5,marginTop: 10,
                            borderRadius: 10,borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                        }}>
                            <HomeSectionList navigation={navigation} homeData={homeData}/>
                        </View>
                    </View>:
                    isLoading && <View style={{
                        marginTop:'20%',marginBottom: 20,
                        width: '100%',alignSelf:'center',alignContent:'center',
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <Text>Fetching...</Text>
                    </View>: <View
                    style={{
                        marginTop:'20%',marginBottom: 20,
                        width: '100%',alignSelf:'center',alignContent:'center',
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <Text> Haven't Saved anything yet </Text>
                    </View>}
                </View>
            </View>
        </ScrollView>
    )
}