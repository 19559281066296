export const guideList = [
    {
        title: 'Read Stories',
        image: 0,
        description:'There are numerous available stories to read.' +
        ' They are of different topics and genres.'
    },
    {
        title: 'Create Stories',
        image: 1,
        description:'Sign Up with us to tell your stories' +
        'and reach out to many, inspire and educate people allover.'
    }
]