import * as React from "react"
import { encryptData, decryptData } from '../../utils/encryptionUtils'
import { AdminAuthContextProps } from "./admin-auth-interface"
import { adminClearData, adminReadFromFile, adminSaveToFile } from "./admin-file-manager"



export const AdminAuthContext = React.createContext<AdminAuthContextProps | undefined>(undefined)


export const useAdminAuthentication = () => {
    return React.useContext(AdminAuthContext)
}

export const AdminAuthProvider = ({children}:React.PropsWithChildren):JSX.Element=>{
    const [authenticatedUser, setAuthenticatedUser] = React.useState(null)

    const adminLogin = (response) => {
        console.log('Start...')
        const encryptedData = encryptData({
            token: response.data.token,
            username: response.data.username,
            email: response.data.email,
            description: response.data.description,
            profile: response.data.profile,
            links: response.data.links,
        })
        // Save the encrypted data to a file
        adminSaveToFile(encryptedData)

        // Set the decrypted user data in the state
        setAuthenticatedUser(decryptData(encryptedData))
        console.log('Finish...')
    }

    const logout = async () => {
        // Remove the encrypted data from state
        adminClearData()
        setAuthenticatedUser(null)
    }

    const loadAdminDataFromFile = async () => {
        const fileContent = adminReadFromFile()
        if (fileContent){
            const decryptedData = decryptData(fileContent)
            setAuthenticatedUser(decryptedData)
            return decryptedData
        }
    }

    React.useEffect(() => {
        loadAdminDataFromFile();
    }, [])

    return <AdminAuthContext.Provider value={{ auth: authenticatedUser, adminLogin, logout, loadAdminDataFromFile }}>
        {children}
    </AdminAuthContext.Provider>
}
