import * as React from 'react'
import {
    View,TouchableOpacity,
    Text,
    useWindowDimensions,
} from 'react-native'
import { NewMultipleStyle } from '../utils/app-styles'
import { NewStoryContext } from '../utils/new-blog-context'
import axios from 'axios'
import dataHandler from '../utils/form-data-handler'
import { baseUrl } from '../utils/urls'
import { useAuthentication } from '../utils/auth-context'


function StoryControls({title, content,navigation,toDelete,
    selectedTopic,darkTheme,setNot,isStatus,blog_id
}):JSX.Element{
    // initializing the styles
    const newMultipleStyle = NewMultipleStyle()
    const {auth} = useAuthentication()
    // Get the context and setter function from the context
    const { setNewBlog } = React.useContext(NewStoryContext)
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const [isUploading,setIsUploading] = React.useState(false)

    const deleteBlog= ()=>{
        if(title !==''||content.length !==0){
            setNewBlog({})
            setNot({message:'Deleted successfully',level:'success'})
        }
        setNot({message:'No Content yet',level:'success'})
    }


    const previewOnPress = async ()=>{
        if(title!=='' && content.length !==0){
            setIsUploading(true)
            const {data,imagesForm,imagesMetadata} = await dataHandler({
                title:title,content:content,topic:selectedTopic
            })
            const url = isStatus==='create'?`${baseUrl}create/`: `${baseUrl}update_blog/`

            isStatus === 'create' ? await axios.post(`${url}`,data,
            {
                headers:{
                    Authorization: `Token ${auth.token}`,
                },
            }
            )
            .then((response)=>{
                setNot({message:'Uploading Images...',level:'success'})
                uploadImages(response.data.id,imagesForm,null)
            })
            .catch((err)=>{
                setNot({message:`Experienced an error while uploading: ${err.message}`,level:'warning'})
            }): await axios.put(`${url}${blog_id}/`,data,
            {
                headers:{
                    Authorization: `Token ${auth.token}`,
                },
            }
            )
            .then((response)=>{
                setNot({message:'Uploading Images...',level:'success'})
                uploadImages(response.data.id,imagesForm,imagesMetadata)
            })
            .catch((err)=>{
                setNot({message:`Experienced an error while uploading: ${err.message}`,level:'warning'})
            })
        }
        else{
            alert('Add all Entries')
        }
    }

    const uploadImages = async(id:any,imagesForm:any,imagesMetadata)=>{
        isStatus==='create'? await axios.post(`${baseUrl}upload_image/${id}/`,imagesForm,
            {
                headers:{
                    Authorization: `Token ${auth.token}`,
                },
            }
            )
            .then((response)=>{
                setNot({message:'Successfully created Blog',level:'success'})
                window.location.reload()
            })
            .catch((err)=>{
                setNot({message:`Error uploading images: ${err.message}`,level:'warning'})
            }):
            await axios.put(`${baseUrl}update_image/${id}/`,imagesForm,
            {
                headers:{
                    Authorization: `Token ${auth.token}`,
                },
            }
            )
            .then((response)=>{
                uploadMetadata(imagesMetadata)
                setNot({message:'Finalizing...',level:'success'})
                deleteImage(blog_id)
            })
            .catch((err)=>{
                setNot({message:`Error uploading images: ${err.message}`,level:'warning'})
            })
    }

    const uploadMetadata = async(data:any)=>{
        await axios.put(`${baseUrl}update_metadata/`,data,
        {
            headers:{
                Authorization: `Token ${auth.token}`,
            },
        }
        )
        .then((response)=>{
            setNot({message:'Progressing...',level:'success'})
        })
        .catch((err)=>{
            setNot({message:`Experienced an error while uploading: ${err.message}`,level:'warning'})
        })
    }

    const deleteImage=async(b_id)=>{
        const deleteForm = new FormData()
        if(toDelete!==null && toDelete.length>0){
            toDelete.forEach(id => {
                deleteForm.append('id', id)
            })
        }
        axios.put(`${baseUrl}delete_images/${b_id}/`,deleteForm,{
            headers:{
                Authorization: `Token ${auth.token}`,
            },
        })
        .then((response)=>{
            setNot({message:'Successfully updated Blog',level:'success'})
            navigation.replace('Profile')
            setIsUploading(false)
        }).catch((err)=>{
            setNot({message:`Experienced an error while uploading: ${err.message}`,level:'warning'})
            setIsUploading(false)
        })
    }

    return(
        <View style={{
        alignItems:'flex-end',
        justifyContent:'flex-end',
        width: isNarrowScreen?'50%':'90%'
        }}>
            <View style={{  flexDirection: 'row',
            width: isNarrowScreen?windowWidth*.3:windowWidth*.2,
            padding:7,
            alignItems: 'center',
            justifyContent:'center'
            }}>
                <TouchableOpacity
                style={{
                    alignItems:'center',paddingHorizontal: 10, backgroundColor:'#f3f3f3',
                    borderRadius: 5,paddingVertical:5,
                }}
                onPress={()=>isUploading===false && previewOnPress()}
                    >
                    <Text
                    style={[newMultipleStyle.btnText,{fontSize:isNarrowScreen?14:16,fontWeight:'500'}]}>
                        {isUploading?'Uploading...':'Publish'}
                    </Text>
                </TouchableOpacity>
                <View style={{ marginHorizontal: 10,width:1,height: 30,backgroundColor:'#E3E1E1' }} />
                <TouchableOpacity
                style={{
                    alignItems:'center',paddingHorizontal: 10, backgroundColor:'#f3f3f3',
                    paddingVertical:5,
                    borderRadius: 5
                }}
                onPress={deleteBlog}
                >
                    <Text style={[newMultipleStyle.btnText,{fontSize:isNarrowScreen?14:16,fontWeight:'500'}]}>Discard</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default StoryControls