import DateTimeFormatter from "./date-time-util-formatter"

export function timeAgo(date:string):string{
    const now = new Date()
    const diffInMs = now.getTime() - new Date(date).getTime()
    const diffInSecs = Math.floor(diffInMs/1000)
    const diffInMins = Math.floor(diffInSecs/60)
    const diffInHours = Math.floor(diffInMins/60)
    const diffInDays = Math.floor(diffInHours/24)
    const diffInWeeks = Math.floor(diffInDays/7)
    const dateObject = new Date(date)
    const {day,month,year} = DateTimeFormatter(dateObject)

    if(diffInSecs<60){
        return '. '+ diffInSecs + ' s'
    }else if(diffInMins<60){
        return '. '+ diffInMins + ' min'
    }else if(diffInHours<24){
        return '. '+ diffInHours + ' h'
    }else if(diffInDays===1){
        return '. Yesterday'
    }else if(diffInWeeks <=0 ){
        return '. This Week'
    }else{
        return `.  ${month} ${day}, ${year} `
    }
}