//describes the title, image and content paragraphs layout visible to the customers.
//styling is described below the component.
import * as React from 'react'
import {View, Text,Image, TouchableOpacity} from 'react-native'
import { BlogContentStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import { baseUrlImage } from '../utils/urls'
import { timeAgo } from '../utils/time-ago'
import DateTimeFormatter from '../utils/date-time-util-formatter'
import Hyperlink from 'react-native-hyperlink'
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import { LinearGradients } from './custom-components/gradient-applier'
import * as Linking from 'expo-linking'
import { formatBlogText } from '../utils/text-formatter'



function TimeStamp({blog}){
    //date management
    const dateObject = timeAgo(blog.created_at)
    const blogContentStyle = BlogContentStyle()
    const {hours,minutes} = DateTimeFormatter(new Date(blog.created_at))
    // converts story object to string
    const content = blog?.content

    return(
        <View style={blogContentStyle.timeStampWrapper}>
            <View style={{ flexDirection: 'row' }}>
                <Text style={blogContentStyle.dateText}>{`Posted ${dateObject}`}</Text>
                <Text style={blogContentStyle.timeText}>{` . ${hours} : ${minutes} ${hours>11?'pm': 'am'} `}</Text>
            </View>
            <Text style={{ ...blogContentStyle.dateText,fontWeight: 'bold' }}>
                {/*calculates approximate duration able to read the blog*/}
                {`.   ${Math.ceil(content[0].story.length/1500*content.length)} min Read`}
            </Text>
        </View>
    )
}

function BioContent({blog}){
    const blogContentStyle = BlogContentStyle()
    return <View style={{ width: '90%',flexWrap:'wrap' }}>
        <Text style = {blogContentStyle.editorText}>
                {`Written by ${blog?.author?.name}`}
        </Text>
        <View >
            <Text style={blogContentStyle.contentText}>
                {`${blog?.author?.description}`}
            </Text>
        </View>
    </View>
}


function Blog({blog}){
    const {darkTheme} = useTheme()

    const blogContentStyle = BlogContentStyle()

    // converts story object to string
    const selectedImages = blog?.contentImages
    const mergedContent = []
    // converts story object to string
    const content = blog?.content
    const links = JSON.parse(blog?.author.links) ||[]

    if (content && selectedImages) {
        const combinedData = [...content, ...selectedImages]
        combinedData.sort((a, b) => {
            return a.index - b.index
        })
        for (let i = 0; i < combinedData.length; i++) {
            mergedContent.push(combinedData[i])
        }
    }

    const renderIcon = (type:string,url) => {
        switch (type) {
            case 'Instagram':
                return (
                    <View style={{ flexDirection:'row',flexWrap:'wrap' }}>
                        <LinearGradients
                        iconSize={22}
                        styles={{ borderRadius: 6,alignItems:'center',justifyContent:'center',marginRight:5}}
                            colors={['#405DE6', '#5851DB', '#833AB4', '#C13584', '#E1306C', '#FD1D1D']}
                        >
                            <Feather name="instagram" color="#ffffff" size={22} />
                        </LinearGradients>
                        <Text style={{ color:'#1A8CF1' }}>{url}</Text>
                    </View>
                )
            case 'X':
                return <View style={{ flexDirection:'row',flexWrap:'wrap' }}>
                    <Feather name="twitter" color="#1A8CF1" size={22} style={{ marginRight:5 }} />
                    <Text style={{ color:'#1A8CF1' }}>{url}</Text>
                </View>
            default:
                return <View style={{ flexDirection:'row',flexWrap:'wrap' }}>
                    <MaterialCommunityIcons name="web" color="#1A8CF1" size={22} style={{ marginRight:5 }} />
                    <Text style={{ color:'#1A8CF1' }}>{url}</Text>
                </View>
        }
    }

    // rendered component
    return(
        <View style={{ width: '95%'}}>
            <View key={blog.id}style={{ width: '100%' }} >
                {/* Upper Wrapper */}
                <View style={blogContentStyle.upperWrapper}>
                    {/* title section */}
                    <View style={{
                        width: '100%',
                        alignItems: 'flex-start',
                        marginTop: 20,
                        marginBottom: 20
                        }}>
                            <Text style={blogContentStyle.title}>
                                {blog.title}
                            </Text>
                    </View>
                    {blog.author?.profile && <View style={{ flexDirection:'row',flexWrap:'wrap',alignItems:'center' }}>
                        <Image source={{uri:`${baseUrlImage}${blog.author?.profile}`}}
                        style={{ width: 40, height:40,borderRadius:20 }}
                        />
                        <View style={{ paddingTop: 5,paddingBottom: 10 ,paddingLeft: 10}}>
                            <Text style = {blogContentStyle.editorText}>
                                {blog.author.name}
                            </Text>
                        </View>
                    </View>}
                    {/* links of Author */}
                    <View style={{
                        flexDirection:'row',flexWrap:'wrap',
                        paddingLeft: 10,paddingVertical:5,marginLeft:30,
                        width:'100%'
                    }}>
                        {
                            links.map((item:{type:string,url:string},index:number)=>{
                                return <TouchableOpacity
                                style={{ marginHorizontal: 5 }}
                                key={index}
                                onPress={()=> Linking.openURL(item.url)}
                                >
                                    {renderIcon(item.type,item.url)}
                                </TouchableOpacity>
                            })
                        }
                    </View>
                    {/* timestamp of story */}
                    <TimeStamp blog={blog}/>
                </View>
                {/* Lower Wrapper */}
                <View style={blogContentStyle.lowerWrapper}>
                        {mergedContent.map((item, index) => (
                            <View key={index} style={{
                                marginBottom: 10
                            }}>
                                {item.type === 'subtitle' && (
                                        <Hyperlink linkDefault={ true }>
                                            <Text key={index} style={blogContentStyle.subTitleText}>
                                                {item.story}
                                            </Text>
                                        </Hyperlink>
                                )}
                                {item.type === 'paragraph' && (
                                    <Hyperlink linkDefault={ true } linkStyle={{ color:'#1A8CF1' }}>
                                        <Text key={index} style={blogContentStyle.contentText}>
                                            {formatBlogText(item.story)}
                                        </Text>
                                    </Hyperlink>
                                )}
                                {item.type === 'image' &&  (
                                    <View style={blogContentStyle.imgContentWrapper}>
                                        <Image
                                        source={{ uri:`${baseUrlImage}${item.media}` }}
                                        style={blogContentStyle.img}
                                        resizeMethod='auto'
                                        resizeMode='contain'
                                        />
                                    </View>
                                )}
                            </View>
                        ))}
                    {/* choices and other reaction sections */}
                    {/* legal acknowledgement text */}
                    <View style={{
                        marginVertical: 10 ,alignItems:'flex-start',alignContent:'flex-start',
                        alignSelf:'flex-start'
                        }} >
                        {/* Bio section */}
                        <BioContent blog={blog}/>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Blog