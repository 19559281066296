import * as React from 'react'
import {View,useWindowDimensions,Text,
    TouchableOpacity, ScrollView} from 'react-native'
import { HomeStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import TopicButton from '../components/topic-button'
import { BlogStory } from '../utils/story-data'
import axios from 'axios'
import { baseUrl } from '../utils/urls'
import { filteredBlogsSetter } from '../utils/story-interface-setter'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { BlogsContext } from '../utils/blog-context'
import { TrendBar } from '../components/trend-bar'
import { HomeSectionList } from '../components/section-list-home'
import { Contacts } from '../components/contacts'
import { NavigationButton } from '../components/nav-button'


const FilterBlogs = ({route,navigation}) => {
    const {
        darkTheme,searchTopics
    } = useTheme()
    const {topic} = route.params
    const homeStyle = HomeStyle()
    const [homeData,setHomeData] = React.useState([])
    const [isFiltered,setIsFiltered] = React.useState(false)
    const {trending} = React.useContext(BlogsContext)
    const [filteredData,setFilteredData] = React.useState<BlogStory[]>([])
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    React.useEffect(()=>{
        const fetchTopicBlogs = async () => {
            try {
                const response = await axios.get(`${baseUrl}search/${topic}/`)
                setFilteredData(filteredBlogsSetter(response.data))
                setHomeData([{ title: 'Searched Blogs', data: filteredBlogsSetter(response.data) }])
                setIsFiltered(true)
                // setNotification({message:`filter for ${topic}`,level:'success'})
            } catch (error) {
                setNotification({message:'Error while handling request',level:'warning'})
            }
        }
        fetchTopicBlogs()
    },[topic])

    return(
        <View style={{
            backgroundColor:darkTheme?'#121212':'#f3f3f3',
            flexShrink:1,
            justifyContent:'center',
            flexGrow: 1,
            flexDirection: 'row',
            width:'100%',
            height: '100%',
            flex: 1
        }}>
            {!isNarrowScreen && <View
                // @ts-ignore
                style={{
                    width: isNarrowScreen ? '100%' : '15%',alignSelf: 'flex-start',
                    marginHorizontal:!isNarrowScreen && 10,
                    position: !isNarrowScreen && 'sticky',
                    top: !isNarrowScreen &&10,
                    zIndex: !isNarrowScreen &&100
            }}>
                <TrendBar navigation={navigation} isNarrowScreen={isNarrowScreen}
                isFilter={false} trending={trending} darkTheme={darkTheme}
                />
            </View>}
            <View style={{
                width: isNarrowScreen ? '100%' : '50%',
            }}>
                {notification.level !=='' && <NotificationPanel notification={notification}/>}
                <ScrollView
                showsVerticalScrollIndicator={false}
                >
                    {
                        <View style={{
                            width: '100%', flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                            justifyContent:isNarrowScreen?'center':
                            'flex-start',alignSelf:isNarrowScreen?'center':'flex-start'
                        }} >
                            <NavigationButton navItem={'Home'} onPress={()=> navigation.reset({
                                index: 0,
                                routes: [{ name: 'Home' }],
                            })}
                            index={0}
                            />
                            <NavigationButton navItem={'Latest'} onPress={()=> navigation.navigate('Latest')}
                                index={1}
                            />
                        </View>
                    }
                    {
                        isNarrowScreen && <TrendBar navigation={navigation} isNarrowScreen={isNarrowScreen}
                        isFilter={false} trending={trending} darkTheme={darkTheme}
                        />
                    }
                    <View style={{
                        width:'100%',
                        backgroundColor:darkTheme?'#12222F':'#ffffff',
                        paddingHorizontal: 5,marginTop: 1,
                        borderRadius: 10
                    }}>
                        <HomeSectionList navigation={navigation} homeData={homeData}/>
                    </View>
                </ScrollView>
            </View>
            {!isNarrowScreen && <View style={{
                marginBottom: 10,marginTop: 10,
                marginLeft:20,borderRadius:10,
                width:'30%', flexDirection:'column',
                backgroundColor:darkTheme?'#12222F':'#ffffff',
                paddingHorizontal: 10, alignSelf:'flex-start'
            }}>
                <View style={{paddingVertical: 10,paddingLeft: 10}}>
                    <Text style={homeStyle.listedText}>Topics</Text>
                </View>
                <View style={homeStyle.topicsWrapper}>
                    <View style={{ flexWrap:'wrap',flexDirection: 'row'}} >
                        {searchTopics.map((topic: any,index: React.Key)=>(
                            <TouchableOpacity key={index}
                            onPress={()=> navigation.navigate('FilterBlogs',{topic:topic})}
                            >
                                <TopicButton topic={topic}/>
                            </TouchableOpacity>)
                        )}
                    </View>
                </View>
                <View>
                    <Contacts navigation={navigation}/>
                </View>
            </View>}
        </View>
    )
}

export default FilterBlogs
