import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as React from 'react'
import { FlatList, TextInput, View,Text, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useTheme } from '../../utils/state-context'
import { SearchBarStyle } from '../../utils/app-styles'
import { AllBlogsContext } from '../../utils/all-blogs-context'
import { timeAgo } from '../../utils/time-ago'
import { ScrollView } from 'react-native'


export const SearchBar=({navigation}): JSX.Element=>{
    const {darkTheme} = useTheme()
    const searchStyle = SearchBarStyle()
    const [searchQuery, setSearchQuery] = React.useState('')
    const[filteredBlogs,setFilteredBlogs] = React.useState([])
    const [showSearchResults, setShowSearchResults] = React.useState(false)
    const {allBlogs} = React.useContext(AllBlogsContext)
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800

    const handleSearch = (searchText: string) => {

        setSearchQuery(searchText)
        if (searchText.trim() === '') {
            // If the search query is empty, hide the search results
            setFilteredBlogs([])
            setShowSearchResults(false)
        } else {
            // Filter blogs based on title or topic
            const filteredBlogs = allBlogs.filter(
                (blog) =>
                blog.title.toLowerCase().includes(searchText.toLowerCase())
            )
            if(filteredBlogs.length>5){
                setFilteredBlogs(filteredBlogs.slice(0,5))
            }
            else{
            // Pass the filtered blogs to your component or state
                setFilteredBlogs(filteredBlogs)
            }
            setShowSearchResults(true)
        }
    }
    return <View style={{
        flexDirection: 'row',
        alignContent: 'center',
        alignItems:'center',
        alignSelf: 'center',
        padding: 5,
        marginHorizontal: 5,
        height: 60,
        }}>
        <View style={{
            borderRadius: 5,
            borderWidth: 0,
            backgroundColor: darkTheme?'#121212':'#F0F0F0',
            minWidth: windowWidth *.3,
            height: 50,flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal:5,
            paddingVertical: 5,
            paddingHorizontal: 10,
        }}>
            <MaterialCommunityIcons name='magnify' size={24} color={darkTheme?'#ffffff':'#141414'}/>
            <TextInput
                inlineImageLeft='search_icon'
                style={searchStyle.inputField}
                underlineColorAndroid={'transparent'}
                placeholder='Search Title'
                placeholderTextColor={darkTheme?'#f3f3f3':'#666'}
                value={searchQuery}
                onChangeText = {(search)=> handleSearch(search)}
                onFocus={() => setShowSearchResults(true)}
                onBlur={() => setSearchQuery('')}
            />
        </View>
        {/* Display search results here with absolute positioning */}
        {filteredBlogs.length > 0 &&showSearchResults && (
            <View
            style={{
                position: 'absolute',
                top: 60,
                left: 0,
                right: 0,
                backgroundColor: darkTheme?'#12222F':'#ffffff', // Set background color as needed
                zIndex: 1, // Ensure it's above other content
                elevation: 5, // Adjust elevation as needed
                padding: 10,
                borderRadius: 10,
                paddingLeft: 20,borderWidth: 1,
                borderColor:darkTheme?'#545454':'#CDCCCC'
            }}
            >
                <ScrollView>
                    <FlatList
                        data={filteredBlogs}
                        keyExtractor={(item) => item.id.toString()} // Adjust the keyExtractor based on your data
                        renderItem={({ item }) => (
                            <TouchableOpacity onPress={()=>{
                                setSearchQuery('')
                                setFilteredBlogs([])
                                navigation.navigate('BlogRead',{id:item.id})
                            }}>
                                <View style={{ marginBottom: 10,marginTop:5 }}>
                                    <Text numberOfLines={2} ellipsizeMode="tail"
                                    style={{
                                        fontSize: 16,fontWeight:'bold',fontFamily:'roboto-medium',
                                        color: darkTheme?'#ffffff':'#121212'
                                    }}
                                    >{item.title}</Text>
                                    <View style={{ flexDirection:'row' }}>
                                        <Text numberOfLines={1} ellipsizeMode="tail"
                                        style={{
                                            fontSize: 13,
                                            color: darkTheme?'#ffffff':'#6B6B6B'
                                        }}
                                        >{item.author.name}</Text>
                                        <Text numberOfLines={1} ellipsizeMode="tail"
                                        style={{
                                            fontSize: 13,
                                            color: darkTheme?'#ffffff':'#121212',
                                            marginLeft: 5
                                        }}
                                        >{timeAgo(item.created_at)}</Text>
                                    </View>
                                    {item.content.length>0&&<Text style={{
                                        fontWeight: '400', marginTop: 5,
                                        fontSize: 13,
                                        color: darkTheme?'#ffffff':'#121212',
                                        }}>
                                        {`${Math.ceil(item.content[0].story.length/1500*item.content.length)} min Read`}
                                    </Text>}
                                </View>
                            </TouchableOpacity>
                        )}
                    />
                </ScrollView>
            </View>
        )}
    </View>
}