import * as React from 'react'
import { View, Text, TouchableOpacity,
    StyleSheet, useWindowDimensions } from 'react-native'
import { useTheme } from '../utils/state-context'
import { useAuthentication } from '../utils/auth-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import WriteRequestModal from './modal-components/request-write-modal'
import axios from 'axios'
import { baseUrl } from '../utils/urls'
import FormData from 'form-data'

export function HomeBanner({navigation}){
    const{auth} = useAuthentication()
    const {width: windowWidth} = useWindowDimensions()
    const {darkTheme} = useTheme()
    const [request,setRequest]= React.useState(false)
    const isNarrowScreen = windowWidth < 800
    const [modalVisible, setModalVisible] = React.useState(false)

    const handleRequest = () => {
        // Set modalVisible to true to show the delete confirmation modal
        setModalVisible(true)
    }

    const handleCancel = () => {
        // Handle cancel action and hide the modal
        setModalVisible(false)
    }

    const sendRequest=async()=>{
        const form = new FormData()
        await axios.post(`${baseUrl}send-request/`,form,{
            headers:{
                Authorization: `Token ${auth.token}`,
            }
        }).then((response)=>{
            setRequest(true)
            console.log(response)
        }).catch((error)=>{
            console.log(error.message)
        })
    }

    const icons = ['bookmark-multiple-outline','chat-outline']
    const iconsWriter = ['view-dashboard-outline','square-edit-outline']

    return (
        <View style={styles.container}>
            <View style={[styles.content,{width: windowWidth <900?'100%':'50%'}]}>
                {auth ===null?<View style={{
                    width: '100%',padding: auth?.is_Writer?0:10,alignSelf: 'center',justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Text style={[styles.boldText,{fontSize: isNarrowScreen? 18:24}]}>Join us Today</Text>
                    <View style={{
                        width: '100%',paddingHorizontal: 10,alignSelf: 'center',justifyContent:'center',
                        alignItems:'center',paddingVertical:5,
                    }}>
                        <Text style={[styles.text,{fontSize: isNarrowScreen? 14:16}]}>Make the First step</Text>
                        <TouchableOpacity onPress={
                                ()=>{
                                    navigation.navigate('SignIn')
                                }
                        } style={styles.confirmButton}>
                            <Text style={[styles.buttonText,{fontSize: isNarrowScreen? 12:14,}]}>
                                Sign In
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>:auth?.is_Writer?null:
                <View style={{
                    width: '100%',padding: 10,alignSelf: 'center',justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Text style={[styles.boldText,{fontSize: isNarrowScreen? 18:24}]}>Want to be a writer</Text>
                    <View style={{
                        width: '100%',padding: 10,alignSelf: 'center',justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <Text style={[styles.text,{fontSize: isNarrowScreen? 14:16}]}>Try out!</Text>
                        <TouchableOpacity onPress={handleRequest} style={styles.confirmButton}>
                            <Text style={[styles.buttonText,{fontSize: isNarrowScreen? 12:14,}]}>
                                Request
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
                }
            </View>
            <View style={[styles.content,{width: windowWidth <900?'100%':'50%'}]}>
                {auth && !auth?.is_Writer?iconsWriter.map((item:string,index:number)=>{
                        return <View style={{
                            margin: 5,width:'100%',flexDirection:'row',
                            paddingVertical: 5,alignItems:'center',paddingHorizontal: 10
                            }} key={index}>
                            <View style={styles.icons}>
                                <MaterialCommunityIcons // @ts-ignore
                                name={`${item}`}
                                size={isNarrowScreen?20: 24} color={
                                    '#ffffff'
                                } />
                            </View>
                            <Text style={[styles.text,{ width: '80%',
                                flexWrap:'wrap',color: '#1A8CF1',
                            }]}>
                                {item==='square-edit-outline'?'Write stories':
                                'Fully control Your Stories and get Feedback'}
                            </Text>
                        </View>
                    }):
                    icons.map((item:string,index:number)=>{
                        return <View style={{
                            margin: 5,width:'100%',flexDirection:'row',
                            paddingVertical: 5,alignItems:'center',paddingHorizontal: 10
                            }}  key={index}>
                            <View style={styles.icons}>
                                <MaterialCommunityIcons // @ts-ignore
                                name={`${item}`}
                                size={isNarrowScreen?20: 24} color={
                                    '#ffffff'
                                } />
                            </View>
                            <Text style={[styles.text,{ width: '80%',
                                flexWrap:'wrap',color: '#1A8CF1',
                            }]}>
                                {item==='bookmark-multiple-outline'?
                                'Save stories to read later':
                                'Fully control Your Comments'}
                            </Text>
                        </View>
                    })
                }
                <WriteRequestModal navigation={navigation} onCancel={handleCancel}
                visible={modalVisible} sendRequest={sendRequest} request={request}
                />
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#EDF2F6',
        flexDirection:'row',flexWrap:'wrap',
        marginTop: 5,
        borderRadius:5,
        padding: 5,
    },
    content: {
        borderRadius: 5,
        justifyContent: 'center',
        flexDirection:'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding:5,
    },
    text: {
        fontWeight:'bold',
        alignContent:'center',
        fontFamily:'roboto-bold',
    },
    icons:{
        marginRight: 5,backgroundColor:'#1A8CF1', borderRadius:5,
        // width: '20%'
        padding: 5
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,marginTop: 10
    },
    cancelButton: {
        backgroundColor: 'red',
        padding:2,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent:'flex-start'
    },
    confirmButton: {
        backgroundColor: '#1A8CF1',
        paddingHorizontal: 10,paddingVertical: 5,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center',
        marginBottom: 5,marginTop: 10
    },
    buttonText: {
        color: 'white',
        fontWeight:'bold',
        fontFamily:'roboto-bold'
    },
    boldText:{
        fontWeight:'bold',
        fontFamily:'roboto-bold',
        color: '#1A8CF1',
    }
})