import * as React from 'react'
import {View,Text,useWindowDimensions,
    TouchableOpacity} from 'react-native'
import { BlogReadStyle, HomeStyle } from '../utils/app-styles'
import { useTheme } from '../utils/state-context'
import TopicButton from '../components/topic-button'
import { BlogsContext } from '../utils/blog-context'
import { AllBlogsContext } from '../utils/all-blogs-context'
import { SubscriptionSection } from '../components/subscription-component'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { SubButton } from '../components/subscribe-button'
import { TrendBar } from '../components/trend-bar'
import { HomeSectionList } from '../components/section-list-home'
import { Contacts } from '../components/contacts'
import CircularProgressIndicator from '../components/custom-components/progress-indicator'
import { CustomPullToRefresh } from '../components/refresher'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NavigationButton } from '../components/nav-button'
import { HomeBanner } from '../components/home-banner'
import { useAuthentication } from '../utils/auth-context'

const Home = ({navigation}) => {
    const{auth} = useAuthentication()
    const {
        darkTheme,topics
    } = useTheme()
    const homeStyle = HomeStyle()
    const blogReadStyle = BlogReadStyle()
    const [selectTopic,setSelectTopic] = React.useState(false)
    const {popular,refreshLatestBlogs,trending} = React.useContext(BlogsContext)
    const {allBlogs,refreshAllBlogs} = React.useContext(AllBlogsContext)
    const [homeData,setHomeData] = React.useState([])
    //progress setter
    const [progress, setProgress] = React.useState(0)
    const [subscribe,setSubscribe] = React.useState(false)
    const[notification,setNotification] = React.useState({
        message:'', level:''
    })
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    //when pull to refresh is invoked
    const onRefresh = React.useCallback((animationCompleteCallback:any) => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress + 1) % 101)
        }, 30)
        // Calling the refresh functions from the contexts
        Promise.all([refreshLatestBlogs(), refreshAllBlogs()])
            .then(() => {
                setHomeData([
                    { title: 'Popular', data: [{ rowData: popular }] },
                    { title: 'All Blogs', data: allBlogs },
                ])
            })
            .catch((error) => {
                // Handle error if necessary
                setNotification({message:`Experienced error: ${error.message}`,level:'warning'})
            })
            .finally(() => {
                clearInterval(interval)
                animationCompleteCallback()
            })
    }, [refreshAllBlogs, refreshLatestBlogs])

    React.useEffect(()=>{
        // Variable to track if fast forward is needed
        let shouldFastForward = false
        // Set up the interval for progress update
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
            // Check if fast forward is needed
            if (shouldFastForward) {
                clearInterval(interval) // Stop the regular progress update interval
                return 100 // Set progress to 100%
            }
            // Continue regular progress update
            return (prevProgress + 5) % 101
            })
        }, 30)
        // Set up the homeData after 2 seconds (adjust as needed)
        const timeout = setTimeout(() => {
            setHomeData([{ title: 'Popular', data: [{ rowData: popular }] }, { title: 'All Blogs', data: allBlogs }])
            shouldFastForward = true // Set the flag to trigger fast forward
        }, 2000)
        // Clear intervals and timeouts on component unmount or dependencies change
        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
    },[allBlogs,popular])

    return(
        <CustomPullToRefresh onRefresh={onRefresh}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            <View style={{
                flexDirection: 'row',
                width:'100%',marginBottom: 10,
                paddingHorizontal: isNarrowScreen?5:10,
                justifyContent: 'space-around'
            }}>
                {!isNarrowScreen && <View
                // @ts-ignore
                style={{
                    width: isNarrowScreen ? '100%' : '15%',alignSelf: 'flex-start',
                    marginHorizontal:!isNarrowScreen && 10,
                    position: !isNarrowScreen && 'sticky',
                    top: !isNarrowScreen &&10,
                    zIndex: !isNarrowScreen &&100
                }}>
                    <View style={{paddingVertical: 10,paddingLeft: 10}}>
                        <Text style={homeStyle.listedText}>Top Trending</Text>
                    </View>
                    <TrendBar navigation={navigation} isNarrowScreen={isNarrowScreen}
                    isFilter={false} trending={trending} darkTheme={darkTheme}
                    />
                </View>}
                <View style={{
                    width: isNarrowScreen ? '100%' : '50%',alignSelf: 'flex-start'
                }}>
                    {
                        <View style={{
                            width: '100%', flexDirection:'row',flexWrap:'wrap',alignItems:'center',
                            justifyContent:isNarrowScreen?'center':
                            'flex-start',alignSelf:isNarrowScreen?'center':'flex-start'
                        }} >
                            <NavigationButton navItem={'BlogsHome'} onPress={()=> navigation.reset({
                                index: 0,
                                routes: [{ name: 'Home' }],
                            })}
                            index={0}
                            />
                            <NavigationButton navItem={'Latest'} onPress={()=> navigation.navigate('Latest')}
                                index={1}
                            />
                        </View>
                    }
                    {
                        isNarrowScreen && <TrendBar navigation={navigation} isNarrowScreen={isNarrowScreen}
                        isFilter={false} trending={trending} darkTheme={darkTheme}
                        />
                    }
                    {homeData.length!==0 ?<View style={{
                        width:'100%',
                        backgroundColor:darkTheme?'#12222F':'#ffffff',
                        paddingHorizontal: 5,marginTop: 1,
                        borderRadius: 10,borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                    }}>
                        <HomeSectionList navigation={navigation}
                        homeData={homeData}/>
                    </View>:<View style={{
                        marginTop:'20%',marginBottom: 20,
                        width: '100%',alignSelf:'center',alignContent:'center',
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <CircularProgressIndicator
                        radius={25}
                        strokeWidth={1}
                        progress={progress}
                    />
                    </View>}
                </View>
                {isNarrowScreen ? null :
                <View
                style={[{
                    width: '30%',
                    alignSelf: 'flex-start',
                },
                !auth && (!selectTopic && !subscribe) && blogReadStyle.sidePositioning
                ]}>
                    <View style={{paddingVertical: 10,paddingLeft: 10}}>
                        <Text style={homeStyle.listedText}>Other Content</Text>
                    </View>
                    <View
                    style={{
                        marginTop: 5,marginLeft:10,borderRadius:10,
                        paddingTop:5,alignSelf: 'flex-start',
                        width:'90%', flexDirection:'column',
                        backgroundColor:darkTheme?'#12222F':'#ffffff',
                        paddingHorizontal: 10,borderColor:darkTheme?'#454545':'#EDEAEA',borderWidth:1.5,
                    }}>
                        {auth && auth?.is_Writer?null: auth && <View>
                            <HomeBanner navigation={navigation}/>
                            {/* <View style={{
                                height:1, backgroundColor:darkTheme?'#454545':'#EDEAEA',
                                marginBottom: 10
                            }}/> */}
                        </View>}
                        {selectTopic?
                        <TouchableOpacity onPress={()=>setSelectTopic(!selectTopic)}
                        style={{
                            borderWidth: 1.5,
                            borderColor: darkTheme?'#f3f3f3':'#141414',
                            borderRadius: 11,
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                            height: 22,
                            width:22,
                            alignSelf:'center',
                            marginVertical: 20
                        }}
                        >
                            <MaterialCommunityIcons name='close'
                            style={{ fontWeight: 'bold',color:darkTheme?'#f3f3f3':'#121212' }}
                            />
                        </TouchableOpacity>
                        :<View>
                            <TouchableOpacity onPress={()=>setSelectTopic(!selectTopic)}
                            style={{
                                borderWidth: 1.5,
                                borderColor: darkTheme?'#f3f3f3':'#141414',
                                borderRadius: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 7,
                                paddingVertical: 5,
                                alignSelf:'center',
                                marginVertical: 20
                            }}
                            >
                                <Text
                                style={{ fontSize: 16, fontWeight: 'bold',color:darkTheme?'#f3f3f3':'#121212' }}
                                >Other Topics</Text>
                            </TouchableOpacity>
                        </View>}
                        {selectTopic && <View>
                            <View style={{paddingVertical: 10,paddingLeft: 10}}>
                                <Text style={homeStyle.listedText}>Topics</Text>
                            </View>
                            <View style={homeStyle.topicsWrapper}>
                                <View style={{ flexWrap:'wrap',flexDirection: 'row'}} >
                                    {topics.map((topic: any,index: React.Key)=>(
                                        <TouchableOpacity key={index}
                                        onPress={()=> {navigation.navigate('FilterBlogs',{topic:topic})}}
                                        >
                                            <TopicButton topic={topic}/>
                                        </TouchableOpacity>)
                                    )}
                                </View>
                            </View>
                        </View>}
                        <View>
                            {subscribe && <TouchableOpacity onPress={()=>setSubscribe(!subscribe)}
                            style={{
                                borderWidth: 1.5,
                                borderColor: darkTheme?'#f3f3f3':'#141414',
                                borderRadius: 11,
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 5,
                                paddingVertical: 5,
                                height: 22,
                                width:22,
                                alignSelf:'center',
                                marginVertical: 20
                            }}
                            >
                                <MaterialCommunityIcons name='close'
                                style={{ fontWeight: 'bold',color:darkTheme?'#f3f3f3':'#121212' }}
                                />
                            </TouchableOpacity>}
                            {
                            subscribe ? <SubscriptionSection setNot={setNotification}/>:
                            <SubButton setSubscribe={setSubscribe}/>
                            }
                        </View>
                    </View>
                    <View>
                        <Contacts navigation={navigation}/>
                    </View>
                </View>}
            </View>
        </CustomPullToRefresh>
    )
}

export default Home
