import { View,SectionList,Text } from "react-native"
import BlogTrendCard from "./cards/blog-trend-card"
import ListCard from "./cards/list-card"
import { HomeStyle } from "../utils/app-styles"
import { BlogStory } from "../utils/story-data"


export function HomeSectionList({navigation,homeData}){
    const homeStyle = HomeStyle()
    return(
        <SectionList
        sections={homeData}
        keyExtractor={(_, index) => index.toString()}
        renderItem={(item)=>{
            return (
                //@ts-ignore
                item.section.title ==="Popular"?
                <View style={{
                    flexDirection:'row',flexWrap:'wrap',
                    alignContent:'flex-start',justifyContent:'space-around',
                    alignSelf:'center',
                }} >
                {item.section.data[item.index].rowData.map((item: BlogStory,index: number)=>
                    <BlogTrendCard data={item} key={index} index={index}
                    onPress={() =>
                        navigation.navigate('BlogRead', {
                        id: item.id,
                        })
                    }
                    />
                )}</View>:
                <View style={{
                    alignContent:'center',
                    justifyContent:'center',alignItems:'center',
                    alignSelf:'center',
                    width: '100%',
                }}
                >
                    <ListCard data={item.section.data[item.index]} key={item.index}
                        onPress={() =>
                            navigation.navigate('BlogRead', {
                            id: item.section.data[item.index].id,
                            })
                        }
                    />
                </View>
            )
        }}
        renderSectionHeader={(section)=>{
            return(
                <View style={{
                    alignContent:'flex-start',
                    justifyContent:'flex-start',alignItems:'flex-start',
                    marginTop: 20,marginBottom: 10,paddingLeft: 20
                }}>
                    <Text style={homeStyle.listedText}>{section.section.title} </Text>
                    {/* {section.section.title ==='All Blogs'&& */}
                    <View
                    style={{
                        height:1, backgroundColor: '#CDCCCC',
                        width: '100%', marginTop: 5
                    }}
                    />
                </View>
            )
        }}
        />
    )
}