import * as React from 'react'
import {View,Image,Text} from 'react-native'
import { baseUrlImage } from '../../utils/urls'
import { timeAgo } from '../../utils/time-ago'


export function CommentCard({comment,isNarrowScreen,darkTheme}){
    const dateObject = timeAgo(comment.created_at)

    return <View style={{ paddingHorizontal:10,paddingBottom:10, marginVertical: 10,
        }}>
        <View style={{ flexDirection:'row', flexWrap:'wrap',marginVertical:10, alignItems: 'flex-start' }}>
            <View style={{
            backgroundColor:'#1A8CF1',
            width: 27,height:27,
            borderRadius: 13.5,
            margin: 10, alignItems:'center',justifyContent:'center' }}>
                {comment.user_profile  &&<Image source={{uri: `${baseUrlImage}${comment.user_profile}`}}
                style={{ width: 25, height:25,borderRadius:12.5}}
                />}
            </View>
            <View style={{ flexWrap:'wrap',width: '80%',borderRadius: 10,
                borderWidth:1.5,borderColor:darkTheme?'#454545':'#EDEAEA',
                padding: 10
            }}
            >
                <View style={{ flexDirection:'row', flexWrap:'wrap', alignItems:'center',marginBottom:10 }}>
                    <Text style={{fontSize: isNarrowScreen? 14:16,
                    color: darkTheme?'#ffffff':'#121212',
                    fontFamily: 'roboto-bold'}}
                    >{comment.user_name}</Text>
                    <Text style={{fontSize: isNarrowScreen? 12:14,
                    color: darkTheme?'#ffffff':'#121212',
                    fontFamily: 'roboto-thin'}}
                    >{dateObject}</Text>
                </View>
                <Text style={{fontSize: isNarrowScreen? 14:16,
                color: darkTheme?'#ffffff':'#121212',
                fontFamily: 'roboto-thin'}}>{comment.comment}</Text>
            </View>
        </View>
    </View>
}