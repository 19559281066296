import React from 'react'
import { Modal, View, Text, TouchableOpacity,
    StyleSheet, useWindowDimensions } from 'react-native'
import { useTheme } from '../../utils/state-context'
import { MaterialCommunityIcons } from '@expo/vector-icons';

const WriteRequestModal = ({ visible, onCancel,navigation,sendRequest,request }) => {
    const {width: windowWidth} = useWindowDimensions()
    const {darkTheme} = useTheme()
    const isNarrowScreen = windowWidth < 800

    return (
        <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={onCancel}
        >
            <View style={styles.modalContainer}>
                <View style={[styles.modalContent,{
                    width: isNarrowScreen?'80%':'40%',
                    backgroundColor: darkTheme?'#12222F':'white',
                }]}>
                    <View style={{
                        width:'50%',height:'100%',
                        backgroundColor:'#EDF2F6',
                        padding: 10,borderTopLeftRadius: 10,borderBottomLeftRadius: 10,
                    }}>
                        <View style={[styles.buttonContainer,{width: '100%'}]}>
                            <TouchableOpacity onPress={onCancel} style={styles.cancelButton}>
                                {/* <Text style={styles.buttonText}>Cancel</Text> */}
                                <MaterialCommunityIcons name='close' size={16} color={'white'} />
                            </TouchableOpacity>
                        </View>
                        <View style={{
                            width: '100%',padding: 10,alignSelf: 'center',justifyContent:'center',
                            alignItems:'center'
                        }}>
                            <Text style={[styles.boldText,{fontSize: isNarrowScreen? 20:30}]}>Be a creator, and tell a story</Text>
                        </View>
                    </View>
                    <View style={{ width:'50%',height:'100%',padding:10 }}>
                        <View style={{
                            width: '100%',padding: 10,alignSelf: 'center',justifyContent:'center',
                            alignItems:'center'
                        }}>
                            <Text style={[styles.boldText,{fontSize: isNarrowScreen? 20:30}]}>Make the First step</Text>
                        </View>
                        <View style={{ width:'100%',alignItems:'center',justifyContent:'center' }}>
                            <Text style={[styles.modalText,{color: darkTheme?'#ffffff':'#6B6B6B',}]}>
                                {`Send a request to be writer, unlock the opportunity!`}
                            </Text>
                            {request? <View style={[styles.confirmButton,{
                                alignItems:'center',justifyContent:'center'}
                            ]}>
                                <Text style={[styles.buttonText,{fontSize: isNarrowScreen? 14:18,}]}>
                                    Request has Been Sent
                                </Text>
                            </View>:<TouchableOpacity onPress={
                                ()=>{sendRequest()}
                            } style={styles.confirmButton}>
                                <Text style={[styles.buttonText,{fontSize: isNarrowScreen? 14:18,}]}>
                                    Request
                                </Text>
                            </TouchableOpacity>}
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        // padding: 20,
        borderRadius: 10,
        justifyContent: 'center',
        flexDirection:'row',flexWrap: 'wrap',
        alignItems: 'center',
    },
    modalText: {
        fontSize: 16,
        marginBottom: 20,
        alignContent:'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,marginTop: 5
    },
    cancelButton: {
        backgroundColor: 'red',
        padding:2,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent:'flex-start'
    },
    confirmButton: {
        backgroundColor: '#1A8CF1',
        paddingHorizontal: 10,paddingVertical: 10,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center',
        marginBottom: 10,
    },
    buttonText: {
        color: 'white',
        fontWeight:'bold',
        fontFamily:'roboto-bold'
    },
    boldText:{
        fontWeight:'bold',
        fontFamily:'roboto-bold',
        color: '#1A8CF1',
    }
});

export default WriteRequestModal;
