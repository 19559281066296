export interface Account{
    id: number;
    username: string;
    description?: string;
    email: string;
    profile: string;
    links: string;
    bookmarks:string;
    date_joined:string;
    last_login:string;
    is_Writer : boolean;
}

export interface Subscriber{
    id:number,
    name: string,
    email:string,
    created_at:string,
}

export function subscribeSetter(data:{
    id:number,name:string,email:string,created_at:string
}):Subscriber{
    return{
        id:data.id, name:data.name,
        email:data.email, created_at: data.created_at
    }
}


export function subscribesSetter(data:any):Subscriber[]{
    return data.map((item:any)=>subscribeSetter(item))
}

export function accountSetter(
    data:{username: string,email: string,
        description:string, profile:string,links:string;
        bookmarks:string,date_joined:string,last_login:string;
        is_Writer:boolean;id:number;
    }
):Account{
    return{
        id:data.id,
        username: data.username,
        description:data.description,
        email: data.email,
        profile: data.profile,
        links: data.links,
        bookmarks:data.bookmarks,
        date_joined:data.date_joined,
        last_login:data.last_login,
        is_Writer:data.is_Writer
    }
}

export function accountsSetter(data:any):Account[]{
    return data.map((item:any)=>accountSetter(item))
}
