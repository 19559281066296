import React from "react"
import { View,Text,TouchableOpacity } from "react-native"
import { NewMultipleStyle } from "../utils/app-styles"
import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "../utils/state-context"


export const CreateContentControls = ({handleAddParagraph,handleSubtitle,selectImage})=>{
    const{darkTheme} = useTheme()
    const newMultipleStyle = NewMultipleStyle()
    return <View style={newMultipleStyle.addContent} >
        <Text
        style={{alignSelf: 'center',fontFamily: 'roboto-bold',fontSize: 16, color:darkTheme?'#ffffff':'#121212'  }}
        >Add: </Text>
        {/* button to add Subtitle */}
        <TouchableOpacity
            onPress={()=>handleSubtitle()}
            style={newMultipleStyle.btnAddSection}
        >
            <Ionicons name="text" size={24} color={darkTheme?'#ffffff': '#121212'} />
            <Text style={newMultipleStyle.btnTextSection}>Subtitle</Text>
        </TouchableOpacity>
        {/* button to add paragraph */}
        <TouchableOpacity
            onPress={()=>handleAddParagraph()}
            style={newMultipleStyle.btnAddSection}
        >
            <Ionicons name="text-outline" size={24} color={darkTheme?'#ffffff': '#121212'} />
            <Text style={newMultipleStyle.btnTextSection}>Paragraph</Text>
        </TouchableOpacity>
        {/* button to add paragraph */}
        {/* <TouchableOpacity
            onPress={()=>handleAddList()}
            style={newMultipleStyle.btnAddSection}
        >
            <Ionicons name="list" size={24} color={darkTheme?'#ffffff': '#121212'} />
            <Text style={newMultipleStyle.btnTextSection}>List</Text>
        </TouchableOpacity> */}
        {/* component to handle upload of the multiple content images */}
        <TouchableOpacity
            onPress={()=>selectImage()}
            style={newMultipleStyle.btnAddSection}
        >
            <Ionicons name="image-outline" size={24} color={darkTheme?'#ffffff': '#121212'} />
            <Text style={newMultipleStyle.btnTextSection} >Image</Text>
        </TouchableOpacity>
    </View>
}