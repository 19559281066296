import * as React from 'react'
import {View,Pressable,Text,StyleSheet,useWindowDimensions, TouchableOpacity, Linking} from 'react-native'
import { Account } from '../admin-utils/accounts-interface'
import { baseUrl, baseUrlImage } from '../../utils/urls'
import {Image} from 'expo-image'
import DateTimeFormatter from '../../utils/date-time-util-formatter'
import { useTheme } from '../../utils/state-context'
import axios from 'axios'
import { useAdminAuthentication } from '../admin-utils/admin-auth-context'
import FormData from 'form-data'
import DeleteConfirmationModal from './delete-modal-component'


export function AccountCard({data,setNot,setIsFiltered}){
    const{auth} = useAdminAuthentication()
    const {width: windowWidth} = useWindowDimensions()
    const {darkTheme} = useTheme()
    const isNarrowScreen = windowWidth < 800
    const links = JSON.parse(data?.links) ||[]
    const dateManager=(date:string)=>{
        const{ year, month, day} = DateTimeFormatter(new Date(date))
        return ` ${month} ${day}, ${year}`
    }
    const [modalVisible, setModalVisible] = React.useState(false)

    const handleDelete = () => {
        // Set modalVisible to true to show the delete confirmation modal
        setModalVisible(true)
    }

    const handleCancel = () => {
        // Handle cancel action and hide the modal
        setModalVisible(false)
    }

    const setWriterRights=async()=>{
        const idForm = new FormData()
        idForm.append('id', data.id)
        idForm.append('is_Writer',!data.is_Writer)
        await axios.put(`${baseUrl}accounts/`, idForm,{
            headers:{
                Authorization: `Token ${auth.token}`,
            }
        }).then((response)=>{
            setNot({
                message:`Updated status successfully`, level:'success'
            })
            setIsFiltered(false)
        }).catch((error)=>{
            setNot({
                message:`Error while handling request: ${error.message}`, level:'warning'
            })
        })
    }

    const deleteAccount=async()=>{
        const idForm = new FormData()
        idForm.append('id', data.id)
        await axios.post(`${baseUrl}accounts/`, idForm,{
            headers:{
                Authorization: `Token ${auth.token}`,
            }
        }).then((response)=>{
            setNot({
                message:`Deleted Account successfully`, level:'success'
            })
            setIsFiltered(false)
        }).catch((error)=>{
            console.log(error)
            setNot({
                message:`Error while handling request: ${error.message}`, level:'warning'
            })
        })
        setModalVisible(false)
    }

    return(
        <View style={{
            padding:5,width:'100%', marginBottom:10,
            borderRadius: 10, borderWidth: 1.5,
            borderColor:darkTheme?'#454545':'#EDEAEA',
        }}>
            <View style={{ flexDirection:'row',flexWrap:'wrap',width:'100%' }}>
                <View style={{
                    width: 26.5,height: 26.5, backgroundColor: '#D6D1D1', marginRight:10,
                    borderRadius: 13,alignItems:'center',justifyContent:'center',
                }}>
                    <Image source={{uri:`${baseUrlImage}${data.profile}`}}
                    style={{ width: 25,height: 25,borderRadius: 12.5,alignSelf:'center'
                    }}/>
                </View>
                <View style={{ width:'80%',flexWrap:'wrap' }}>
                    <Text style={[styles.boldText,{fontSize: 16}]}>{data.username}</Text>
                    <Text style={[styles.boldText,{
                        fontSize: 16,color:'#1A8CF1'
                    }]}>{data.email}</Text>
                    <View style={ styles.sections}>{
                        links.map((item:{type:string,url:string},index:number)=>{
                            return <View style={{ flexDirection:'row',flexWrap:'wrap' }}>
                                <Text style={styles.boldText}>{item.type}</Text>
                                <TouchableOpacity
                                style={{ marginHorizontal: 5 }}
                                key={index}
                                onPress={()=> Linking.openURL(item.url)}
                                >
                                    <Text style={{ color:'#1A8CF1' }}>{item.url}</Text>
                                </TouchableOpacity>
                            </View>
                        })
                    }</View>
                    <Text>{data.description}</Text>
                    <View style={{ flexDirection:'row',flexWrap:'wrap',width:'100%' }}>
                        <View style={{
                            borderRadius:5, backgroundColor:'#ADD5F8',
                            paddingVertical: 5, paddingHorizontal:10,
                            marginHorizontal:5,marginVertical:2
                        }}>
                            <Text style={styles.boldText}>{`${data.isWriter?' Writer ':' Normal User ' }`}</Text>
                        </View>
                        <View style={{
                            borderRadius:5, backgroundColor:'#ADD5F8',
                            paddingVertical: 5, paddingHorizontal:10,
                            marginHorizontal:5,marginVertical:2
                        }}>
                            <Text style={styles.boldText}>{` Joined ${dateManager(data?.date_joined)} `}</Text>
                        </View>
                        <View style={{
                            borderRadius:5, backgroundColor:'#ADD5F8',
                            paddingVertical: 5, paddingHorizontal:10,
                            marginHorizontal:5,marginVertical:2
                        }}>
                            <Text style={styles.boldText}>{` Last Login ${dateManager(data?.last_login)} `}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection:'row',flexWrap:'wrap',
                        width:'100%',marginBottom: 5,
                        alignItems:'center',marginTop: 10
                    }}>
                        <TouchableOpacity style={styles.button} onPress={()=>setWriterRights()}>
                            <Text style={styles.buttonText}>{data.is_Writer?'Revert':'Make Writer'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} onPress={handleDelete}>
                            <Text style={styles.buttonText}>Delete Account</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <DeleteConfirmationModal
                    visible={modalVisible}
                    onCancel={handleCancel}
                    data={data}
                    onConfirm={deleteAccount}
                />
            </View>
        </View>
    )
}

const styles= StyleSheet.create({
    sections:{
        marginVertical:5,
    },
    button:{
        borderRadius:20,
        backgroundColor:'#1A8CF1',
        paddingHorizontal:10,
        paddingVertical: 5,
        marginHorizontal: 10,
        alignItems:'center',
        alignContent:'center',
        justifyContent:'center'
    },
    buttonText:{
        color:'white'
    },
    boldText:{
        fontWeight:'bold',
        fontFamily:'roboto-bold',
    }
})
