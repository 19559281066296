import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as React from 'react'
import {View, Text, TouchableOpacity} from 'react-native'


export function NotificationPanel({notification}):JSX.Element{
    const {message,level} = notification
    const [visible, setVisible] = React.useState(false)

    React.useEffect(() => {
        let timeoutId: string | number | NodeJS.Timeout
        setVisible(true)
        // Set a timeout to automatically collapse the panel after 0.5 seconds
        timeoutId = setTimeout(() => {
            collapseNotification()
        }, 2000);

        // Clean up the timeout if the component unmounts or if the close button is pressed
        return () => clearTimeout(timeoutId)
    }, [notification])

    const collapseNotification = () => {
        setVisible(false)
    }

    const cancelNotification=()=>{
        collapseNotification()
    }
    return visible? (
        <View style={{
            flexDirection:'row', flexWrap:'wrap',paddingHorizontal: 10, paddingVertical: 10,
            margin:10,
            backgroundColor: level ==='success'?'#8DC09A': level ==='warning'?
            '#D88C9A': '#F1AC80',borderRadius:5,justifyContent: 'space-between'
        }}>
            <Text style={{ color:'#FFFFFF',fontFamily: 'roboto-regular',fontSize:16 }}>{message}</Text>
            <TouchableOpacity onPress={()=> cancelNotification()}>
                <MaterialCommunityIcons name='close' size={20} color={'#FFFFFF'}/>
            </TouchableOpacity>
        </View>
    ): null
}

