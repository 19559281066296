// SignInScreen.js
import React, { useState } from 'react';
import { View, Button, Text, Animated, useWindowDimensions, TouchableOpacity } from 'react-native';
import { LoginStyle } from '../utils/app-styles';
import CredentialFormInput from '../components/custom-components/credentialsFormComponent';
import { useTheme } from '../utils/state-context'
import {BlurView} from 'expo-blur'
import { baseUrl } from '../utils/urls';
import axios from 'axios';
import { authenticationSetter } from '../utils/authentication-interface';
import { useAuthentication } from '../utils/auth-context'
import FormData from 'form-data'
import CircularProgressIndicator from '../components/custom-components/progress-indicator'


const SignUp = ({navigation}) => {
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const loginStyles = LoginStyle()
    const[passwordError,setPasswordError] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [password2, setPassword2] = React.useState('')
    const [email, setEmail] = React.useState('')
    const[username, setUsername] = React.useState('')
    const [description, setDescription] = React.useState('New User')
    const [animation] = useState(new Animated.Value(0))

    //set alert notification
    const [notification,setNotification] = React.useState('')
    //showing loader
    const [isProgress,setIsProgress] = React.useState(false)
    //progress setter
    const [progress, setProgress] = React.useState(0)

    // authentication context setting
    const{login} = useAuthentication()

    //progress timer
    React.useEffect(() => {
        const interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress + 1) % 101)
        }, 30)
        return () => {
        clearInterval(interval)
        }
    }, [])

    const data = new FormData()

    //posts the request to the backend
    const RegisterButton=async()=>{
        setIsProgress(true)
        data.append('email', email)
        data.append('username',username)
        data.append('description',description)
        data.append('password', password)
        await axios.post(`${baseUrl}register_user/`,data)
        .then((response)=>{
            login(response)
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            })

        })
        .catch((err)=> {
            setIsProgress(false)
            if(err.response.status===501){
                setNotification('Failed to Register User')
            }else if(err.response.status===500){
                setNotification('There is a problem with the server')
            }else{
                setNotification(JSON.stringify(err.response.data))
            }
        })
    }

    const onchangePassword = (pass)=>{
        if (pass.length < 8) {
            setPassword(pass)
            setPasswordError('Password must be 8 characters or longer')
            return
        }
        setPassword(pass)
        setPasswordError('')
    }
    const onchangePassword2 = (pass)=>{
        if (pass !==password) {
            setPassword2(pass)
            setPasswordError('Passwords not a match')
            return
        }
        setPassword2(pass)
        setPasswordError('')
    }

    const moveAnimation = () => {
        Animated.timing(animation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
        }).start()
    };

    const emailNextHandler = () => {
        moveAnimation();
    };

    return (
        <View style={loginStyles.container}>
        <Animated.View
            style={{
            transform: [
                {
                translateY: animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [150, -600],
                }),
                },
            ],
            width: isNarrowScreen? '100%':'60%'
            }}
        >
            <View style={loginStyles.topContainer}>
                <BlurView tint={darkTheme?'dark':'light'} style={loginStyles.topBar}
                intensity={darkTheme?100:100}
                >
                    <Text style={{
                        fontSize: isNarrowScreen?18:24,
                        fontFamily:'roboto-bold',
                        fontWeight: '400',marginTop:isNarrowScreen?5:10,
                        marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                        color: '#1A8CF1',
                    }} >
                        Your Story
                    </Text>
                </BlurView>
                <Text style={{
                    fontSize: isNarrowScreen?16:18,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }} >
                    Create an Account
                </Text>
                <Text style={{
                    fontSize: isNarrowScreen?14:16,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }}>Enter your Username and Email Address: </Text>
                <CredentialFormInput
                    darkTheme={darkTheme}
                    secureTextEntry={false}
                    title={'Username:'}
                    label={username}
                    lineNum={1}
                    placeholder={'name'}
                    onChangeText = {(content: React.SetStateAction<string>) => setUsername(content)}
                />
                <CredentialFormInput
                    darkTheme={darkTheme}
                    secureTextEntry={false}
                    title={'Email:'}
                    label={email}
                    lineNum={1}
                    placeholder={'youremail@yourstory.com'}
                    keyboardType = 'email-address'
                    onChangeText = {(content: React.SetStateAction<string>) => setEmail(content)}
                />
                {
                    notification!=='' && <Text style={{ color:'red' }}>
                        {notification}
                    </Text>
                }
                <View style={{ marginVertical:5 }}>
                    <Text
                    onPress={()=>{
                        navigation.reset({
                            index:0,
                            routes: [{ name: 'SignIn' }],
                        })
                    }}
                    style={{
                        fontWeight: '400',
                        color: '#1A8CF1',
                        fontFamily: 'roboto-italic',
                        fontSize: 14,
                    }}
                    >Sign In</Text>
                </View>
                <View style={[loginStyles.extendedBtn]} >
                    <TouchableOpacity onPress={email.length>0&& username.length>0 && emailNextHandler}>
                        <Text style={loginStyles.extendText} >Next</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Animated.View>

        <Animated.View
            style={{
            transform: [
                {
                translateY: animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [400, -200],
                }),
                },
            ],
            width: isNarrowScreen? '100%':'60%'
            }}
        >
            <View style={loginStyles.topContainer}>
                <BlurView tint={darkTheme?'dark':'light'} style={loginStyles.topBar}
                intensity={100}
                >
                    <Text style={{
                        fontSize: isNarrowScreen?18:24,
                        fontFamily:'roboto-bold',
                        fontWeight: '400',marginTop:isNarrowScreen?5:10,
                        marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                        color: '#1A8CF1',
                    }} >
                        Your Story
                    </Text>
                </BlurView>
                <Text style={{
                    fontSize: isNarrowScreen?14:16,
                    fontFamily:'roboto-bold',
                    fontWeight: '400',marginTop:isNarrowScreen?5:10,
                    marginBottom: isNarrowScreen?5:10,alignSelf:'center',
                    color: darkTheme?'#ffffff':'#2E2E2E',
                }}>Enter your Password: </Text>
                <CredentialFormInput
                    darkTheme={darkTheme}
                    title={'Password:'}
                    label={password}
                    lineNum={1}
                    secureTextEntry = {true}
                    placeholder={'A-Z,a-z,0-9,{!@#}'}
                    onChangeText = {(content: React.SetStateAction<string>) => onchangePassword(content)}
                />
                <CredentialFormInput
                    darkTheme={darkTheme}
                    title={'Password:'}
                    label={password2}
                    lineNum={1}
                    secureTextEntry = {true}
                    placeholder={'A-Z,a-z,0-9,{!@#}'}
                    onChangeText = {(content: React.SetStateAction<string>) => onchangePassword2(content)}
                />
                <View style={{ marginVertical:5 }}>
                    <Text
                    onPress={()=>{
                        Animated.timing(animation, {
                            toValue: 0,
                            duration: 500,
                            useNativeDriver: false,
                            }).start()
                    }}
                    style={{
                        fontSize: isNarrowScreen?12:14,
                        fontFamily:'roboto-bold',
                        fontWeight: '400',
                        color: '#1A8CF1',
                    }}
                    >Go back</Text>
                </View>
                {passwordError!=='' &&
                    <Text style={[loginStyles.textDesc,{color:'red'}]}>
                        {passwordError}
                    </Text>
                }
                {isProgress &&
                <CircularProgressIndicator
                    radius={25}
                    strokeWidth={1}
                    progress={progress}
                />}
                <View style={[loginStyles.extendedBtn]} >
                    <TouchableOpacity
                    onPress={()=>password.length>0 && RegisterButton()}
                    >
                        <Text style={loginStyles.extendText} >Sign Up</Text>
                    </TouchableOpacity>
                </View>
                <View style={{
                    flexDirection:'row', flexWrap:'wrap',width:'90%',
                    alignItems: 'center', justifyContent:'center',marginVertical:10
                }}>
                    <Text style={loginStyles.bottomLinkText}>
                        Click “Sign Up” to agree to YourStory's
                    </Text>
                    <TouchableOpacity onPress={()=> navigation.navigate('Terms')}>
                        <Text style={{
                        fontWeight: '400',
                        color: '#1A8CF1',
                        fontFamily: 'roboto-italic',
                        fontSize: 14,
                        }}> Terms of Service </Text>
                    </TouchableOpacity>
                    <Text style={loginStyles.bottomLinkText}>
                        and acknowledge that YourStory's
                    </Text>
                    <TouchableOpacity onPress={()=> navigation.navigate('Policy')}>
                        <Text style={{
                        fontWeight: '400',
                        color: '#1A8CF1',
                        fontFamily: 'roboto-italic',
                        fontSize: 14,
                        }}> Privacy Policy </Text>
                    </TouchableOpacity>
                    <Text style={loginStyles.bottomLinkText}>
                        applies to you.
                    </Text>
                </View>
            </View>
        </Animated.View>
        </View>
    );
};

export default SignUp;

