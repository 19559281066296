import * as React from 'react'
import { View,TouchableOpacity,Text } from "react-native"
import { BlogContentStyle } from "../utils/app-styles"
import { Feather, MaterialCommunityIcons, } from "@expo/vector-icons"
import { baseUrl } from "../utils/urls"
import axios from "axios"
import * as Clipboard from 'expo-clipboard'
import { useAuthentication } from '../utils/auth-context'
import FormData from 'form-data'
import SignInModal from './modal-components/sign-in-modal'

export function ContentActions({darkTheme,isNarrowScreen,setNot,
    setLikes,likes,id,views,navigation,comments}){
    const blogContentStyle = BlogContentStyle()
    const{auth,login} = useAuthentication()
    const [isBookmarked,setIsBookmarked] = React.useState(false)
    const [isLiked,setIsLiked] = React.useState(false)
    const [authLikes,setAuthLikes] = React.useState<number[]>(auth && auth.likes !==null?JSON.parse(auth.likes):[])
    const [bookmarks,setBookmarks] = React.useState<number[]>(auth && auth.bookmarks!==null?JSON.parse(auth.bookmarks):[])
    const [modalVisible, setModalVisible] = React.useState(false)

    const handleBookmark = () => {
        // Set modalVisible to true to show the delete confirmation modal
        setModalVisible(true)
    }

    const handleCancel = () => {
        // Handle cancel action and hide the modal
        setModalVisible(false)
    }

    React.useEffect(()=>{
        const stringifiedBookmarks = bookmarks.map(String)
        const stringifiedLikes  = authLikes.map(String)
        const idString = String(id)
        const isCurrentlyBookmarked = stringifiedBookmarks.includes(idString)
        const isCurrentlyLiked = stringifiedLikes.includes(idString)
        setIsBookmarked(isCurrentlyBookmarked)
        setIsLiked(isCurrentlyLiked)
    },[bookmarks,id, authLikes])

    const saveBlog =async()=>{
        const bookmarkForm = new FormData()
        if(isBookmarked){
            const idIndex = bookmarks.indexOf(id)
            if(idIndex !== -1){
                bookmarks.splice(idIndex,1)
                bookmarkForm.append('bookmarks',JSON.stringify(bookmarks))
            }
        }else{
            const newBookmarks = [...bookmarks,id]
            bookmarkForm.append('bookmarks',JSON.stringify(newBookmarks))
        }
        await axios.put(`${baseUrl}update_user/`,bookmarkForm,
        {
            headers:{
                Authorization: `Token ${auth.token}`,
            }
        },)
        .then((response)=>{
            login(response)
            setNot({
                message:isBookmarked?'Blog removed successfully':'Blog Saved successfully',
                level:'success'
            })
            isBookmarked?setIsBookmarked(false):setIsBookmarked(true)
        })
        .catch((err)=>{
            setNot({
                message:`Error Saving blog: ${err.message}`, level:'warning'
            })
        })
    }

    const updateLikes=async()=>{
        const likesForm = new FormData()
        if(isLiked){
            const idIndex = authLikes.indexOf(id)
            if (idIndex !== -1) {
                authLikes.splice(idIndex,1)
                likesForm.append('likes',JSON.stringify(authLikes))
            }
        }else{
            const newLikes = [...authLikes,id]
            likesForm.append('likes',JSON.stringify(newLikes))
        }
        await axios.put(`${baseUrl}update_user/`,likesForm,
        {
            headers:{
                Authorization: `Token ${auth.token}`,
            }
        },
        )
        .then((response)=>{
            login(response)
            sendLike()
            isLiked && setNot({
                message:'Blog removed successfully', level:'success'
            })
            isLiked && setIsLiked(false)
        })
        .catch((err)=>{
            setNot({
                message:`Error Saving blog: ${err.message}`, level:'warning'
            })
        })
    }

    // passing responses on clicking
    const data = new FormData()
    data.append('id',id)
    const sendLike = async()=>{
        await axios.post(`${baseUrl}${isLiked?'dislike':'like'}/`,data)
        .then(()=>{
            setLikes(isLiked?likes-1:likes+1)
            setNot({
                message:isLiked?'Blog disliked successfully':'Blog Saved successfully', level:'success'
            })
            setIsLiked(isLiked?false:true)
        })
        .catch((error)=>{
            setNot({message:'Error while handling request',level:'warning'})
        })
    }
    const copyToClipboard = async () => {
        await Clipboard.setStringAsync(`https://yourstory.co.ke/blog/${id}`)
        setNot({message:'Blog url copied to clipboard!',level:'success'})
    }
    return(
        <View style={[blogContentStyle.actionWrapper,{flexDirection:isNarrowScreen? 'row':'column',
        alignItems:'center',alignContent:'stretch',
        justifyContent:'space-evenly'}]} >
            <View style={blogContentStyle.actionBtn}>
            <TouchableOpacity onPress={()=>{
                    auth?updateLikes():handleBookmark()
                }}>
                    <MaterialCommunityIcons name={isLiked?"heart":"heart-outline"} size={24}
                    color={isLiked?'#1A8CF1':darkTheme?'#ffffff': '#121212'} />
                </TouchableOpacity>
                <View style={{ flexDirection: 'row',alignContent:'space-between' }}>
                    {isNarrowScreen && <Text style={blogContentStyle.dateText}>Like</Text>}
                    <Text style={blogContentStyle.dateText}>{` ${ likes }`}</Text>
                </View>
            </View>
            <View style={blogContentStyle.actionBtn}>
                <TouchableOpacity>
                    <Feather name="message-circle" size={24} color={darkTheme?'#ffffff': '#121212'} />
                </TouchableOpacity>
                {<Text style={blogContentStyle.dateText}>{comments.length>0?comments.length:null}</Text>}
            </View>
            <View style={blogContentStyle.actionBtn}>
                <TouchableOpacity onPress={()=>{
                    auth?saveBlog():handleBookmark()
                }}>
                    <MaterialCommunityIcons name={isBookmarked?"bookmark":"bookmark-outline" }
                    size={24} color={isBookmarked?'#1A8CF1':darkTheme?'#ffffff': '#121212'} />
                </TouchableOpacity>
                {/* {!isNarrowScreen && <Text style={blogContentStyle.dateText}>Bookmark</Text>} */}
            </View>
            <View style={blogContentStyle.actionBtn}>
                <TouchableOpacity onPress={()=>copyToClipboard()}>
                    <MaterialCommunityIcons name="content-copy" size={24} color={darkTheme?'#ffffff': '#121212'} />
                </TouchableOpacity>
                {/* {!isNarrowScreen && <Text style={blogContentStyle.dateText}>Share</Text>} */}
            </View>
            <SignInModal navigation={navigation} onCancel={handleCancel} visible={modalVisible}/>
        </View>
    )
}