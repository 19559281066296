import * as React from 'react'
import {View,Text, Image, TouchableOpacity, useWindowDimensions} from 'react-native'
import { useTheme } from '../utils/state-context'
import { useAuthentication } from '../utils/auth-context'


export function GuideComponent(data:{title:string,iconSize: number,
    description:string,img: number,navigation}):JSX.Element{
    const{darkTheme} = useTheme()
    const {auth} = useAuthentication()
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    return(
        <View style={{
            padding: 10,
            paddingTop: 20,
            margin: !isNarrowScreen && 10, alignItems: 'center',alignContent:'center',
            width: '100%',
            backgroundColor:darkTheme && '#2B2A2A',
            borderColor: darkTheme && '#454545',
            borderRadius: darkTheme && 5,
            shadowOpacity: darkTheme && .5,shadowColor: darkTheme && '#B8B5B5',
            shadowRadius: darkTheme && 3,
        }} >
            <Text style={{
                fontSize: 24,
                fontFamily:'roboto-bold',
                fontWeight: '400',
                marginBottom: 20,
                color: darkTheme?'#ffffff':'#2E2E2E',
            }} >
                {data.title}
            </Text>
            {<Image source={require(data.img===0?'../images/read.jpg':'../images/write.avif')}
            style={{
                width:'90%',
                height: 150,
                borderRadius:5
            }}
            resizeMode = 'contain'
            />}
            <Text style={{
                fontSize: 16,
                fontFamily:'roboto-thin',
                color: darkTheme?'#f6f6f6':'#6B6B6B'
            }}
            >
                {data.description}
            </Text>
            {data.img ===0 && <TouchableOpacity   onPress={()=>{
                 // Reload the entire web app
                data.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Home' }],
                })
            }}>
                <Text style={{
                fontSize: 20,
                fontFamily:'roboto-bold',
                color:'#1A8CF1'
            }}>Read</Text>
            </TouchableOpacity>}
            {
            data.img ===1 && auth!==null?
            <TouchableOpacity>
                <Text style={{
                fontSize: 20,
                fontFamily:'roboto-bold',
                color:'#1A8CF1'
            }}>Write</Text>
            </TouchableOpacity>: data.img ===1 && <TouchableOpacity>
                <Text
                style={{
                    fontSize: 20,
                    fontFamily:'roboto-bold',
                    color:'#1A8CF1'
                }}>Sign In</Text>
            </TouchableOpacity>
            }
        </View>
    )
}

