import FormData from "form-data"

const dataHandler= async({title,content,topic})=>{
    const data = {
        title: title,
        topic: topic,
        story: []
    }
    const imagesForm = new FormData()
    const imagesMetadata = new FormData()
    for (const item of content) {
        if (item.type === 'image' && item.blob && item.blob !==null) {
            if (item.status ==='new'){
                imagesForm.append('index',item.index)
                imagesForm.append('type',item.type)
                imagesForm.append('imageCopyright',item.imageCopyright)
                imagesForm.append('media',item.blob,item.filename)
                imagesForm.append('status',item.status)
            }else{
                imagesForm.append('id',item.id)
                imagesForm.append('index',item.index)
                imagesForm.append('type',item.type)
                imagesForm.append('imageCopyright',item.imageCopyright)
                imagesForm.append('media',item.blob,item.filename)
                imagesForm.append('status','update')
            }
        } else if(item.type==='image' && !item.blob){
            imagesMetadata.append('id',item.id)
            imagesMetadata.append('index',item.index)
            imagesMetadata.append('type',item.type)
            imagesMetadata.append('imageCopyright',item.imageCopyright)
        }else {
            data.story.push(item)
        }
    }
    return {data,imagesForm,imagesMetadata}
}
export default dataHandler