import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { useTheme } from '../utils/state-context';

interface PolicySection {
    [key: string]: string | PolicySection[];
}

interface TermsAndConditions {
    [key: string]: string | PolicySection;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    sectionContainer: {
        marginBottom: 16,
        paddingHorizontal: 10,
    },
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: 30,
        marginBottom: 8,
    },
    sectionSubtitle:{
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 8,
    },
    content: {
        fontSize: 16,
        lineHeight: 20,
    },
});

const displayPolicySection = (
    section: PolicySection,
    indentation: string = ''
    ): React.ReactNode => {
    return (
        <>
        {Object.entries(section).map(([key, value]) => (
            <View key={key} style={styles.sectionContainer}>
            <Text style={styles.sectionSubtitle}>{`${indentation}${key}`}</Text>
            {typeof value === 'string' ? (
                <Text style={styles.content}>{`${indentation}${value}`}</Text>
            ) : Array.isArray(value) ? (
                value.map((item, index) => (
                <Text key={index} style={styles.content}>{`${indentation}${index + 1}. ${item}`}</Text>
                ))
            ) : (
                displayPolicySection(value as PolicySection, `${indentation}  `)
            )}
            </View>
        ))}
        </>
    );
};

const displayTermsAndConditions = (
    terms: TermsAndConditions
    ): React.ReactNode => {
    let sectionNumber = 1;

    return (
        <View>
            {Object.entries(terms).map(([key, value]) => (
                <View key={key} style={styles.sectionContainer}>
                    <View style={{ width: '100%', alignItems:'center',marginBottom: 20,marginTop: 30 }}>
                        <Text style={styles.sectionTitle}>
                            {key}
                        </Text>
                    </View>
                {typeof value === 'string' ? (
                    <Text style={styles.content}>{value}</Text>
                ) : (
                    displayPolicySection(value as PolicySection)
                )}
                </View>
            ))}
        </View>
    );
};

const Terms = () => {
    const {width: windowWidth,height:windowHeight} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800
    const {darkTheme} = useTheme()
    const [termsAndConditions, setTermsAndConditions] =
        useState<TermsAndConditions | null>(null);

    useEffect(() => {
        const loadTerms = async () => {
        try {
            // Import the JSON file directly
            const terms = require('./terms.json');
            setTermsAndConditions(terms as TermsAndConditions);
        } catch (error) {
            console.error(`Error loading JSON file: ${error}`);
        }
        };

        loadTerms();
    }, []);

    return (
        <View style={{ flex: 1,alignItems: 'center', justifyContent:'center', width: '100%'}}>
            <ScrollView contentContainerStyle={{
                alignItems: 'center', justifyContent:'center',
            }} showsVerticalScrollIndicator={false}>
                <View style={{
                        flex: 1,alignItems: 'center', justifyContent:'center', width: isNarrowScreen?'100%':'60%',
                        borderRadius: 10,marginVertical: 10,
                        backgroundColor: '#FFFFFF'
                    }}>
                    {termsAndConditions ? (
                        displayTermsAndConditions(termsAndConditions)
                    ) : (
                        <Text>Loading Terms and Conditions...</Text>
                    )}
                </View>
            </ScrollView>
        </View>
    );
};

export default Terms;
