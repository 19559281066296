import * as React from 'react'
import {View, useWindowDimensions} from 'react-native'
import { useTheme } from '../utils/state-context'
import { ScrollView } from 'react-native-gesture-handler'
import MyBlogs from './author-blogs'
import { NotificationPanel } from '../components/custom-components/notification-panel'
import { useAuthentication } from '../utils/auth-context'
// import BottomSide from '../components/bottom-side'

// Profile
export function Profile({navigation}):JSX.Element{
    const {darkTheme} = useTheme()
    const [notification,setNotification] = React.useState({
        message:'',level:''
    })
    const{auth}= useAuthentication()
    const {width: windowWidth} = useWindowDimensions()
    const isNarrowScreen = windowWidth < 800


    return(
        <View style={{
            flexGrow:1,flexShrink:1,
            backgroundColor:darkTheme?'#121212':'#f3f3f3',
            width:'100%',
        }}>
            {notification.level !=='' && <NotificationPanel notification={notification}/>}
            {auth && <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{
                    width: isNarrowScreen? '100%': '90%',
                    marginHorizontal:!isNarrowScreen&& 20,
                    marginTop: 10,marginBottom: 20
                }}>
                    <MyBlogs navigation={navigation} setNot={setNotification}/>
                </View>
            </ScrollView>}
        </View>
    )
}